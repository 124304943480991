import React, { ChangeEvent } from 'react';
import { TextArea } from '@rentecarlo/component-library';

interface Props {
  id: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const CustomerComment: React.FC<Props> = (props) => {
  return <TextArea maxLength={255} {...props} />;
};

export default CustomerComment;
