// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';
import { Theme } from 'utils/theme';
import ContentLoader from 'react-content-loader';
import {
  Container,
  ContentLoaderContainer,
  ContentLoaderWrapper,
  AddressLoaderContainer,
  FormItemLabel,
  FieldContainer,
  OccupationContainer,
  Arrow,
  DetailsLoaderContainer,
} from './assets/styles';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PersonalDetailsSkeletonLoader = () => (
  <div id='PersonalSkeleton'>
    <ContentLoaderContainer>
      <ContentLoaderWrapper>
        <Container bottomMargin='40px'>
          <DetailsLoaderContainer>
            <ContentLoader
              uniqueKey='personalSkeleton'
              height='100%'
              width='100%'
              speed={2}
              backgroundColor={Theme.backgroundAlt}
              foregroundColor={Theme.skeletonGrey}
              rtl
            >
              <rect x='24' y='23' rx='8' ry='8' width='120' height='20' />
              <rect x='24' y='67' rx='8' ry='8' width='160' height='20' />
              <rect x='24' y='111' rx='8' ry='8' width='100' height='20' />
              <rect x='24' y='134' rx='8' ry='8' width='160' height='18' />
            </ContentLoader>
          </DetailsLoaderContainer>
        </Container>
      </ContentLoaderWrapper>
    </ContentLoaderContainer>
  </div>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ContactSkeletonLoader = () => (
  <div id='ContactSkeleton'>
    <ContentLoaderContainer>
      <ContentLoaderWrapper>
        <Container>
          <FormItemLabel>Your address</FormItemLabel>
          <AddressLoaderContainer>
            <ContentLoader
              uniqueKey='addressSkeleton'
              height='100%'
              width='100%'
              speed={2}
              backgroundColor={Theme.backgroundAlt}
              foregroundColor={Theme.skeletonGrey}
            >
              <rect x='24' y='23' rx='8' ry='8' width='160' height='13' />
              <rect x='24' y='42' rx='8' ry='8' width='120' height='13' />
              <rect x='24' y='61' rx='8' ry='8' width='140' height='13' />
              <rect x='24' y='80' rx='8' ry='8' width='100' height='13' />
            </ContentLoader>
          </AddressLoaderContainer>
        </Container>
        <Container>
          <FormItemLabel>Your phone number</FormItemLabel>
          <FieldContainer>
            <ContentLoader
              uniqueKey='phoneSkeleton'
              height='100%'
              width='100%'
              speed={2}
              backgroundColor={Theme.backgroundAlt}
              foregroundColor={Theme.skeletonGrey}
            >
              <rect x='24' y='24' rx='8' ry='8' width='160' height='13' />
            </ContentLoader>
          </FieldContainer>
        </Container>
        <Container>
          <FormItemLabel>Your email address</FormItemLabel>
          <FieldContainer>
            <ContentLoader
              uniqueKey='emailSkeleton'
              height='100%'
              width='100%'
              speed={2}
              backgroundColor={Theme.backgroundAlt}
              foregroundColor={Theme.skeletonGrey}
            >
              <rect x='24' y='24' rx='8' ry='8' width='200' height='13' />
            </ContentLoader>
          </FieldContainer>
        </Container>
        <Container bottomMargin='0px'>
          <FormItemLabel>Your occupation</FormItemLabel>
          <FieldContainer bottomMargin='8px'>
            <ContentLoader
              uniqueKey='studentSkeleton'
              height='100%'
              width='100%'
              speed={2}
              backgroundColor={Theme.backgroundAlt}
              foregroundColor={Theme.skeletonGrey}
            >
              <rect x='24' y='24' rx='8' ry='8' width='160' height='13' />
            </ContentLoader>
          </FieldContainer>
          <FieldContainer bottomMargin='8px'>
            <ContentLoader
              uniqueKey='employedSkeleton'
              height='100%'
              width='100%'
              speed={2}
              backgroundColor={Theme.backgroundAlt}
              foregroundColor={Theme.skeletonGrey}
            >
              <rect x='24' y='24' rx='8' ry='8' width='160' height='13' />
            </ContentLoader>
          </FieldContainer>
          <FieldContainer>
            <ContentLoader
              uniqueKey='otherSkeleton'
              height='100%'
              width='100%'
              speed={2}
              backgroundColor={Theme.backgroundAlt}
              foregroundColor={Theme.skeletonGrey}
            >
              <rect x='24' y='24' rx='8' ry='8' width='160' height='13' />
            </ContentLoader>
          </FieldContainer>
        </Container>
        <OccupationContainer>
          <Arrow />
          <FieldContainer>
            <ContentLoader
              uniqueKey='occupationSkeleton'
              height='100%'
              width='100%'
              speed={2}
              backgroundColor={Theme.backgroundAlt}
              foregroundColor={Theme.skeletonGrey}
            >
              <rect x='24' y='24' rx='8' ry='8' width='200' height='13' />
            </ContentLoader>
          </FieldContainer>
        </OccupationContainer>
      </ContentLoaderWrapper>
    </ContentLoaderContainer>
  </div>
);
