import { PageTitle } from 'components/atoms';
import { TitledSection, PageLayout } from 'components/templates';
import {
  PolicyCard,
  SubscriptionCard,
  BannerCard,
  Separator,
  Subscription as SubscriptionType,
  Policy as PolicyType,
  PolicyStatus,
} from '@rentecarlo/component-library';
import React, { useCallback } from 'react';
import {
  ActivePolicies,
  UpcomingPolicies,
  EndedPolicies,
  ActiveSubscriptions,
  UpcomingSubscriptions,
  EndedSubscriptions,
} from 'state/purchases/types';
import { useHistory } from 'react-router-dom';
import { Container, PolicyCardContainer, BannerCardContainer } from './assets/styles';
import { ReactComponent as BannerImage } from './assets/simplified_image.svg';

interface PurchasesInterface {
  policies: ActivePolicies & UpcomingPolicies & EndedPolicies;
  subscriptions: ActiveSubscriptions & UpcomingSubscriptions & EndedSubscriptions;
}

const Purchases: React.FC<PurchasesInterface> = ({ policies, subscriptions }) => {
  const history = useHistory();

  const goToDetailedPurchase = useCallback(
    (type: string, uuid: string, isSubscription?: boolean) =>
      history.push(`/detailed-purchase`, { status: type, uuid, isSubscription }),
    [history],
  );

  return (
    <PageLayout>
      <Container>
        <PageTitle id='pageTitle'>Purchases</PageTitle>
        {policies.active.length === 0 &&
          policies.upcoming.length === 0 &&
          subscriptions.active.length === 0 &&
          subscriptions.upcoming.length === 0 && (
            <BannerCardContainer>
              <BannerCard
                title='You have no active or upcoming policies'
                text="Looks like you have not taken out any insurance policies with us. Let's do something about that right now, by starting a quote..."
                buttonText='Get a quote'
                buttonId='purchases-get-a-quote-banner-card'
                onClick={() => (window.location.href = `${process.env.REACT_APP_QUOTE_URL}/start`)}
                BannerImage={BannerImage}
              />
              {policies.ended.length > 0 && <Separator />}
            </BannerCardContainer>
          )}
        {(policies.active.length > 0 ||
          policies.upcoming.length > 0 ||
          subscriptions.active.length > 0 ||
          subscriptions.upcoming.length > 0) && (
          <TitledSection title='Active and upcoming policies'>
            {subscriptions.active.map((subscription: SubscriptionType) => {
              return (
                <PolicyCardContainer key={subscription.referenceNumber}>
                  <SubscriptionCard
                    subscription={subscription}
                    detailedCard={false}
                    action={(): void => goToDetailedPurchase('active', subscription.uuid, true)}
                    updatePaymentLink={
                      'updateUrl' in subscription && subscription.updateUrl
                        ? String(subscription.updateUrl)
                        : null
                    }
                  />
                </PolicyCardContainer>
              );
            })}
            {subscriptions.upcoming.map((subscription: SubscriptionType) => {
              return (
                <PolicyCardContainer key={subscription.referenceNumber}>
                  <SubscriptionCard
                    subscription={subscription}
                    detailedCard={false}
                    action={(): void => goToDetailedPurchase('upcoming', subscription.uuid, true)}
                  />
                </PolicyCardContainer>
              );
            })}

            {policies.active.map((policy: PolicyType) => {
              return (
                <PolicyCardContainer key={policy.referenceNumber}>
                  <PolicyCard
                    policy={policy}
                    detailedCard={false}
                    policyStatus={PolicyStatus.ACTIVE}
                    action={(): void => goToDetailedPurchase('active', policy.uuid)}
                  />
                </PolicyCardContainer>
              );
            })}
            {policies.upcoming.map((policy: PolicyType) => {
              return (
                <PolicyCardContainer key={policy.referenceNumber}>
                  <PolicyCard
                    policy={policy}
                    detailedCard={false}
                    policyStatus={PolicyStatus.UPCOMING}
                    action={(): void => goToDetailedPurchase('upcoming', policy.uuid)}
                  />
                </PolicyCardContainer>
              );
            })}
            <Separator />
          </TitledSection>
        )}
        {(policies.ended.length > 0 || subscriptions.ended.length > 0) && (
          <TitledSection title='Expired policies'>
            {subscriptions.ended.map((subscription: SubscriptionType) => {
              return (
                <PolicyCardContainer key={subscription.referenceNumber}>
                  <SubscriptionCard
                    subscription={subscription}
                    detailedCard={false}
                    action={(): void => goToDetailedPurchase('ended', subscription.uuid, true)}
                  />
                </PolicyCardContainer>
              );
            })}

            {policies.ended.map((policy: PolicyType) => {
              return (
                <PolicyCardContainer key={policy.referenceNumber}>
                  <PolicyCard
                    policy={policy}
                    detailedCard={false}
                    policyStatus={!policy.cancelled ? PolicyStatus.ENDED : PolicyStatus.CANCELLED}
                    action={(): void => goToDetailedPurchase('ended', policy.uuid)}
                  />
                </PolicyCardContainer>
              );
            })}
          </TitledSection>
        )}
      </Container>
    </PageLayout>
  );
};

export default Purchases;
