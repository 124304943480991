import { Client } from 'services';

const getBuyapowaSignature = async () => {
  const response = await fetch(`${process.env.REACT_APP_REFERRALS_BASE_URL}/buyapowa/signature`, {
    headers: {
      Authorization: await Client.retrieveCognitoBearerToken(),
    },
    method: 'POST',
  });
  if (response.ok) {
    return response.text();
  }
  throw new Error(`getBuyapowaSignature returned: ${response.text()}`);
};

export const ReferralsClient = {
  getBuyapowaSignature,
};
