import { v4 as uuidv4 } from 'uuid';
import { call, put, fork } from 'redux-saga/effects';
import { AmplifyService } from 'services';
import { setSessionCookie, getCookie } from 'utils/cookies';
import { setOptimiselyAttribute } from 'state/config/actions';
import accountSaga from './account/sagas';

function* setUserIdentifier() {
  let userIdentity = getCookie('user_identity');

  if (userIdentity === undefined) {
    userIdentity = uuidv4();

    const cookieYesCookie = getCookie('cookieyes-consent');
    if (cookieYesCookie && cookieYesCookie.includes('functional:yes')) {
      setSessionCookie('user_identity', userIdentity);
    }
  }

  yield put(setOptimiselyAttribute('user_identity', userIdentity));
}

function* initialiseAmplifyService() {
  try {
    yield call(() => AmplifyService.initialise());
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
}

export function* runInit(): Generator {
  yield setUserIdentifier();
  yield initialiseAmplifyService();
  yield fork(accountSaga);
}
