import React, { MouseEvent } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 67px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  background: rgb(243, 244, 247);
`;

const textButton = css`
  padding: 0 16px;
  line-height: 17px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'proxima-soft';
  color: ${({ theme }): string => theme.buttonBackground};
`;

const CancelButton = styled.span`
  ${textButton}
  text-align: left;
`;

const DoneButton = styled.span`
  ${textButton}
  text-align: right;
`;

interface MobileModalHeaderProps {
  onCancel: (event: MouseEvent<HTMLSpanElement>) => void;
  onDone: (event: MouseEvent<HTMLSpanElement>) => void;
}

const MobileModalHeader: React.FC<MobileModalHeaderProps> = ({ onCancel, onDone }) => (
  <Container>
    <CancelButton id='account-button-mobileModalCancel' onClick={onCancel}>
      Cancel
    </CancelButton>
    <DoneButton id='account-button-mobileModalDone' onClick={onDone}>
      Done
    </DoneButton>
  </Container>
);

export default MobileModalHeader;
