import * as actionTypes from './actionTypes';
import { ToggleNotification, UpdateFailed, UpdateSuccessful, UndoNotification } from './types';

const toggleNotification = (id: string): ToggleNotification => ({
  id,
  type: actionTypes.TOGGLE_NOTIFICATION,
});

const updateFailed = (): UpdateFailed => ({
  type: actionTypes.UPDATE_FAILED,
});

const updateSuccessful = (): UpdateSuccessful => ({
  type: actionTypes.UPDATE_SUCCESSFUL,
});

const undoNotifications = (): UndoNotification => ({
  type: actionTypes.NOTIFICATIONS_UNDO,
});

export const actionCreators = {
  toggleNotification,
  updateFailed,
  updateSuccessful,
  undoNotifications,
};
