import React, { ReactNode } from 'react';
import {
  HorizontalLayoutContainer,
  DetailsIcon,
  DetailsContainer,
  DetailsTitle,
  DetailsText,
} from './assets/styles';

interface Props {
  image?: string;
  children?: string | ReactNode;
  detailTitle: string;
}

const DetailsSummary: React.FC<Props> = (props) => {
  const { image, detailTitle, children } = props;
  return (
    <HorizontalLayoutContainer>
      <DetailsIcon src={image} />
      <DetailsContainer>
        <DetailsTitle>{detailTitle}</DetailsTitle>
        <DetailsText>{children}</DetailsText>
      </DetailsContainer>
    </HorizontalLayoutContainer>
  );
};

export default DetailsSummary;
