import React from 'react';
import { AvatarImage, AvatarWrapper, Container, TextWrapper } from './assets/styles';

interface Props {
  name: string;
}

const Avatar: React.FC<Props> = ({ name }) => (
  <Container>
    <TextWrapper>Logged in as</TextWrapper>
    <AvatarWrapper>
      <AvatarImage /> {name}
    </AvatarWrapper>
  </Container>
);

export default Avatar;
