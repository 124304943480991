/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Styled from 'styled-components';
import { Fonts } from 'utils/config';

export const Title = Styled.span`
  height: 36px;
  color: ${({ theme }) => theme.textPrimary};
  font-family: ${Fonts.title};
  font-size: 32px;
  font-weight: 800;
  line-height: 36px;
  margin-bottom: 32px;
  margin-left: 8px;
`;
