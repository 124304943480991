import { NotificationActions, NotificationState } from './types';
import { AccountActions } from '../account/types';
import * as actionTypes from './actionTypes';
import * as loginActionTypes from '../account/actionTypes';

export const initialState = {
  emailConsent: false,
  phoneConsent: false,
  updateFailed: false,
};

const preferencesReducer = (state: NotificationState, actionId: string): NotificationState => {
  switch (actionId) {
    case 'emailConsent':
      return state.emailConsent
        ? {
            // email toggled OFF
            ...state,
            emailConsent: false,
          }
        : {
            //  email toggled ON
            ...state,
            emailConsent: true,
          };
    case 'phoneConsent':
      return {
        // phone toggled ON or OFF
        ...state,
        phoneConsent: !state.phoneConsent,
      };
    default:
      return state;
  }
};

export const reducer = (
  state: NotificationState = initialState,
  action: NotificationActions | AccountActions,
): NotificationState => {
  switch (action.type) {
    case actionTypes.TOGGLE_NOTIFICATION:
      return {
        ...preferencesReducer(state, action.id),
        updateFailed: false,
      };
    case loginActionTypes.RETRIEVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        phoneConsent: action.data.notifications.phoneConsent,
        emailConsent: action.data.notifications.emailConsent,
      };
    case actionTypes.UPDATE_FAILED:
      return {
        ...state,
        updateFailed: true,
      };
    default:
      return state;
  }
};
