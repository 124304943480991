import React, { ReactNode } from 'react';
import Container from './assets/styles';

export enum Direction {
  UP = 'UP',
  DOWN = 'DOWN',
}

interface Props {
  children?: ReactNode;
  close: boolean;
  offset?: number;
  slideDirection?: Direction;
}

const defaultProps = {
  offset: 0,
  slideDirection: Direction.DOWN,
};

const SlideUpModal: React.FC<Props> = (props) => {
  const { close, offset, slideDirection, children } = {
    ...defaultProps,
    ...props,
  };
  return (
    <Container close={close} offset={offset} slideDirection={slideDirection}>
      {children}
    </Container>
  );
};

export default SlideUpModal;
