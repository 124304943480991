import React, { ReactNode } from 'react';
import { FullPage, Content } from './assets/styles';

interface Props {
  children: ReactNode;
}

const PageLayout: React.FC<Props> = ({ children }: Props) => (
  <FullPage>
    <Content>{children}</Content>
  </FullPage>
);

export default PageLayout;
