type ValidationResponse = string | boolean | undefined;

const matchesEmailRegex = (email: string): ValidationResponse =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );

export const isEmail = (value: string): ValidationResponse => {
  if (value && matchesEmailRegex(value)) {
    return undefined;
  }
  return 'Oops! Please enter a valid email address';
};

export const isRequired = (value: string): ValidationResponse => {
  if (value.length > 0) {
    // Preferred to return undefined than null
    // as per best practice on Formik's validation docs.
    return undefined;
  }

  return 'This is a required field.';
};

export const isChecked = (checked: boolean): ValidationResponse => {
  if (checked) {
    return undefined;
  }

  return 'Reason for cancellation is required';
};
