import Styled, { css } from 'styled-components';
import { MediaQuery, ThemeUtils } from '@rentecarlo/component-library';

interface Props {
  theme: ThemeUtils.Theme;
}

export const FullPage = Styled.div`
  flex: 1;
  display: flex;
  margin-left: 48px;
  background-color: ${({ theme }: Props): string => theme.backgroundSecondary};
  max-width: 100%;
  ${MediaQuery.tablet(css`
    justify-content: center;
    margin-left: 0;
  `)};
  ${MediaQuery.mobile(css`
    margin-left: 16px;
    margin-right: 16px;
    justify-content: center;
  `)};
`;

export const Content = Styled.div`
  flex: 1;
  display: flex;
  max-width: 720px;
`;
