import { combineReducers } from 'redux';
import undoable, { includeAction } from 'redux-undo';
import { reducer as accountReducer, actionTypes as AccountActionTypes } from './account';
import {
  reducer as notificationsReducer,
  actionTypes as NotificationActionTypes,
} from './notifications';
import { reducer as profileReducer } from './profile';
import { reducer as purchasesReducer } from './purchases';
import { reducer as configReducer } from './config';

const { NOTIFICATIONS_UNDO, TOGGLE_NOTIFICATION } = NotificationActionTypes;

const rootReducer = combineReducers({
  account: accountReducer,
  notifications: undoable(notificationsReducer, {
    limit: 1,
    undoType: NOTIFICATIONS_UNDO,
    filter: includeAction([AccountActionTypes.RETRIEVE_ACCOUNT_SUCCESS, TOGGLE_NOTIFICATION]),
  }),
  profile: profileReducer,
  purchases: purchasesReducer,
  config: configReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
