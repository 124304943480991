import Styled, { css } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

export const PolicyCardContainer = Styled.div`
  margin-bottom: 32px;
  width: calc(50% - 16px);
  ${MediaQuery.mobile(css`
    width: 100%;
  `)}
`;

export const Container = Styled.div`
  margin-top: 48px;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${MediaQuery.mobile(css`
    margin-top: 32px;
  `)}
`;

export const BannerCardContainer = Styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  &>div:first-child {
    margin-bottom: 40px;
  }
`;
