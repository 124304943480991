/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { House, Kebab } from 'assets';
import styled, { css } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

export const StyledBreadcrumb = styled.div`
  display: flex;
  width: 99%;
  height: 48px;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  box-shadow: ${({ theme }) => `0 0 3px 0 ${theme.shadowPrimary}`};
  border-left: ${({ theme }) => `4px solid ${theme.primary}`};
  min-height: 48px;
  flex-direction: row;
  ${MediaQuery.desktop(css`
    display: none;
  `)}
`;

export const HouseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 18.75px;
  margin-right: 17.5px;
  height: 100%;
  ${MediaQuery.desktop(css`
    display: none;
  `)}
`;

export const IconImgHolder = styled.img.attrs({
  src: House,
})`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  height: 24.5px;
  width: 27.5px;
  ${MediaQuery.desktop(css`
    display: none;
  `)}
`;
export const TitleWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-family: Proxima-Soft;
  font-weight: bold;
  color: ${({ theme }) => `${theme.primary}`};
`;

export const KebabWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100px;
  justify-content: center;
  align-items: center;
`;

export const KebabIcon = styled.img.attrs({
  src: Kebab,
})`
  height: 25px;
  width: 5px;
`;
