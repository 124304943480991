/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Styled from 'styled-components';
import { Theme } from 'utils/theme';

interface PillButtonProps {
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  height?: number;
  onClick?: () => void;
  paddingHorizontal?: number;
  textColor?: string;
  theme: Theme;
  hoverTextColor?: string;
}

interface PillButtonTextInterface {
  fontSize?: number;
  fontWeight?: string;
}

export const Button = Styled.button`
  height: ${({ height }: PillButtonProps) => height || 30}px;
  width: 100%;
  border-radius: 17px;
  color: ${(props: PillButtonProps) => props.textColor};
  background-color: ${({ backgroundColor, theme }: PillButtonProps) =>
    backgroundColor || theme.backgroundAlt};
  border: ${({ borderColor, borderWidth, theme }: PillButtonProps) =>
    `${borderWidth}px solid ${borderColor || theme.linkPrimary}`};
  padding: 2px ${({ paddingHorizontal }: PillButtonProps) => paddingHorizontal || 18}px;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
  &:hover {
    background-color: ${({ textColor, theme }: PillButtonProps) => textColor || theme.linkPrimary};
    color: ${({ hoverTextColor, theme }: PillButtonProps) => hoverTextColor || theme.logoPrimary};
  }
`;

export const PillButtonText = Styled.span`
  font-size: ${({ fontSize }: PillButtonTextInterface) => fontSize || 16}px;
  font-weight: ${({ fontWeight }: PillButtonTextInterface) => fontWeight || 'bold'};
  line-height: 20px;
  text-align: center;
`;
