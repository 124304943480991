import React from 'react';
import { FieldProps } from 'formik';

import { PasswordInput } from '@rentecarlo/component-library';

interface InputField extends FieldProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  error?: string;
  submitError?: boolean;
}

type ErrorText = string | undefined;

// Formik Field Input handler
const PasswordInputField: React.FC<InputField> = ({
  field,
  form,
  placeholder,
  error,
  submitError,
  ...others
}) => {
  const errorText = form.errors[field.name] as ErrorText;
  const touched = form.touched[field.name];

  return (
    <PasswordInput
      errorText={errorText || error}
      placeholder={placeholder}
      touched={!!(touched || (submitError && errorText))}
      containerError={!!(submitError && errorText)}
      id='password-input'
      {...field}
      {...others}
    />
  );
};

export default PasswordInputField;
