import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import GreenTick from '../../atoms/GreenTick/GreenTick';
import arrow from '../../../assets/select-arrow.svg';
import disabledArrow from '../../../assets/select-arrow-disabled.svg';

interface StyledSelectProps {
  disabled?: boolean;
  hasValue?: boolean;
  showTick?: boolean | null;
  type?: string;
}

const StyledSelect = styled.select<StyledSelectProps>`
  appearance: none;
  background: ${(props) => (props.disabled ? `url(${disabledArrow})` : `url(${arrow})`)} no-repeat
    right;
  background-color: ${({ theme, ...props }) =>
    props.disabled ? theme.grey.background.disabled : theme.white};
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: ${(props) => (props.hasValue ? 'bold' : '')};
  text-transform: none;
  line-height: 19px;
  padding: 22px 16px;
  width: 90%;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  ::-ms-expand {
    display: none;
  }
  ${(props) => (props.showTick ? 'padding-right: 85px' : 'padding-right: 40px')};
  ${(props) =>
    props.type === 'compact' &&
    css`
      max-height: 40px;
      padding: 10px 26px 10px 16px;
      color: ${({ theme }) => theme.outerSpace};
      font-family: 'proxima-soft';
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      background-color: white;
    `}
`;

export const Spacer = styled.div`
  height: '8px';
`;

interface OptionProps {
  id: string;
  value: string;
  name: string;
}

// eslint-disable-next-line react/destructuring-assignment
const Option: React.FC<OptionProps> = ({ id, value, name }) => (
  <option id={id} value={value}>
    {name}
  </option>
);

interface SelectProps extends StyledSelectProps {
  id: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  options: OptionProps[];
  placeholder: string;
  selectorType: string;
  showValidateTick: boolean | null;
  desktopRightMargin: string;
  value: string;
}

const Select: React.FC<SelectProps> = ({
  disabled,
  id,
  onChange,
  options,
  placeholder,
  selectorType,
  showValidateTick,
  type,
  value,
  desktopRightMargin,
}) => {
  return (
    <GreenTick
      id='green-tick-id'
      desktopMinWidth='0px'
      showTick={showValidateTick === null ? !!value : showValidateTick}
      mobileRightMargin={`${50}px`}
      desktopRightMargin={desktopRightMargin}
    >
      <StyledSelect
        data-test={selectorType}
        aria-label={id}
        id={id}
        onChange={onChange}
        type={type}
        disabled={disabled}
        value={value}
        hasValue={!!value}
        showTick={showValidateTick === null ? !!value : showValidateTick}
      >
        {placeholder && (
          <option id='account-component-option' key='default' value='' disabled>
            {placeholder}
          </option>
        )}
        {options.map((option) => {
          const { id: optionId, value: optionValue, name } = option;
          return <Option key={optionId} id={optionId} value={optionValue} name={name} />;
        })}
      </StyledSelect>
    </GreenTick>
  );
};

Select.defaultProps = {
  type: 'default',
  disabled: false,
  value: '',
  placeholder: '',
  showValidateTick: null,
};

export default Select;
