/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Styled from 'styled-components';

export const Title = Styled.div`
  color: ${({ theme }) => theme.textPrimary};
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 12px;
`;
