import Styled, { css } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

type ContainerProps = {
  leftWidth: number;
};

export const Container = Styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  & > div:nth-child(even) {
    width: calc(${({ leftWidth }): string => `${100 - leftWidth}% - 16px`});
  }

  & > div:nth-child(odd) {
    margin-right: 32px;
    width: calc(${({ leftWidth }): string => `${leftWidth}% - 16px`});
  }

  ${MediaQuery.mobile(css`
    & > div:nth-child(n) {
      width: 100%;
      margin-bottom: 32px;
    }

    flex-direction: column;
  `)}
`;
