/* eslint-disable @typescript-eslint/explicit-function-return-type */

import Client from './client';
import { buildRequestUrl, RequestTypes } from './clientUtils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const logout = () =>
  new Client.Builder().post(`${buildRequestUrl(RequestTypes.CSI)}/accnt/logout`).execute();

export const AccountClient = {
  logout,
};
