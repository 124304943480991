export const RETRIEVE_SUBSCRIPTION_REQUEST = 'purchases/RETRIEVE_SUBSCRIPTION_REQUEST';
export const RETRIEVE_SUBSCRIPTION_SUCCESS = 'purchases/RETRIEVE_SUBSCRIPTION_SUCCESS';
export const RETRIEVE_SUBSCRIPTION_FAILURE = 'purchases/RETRIEVE_SUBSCRIPTION_FAILURE';
export const CANCEL_SUBSCRIPTION_REQUEST = 'purchases/CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'purchases/CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'purchases/CANCEL_SUBSCRIPTION_FAILURE';

export const RETRIEVE_USER_POLICIES_REQUEST = 'purchases/RETRIEVE_USER_POLICIES_REQUEST';
export const RETRIEVE_USER_POLICIES_SUCCESS = 'purchases/RETRIEVE_USER_POLICIES_SUCCESS';
export const RETRIEVE_USER_POLICIES_FAILURE = 'purchases/RETRIEVE_USER_POLICIES_FAILURE';

export const CANCEL_POLICY_REQUEST = 'purchases/CANCEL_POLICY_REQUEST';
export const CANCEL_POLICY_SUCCESS = 'purchases/CANCEL_POLICY_SUCCESS';
export const CANCEL_POLICY_FAILURE = 'purchases/CANCEL_POLICY_FAILURE';
export const GET_REFUND_AMOUNT_REQUEST = 'purchases/GET_REFUND_AMOUNT_REQUEST';
export const GET_REFUND_AMOUNT_SUCCESS = 'purchases/GET_REFUND_AMOUNT_SUCCESS';
export const GET_REFUND_AMOUNT_FAILURE = 'purchases/GET_REFUND_AMOUNT_FAILURE';
export const GET_SUBS_REFUND_AMOUNT_REQUEST = 'purchases/GET_SUBS_REFUND_AMOUNT_REQUEST';
export const GET_SUBS_REFUND_AMOUNT_SUCCESS = 'purchases/GET_SUBS_REFUND_AMOUNT_SUCCESS';
export const GET_SUBS_REFUND_AMOUNT_FAILURE = 'purchases/GET_SUBS_REFUND_AMOUNT_FAILURE';
export const CLEAR_PURCHASES_ERROR = 'purchases/CLEAR_PURCHASES_ERROR';
export const CLEAR_CANCEL_SUCCESS_STATE = 'purchases/CLEAR_CANCEL_SUCCESS_STATE';
