import { AccountActions } from '../account/types';
import * as loginActionTypes from '../account/actionTypes';
import { ProfileState, ProfileActions } from './types';
import * as profileActionTypes from './actionTypes';

export const initialState: ProfileState = {
  personalDetails: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    licenceType: undefined,
    licenceNumber: '',
  },
  contactDetails: {
    occupationType: undefined,
    occupation: '',
    phoneNumber: '',
    email: '',
    canEdit: false,
    address: {
      UUID: '',
      AddressLine1: '',
      AddressLine2: '',
      County: '',
      FlatNumber: '',
      HouseName: '',
      HouseNumber: '',
      Postcode: '',
      Preview: '',
      Road: '',
      Town: '',
      addressID: '',
      isManual: false,
    },
  },
  loading: true,
  error: undefined,
};

const reducer = (
  state: ProfileState = initialState,
  action: AccountActions | ProfileActions,
): ProfileState => {
  switch (action.type) {
    case loginActionTypes.RETRIEVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        personalDetails: action.data.personalDetails,
        contactDetails: action.data.contactDetails,
        loading: false,
      };
    case profileActionTypes.UPDATE_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case profileActionTypes.UPDATE_CONTACT_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case profileActionTypes.UPDATE_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...action.contactDetails,
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
