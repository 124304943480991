import React, { useState } from 'react';
import { CardModal, H5, P, Button } from '@rentecarlo/component-library';
import { Status } from 'assets';
import { Spacer, TextContent, Content, IncedentIconContainer, StatusIcon } from './assets/styles';

import QuoteDateTimeInput from '../DateTimeInput/AccountDateTimeInput';

interface Props {
  makeClaim: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, date: string) => void;
  visible: boolean;
  error: boolean;
  height: string;
  close: () => void;
}

const CaptureIncidentDate: React.FC<Props> = ({ visible, error, close, makeClaim, height }) => {
  const [date, setDate] = useState('');

  const handleFinalDateTime = (value: string) => {
    setDate(value);
  };

  return (
    <CardModal visible={!!visible || !!error} maxWidth='343px' height={height} close={close}>
      <Content>
        <TextContent id='date-time-component'>
          <H5 lineHeight={25}>When was your incident?</H5>
          <Spacer />
          <QuoteDateTimeInput
            endDateTime={null}
            startDateTime={null}
            onChange={handleFinalDateTime}
            dateId='fromDate'
            timeId='fromTime'
            calenderIconId='fromCalenderIcon'
          />
          <IncedentIconContainer>
            <StatusIcon src={Status} />
            <P fontSize={14}>Please provide the estimated date and time of your incident.</P>
          </IncedentIconContainer>
          <Spacer height={16} />
          <Button
            id='start-claim-self-serve'
            borderWidth={2}
            onClick={(event) => {
              makeClaim(event, date);
              close();
            }}
            height={64}
            borderRadius={8}
            type='button'
            disabled={!date}
          >
            Next
          </Button>
        </TextContent>
      </Content>
    </CardModal>
  );
};

export default CaptureIncidentDate;
