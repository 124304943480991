import { SidebarItem } from 'components/atoms';
import { LoginStatus, SlideUpModal as MobileNavMenu } from 'components/molecules';
import React, { ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useOptimizelyFlag } from 'utils/hooks';

import {
  DesktopNavMenu,
  LinkWrapper,
  LogoutSpacer,
  MenuItemsWrapper,
  SidebarDivider,
  SidebarFooterContainer,
  SideBarStatusContainer,
} from './assets/styles';
import { navItems, Status } from './config';

const renderMenuItems = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any,
  loggedIn: boolean,
  toggleMenu: () => void,
  showReferrals: boolean,
): ReactElement => {
  return (
    <MenuItemsWrapper>
      {navItems.map((item, index) => {
        const selected = location.pathname === item.path;
        const shouldDisplay = showReferrals || item.id !== 'navReferrals';
        if (!shouldDisplay) {
          return null;
        }
        return item.type === 'link' && loggedIn ? (
          <LinkWrapper
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.path}${index}`}
            href={item.href || '#'}
            target='_blank'
            rel='noopener noreferrer'
            id={`${item.id}Link`}
          >
            <SidebarItem
              label={item.label}
              icon={selected ? item.selectedIcon : item.icon}
              onClick={() => {
                item.onClick();
                toggleMenu();
              }}
              active={selected}
              status={loggedIn ? item.status : Status.INACTIVE}
              id={item.id}
            />
          </LinkWrapper>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <Link key={`${item.path}${index}`} to={item.path} style={{ textDecoration: 'none' }}>
            <SidebarItem
              active={selected}
              label={item.label}
              onClick={() => {
                item.onClick();
                toggleMenu();
              }}
              icon={selected ? item.selectedIcon : item.icon}
              status={loggedIn ? item.status : Status.INACTIVE}
              id={item.id}
            />
          </Link>
        );
      })}
    </MenuItemsWrapper>
  );
};

const renderFooter = (loggedIn: boolean): ReactElement => (
  <SidebarFooterContainer>
    {loggedIn && (
      <SideBarStatusContainer>
        <SidebarDivider />
        <LoginStatus />
      </SideBarStatusContainer>
    )}
  </SidebarFooterContainer>
);

interface NavMenuInterface extends RouteComponentProps {
  loggedIn: boolean;
  close: boolean;
  toggleMenu: () => void;
}

const NavMenu: React.FC<NavMenuInterface> = ({ loggedIn, close, location, toggleMenu }) => {
  const showReferrals = useOptimizelyFlag('TEMP_BUYAPOWA_REFERRALS').enabled;
  return (
    <>
      <DesktopNavMenu>
        <LogoutSpacer>
          {renderMenuItems(location, loggedIn, toggleMenu, showReferrals)}
          {renderFooter(loggedIn)}
        </LogoutSpacer>
      </DesktopNavMenu>
      <MobileNavMenu close={close} offset={108}>
        {renderMenuItems(location, loggedIn, toggleMenu, showReferrals)}
        {renderFooter(loggedIn)}
      </MobileNavMenu>
    </>
  );
};

export default NavMenu;
