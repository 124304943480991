import React from 'react';
import { PageModal, H1, H5, Button, IconList } from '@rentecarlo/component-library';
import { Tick } from 'assets';
import {
  Container,
  ButtonsContainer,
  ButtonContainer,
  SubheadingContainer,
  Content,
  Text,
  SubText,
} from './assets/styles';

type Props = {
  show: boolean;
  close: () => void;
  startClaim: () => void;
};

const StartClaimInfo: React.FC<Props> = ({ show, close, startClaim }) => {
  const redirectToClaimHelpCentre = () => {
    const win = window.open('', '_blank');
    try {
      if (win) {
        win.location.href = 'https://www.veygo.com/help/make-a-claim-or-complaint/';
        win.focus();
      }
    } catch (error) {
      win?.close();
    }
  };

  return (
    <PageModal
      show={show}
      close={() => {
        close();
      }}
      closeText='Back'
    >
      {show && (
        <Container>
          <H1>Start a claim</H1>
          <SubheadingContainer>
            <H5>Ready to start your claim?</H5>
          </SubheadingContainer>
          <Content>
            <SubText>The easiest way to get started is through our chatbot:</SubText>
            <IconList
              id='start-claim-info-list'
              icon={Tick}
              items={[
                <Text>It usually takes 5 minutes.</Text>,
                <Text>
                  We’ll ask you a few questions about your incident and might request some pictures
                  if needed.
                </Text>,
                <Text>
                  Your claim will be saved at each step. We’ll text you a link so you can complete
                  it whenever it’s convenient for you.
                </Text>,
                <Text>
                  Once your claim is submitted, we’ll be in touch with you within 3 working days.
                </Text>,
              ]}
            />
            <Text>
              Skip the wait and start your claim with our chatbot. Click below to begin now.
            </Text>
          </Content>
          <ButtonsContainer>
            <ButtonContainer>
              <Button
                id='learn-more'
                borderWidth={0.5}
                height={64}
                borderRadius={8}
                type='button'
                buttonType='outline'
                onClick={redirectToClaimHelpCentre}
              >
                Learn more
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button
                id='start-claim-self-serve'
                borderWidth={2}
                onClick={startClaim}
                height={64}
                borderRadius={8}
                type='button'
              >
                Start claim now
              </Button>
            </ButtonContainer>
          </ButtonsContainer>
        </Container>
      )}
    </PageModal>
  );
};
export default StartClaimInfo;
