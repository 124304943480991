import Debug from 'debug';
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { Loader } from 'pages/DetailedPurchase/assets/styles';
import { LoadingIcon } from 'assets';

import {
  BuyapowaEmbed,
  SomethingWentWrong,
  Container,
  ReferralsPageLayout,
  ReferralsContent,
  BuyapowaLoaderContainer,
} from './assets/styles';

const debug = Debug('veygo:pages:referrals');
// eslint-disable-next-line
declare var Buyapowa: any;

export interface ComponentProps {
  name: string;
  email: string;
  buyapowaSignature: string;
}

const ReferralBuyapowa = ({ name, email, buyapowaSignature }: ComponentProps) => {
  useEffect(() => {
    const buyapowa = new Buyapowa(
      { url: process.env.REACT_APP_BUYAPOWA_URL, market: process.env.REACT_APP_BUYAPOWA_MARKET },
      'bp_div',
      'bp_error',
    );
    buyapowa.embedReferAFriend({
      campaign: process.env.REACT_APP_BUYAPOWA_CAMPAIGN,
      name,
      email,
      locale: 'en',
      signed_keys: 'name, email',
      marketing_opt_in: true,
      signature: buyapowaSignature,
    });
  }, [name, email, buyapowaSignature]);

  return (
    <ReferralsPageLayout>
      <ReferralsContent>
        <Container>
          <SomethingWentWrong id='bp_error'>
            Sorry, something went wrong, please try refreshing the page.
          </SomethingWentWrong>
          <BuyapowaEmbed id='bp_div' />
        </Container>
      </ReferralsContent>
    </ReferralsPageLayout>
  );
};

const Referrals: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const buyapowaSignature = useSelector((state: RootState) => state.account.buyapowaSignature);
  const firstName = useSelector((state: RootState) => state.profile.personalDetails.firstName);
  const lastName = useSelector((state: RootState) => state.profile.personalDetails.lastName);
  const email = useSelector((state: RootState) => state.profile.contactDetails.email);

  useEffect(() => {
    if ([buyapowaSignature, firstName, lastName, email].every(Boolean)) {
      debug('turning loader off');
      setIsLoading(false);
    }
    debug(`values not set firstName: ${firstName},
            buyapowaSignature: ${buyapowaSignature}, lastName: ${lastName}, email: ${email}`);
  }, [buyapowaSignature, firstName, lastName, email]);

  return isLoading ? (
    <BuyapowaLoaderContainer>
      <Loader src={LoadingIcon} id='loadingImg' />
    </BuyapowaLoaderContainer>
  ) : (
    <ReferralBuyapowa
      name={`${firstName} ${lastName}`}
      email={email}
      buyapowaSignature={buyapowaSignature}
    />
  );
};

export default Referrals;
