import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'state';
import { NotificationErrorBox } from './NotificationErrorBox';

interface NotificationErrorBoxContainerInterface {
  isVisible: boolean;
}

const NotificationErrorBoxContainer: React.FC<NotificationErrorBoxContainerInterface> = (props) => {
  const { isVisible } = props;
  return <NotificationErrorBox isVisible={isVisible} />;
};

const mapStateToProps = (state: AppState): NotificationErrorBoxContainerInterface => ({
  isVisible: state.notifications.present.updateFailed,
});

export default connect(mapStateToProps, null)(NotificationErrorBoxContainer);
