import React from 'react';
import { FieldProps } from 'formik';

import { OccupationModal } from '@rentecarlo/component-library';

type OptionType = {
  name: string;
  value: string;
};

interface ComponentProps {
  occupations: Array<OptionType>;
  disabled: boolean;
  close: () => void;
  show: boolean;
}

interface OccupationEditField extends FieldProps, ComponentProps {}

const OccupationDropDownField: React.FC<OccupationEditField> = ({
  field,
  occupations,
  ...others
}) => {
  return <OccupationModal occupations={occupations} {...field} {...others} />;
};

export default OccupationDropDownField;
