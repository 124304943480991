export const LOGIN_VERIFIED = 'account/LOGIN_VERIFIED';
export const LOGIN_NOT_VERIFIED = 'account/LOGIN_NOT_VERIFIED';
export const LOGOUT_REQUEST = 'account/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'account/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'account/LOGOUT_FAILURE';
export const RETRIEVE_ACCOUNT_REQUEST = 'account/RETRIEVE_ACCOUNT_REQUEST';
export const RETRIEVE_ACCOUNT_SUCCESS = 'account/RETRIEVE_ACCOUNT_SUCCESS';
export const RETRIEVE_ACCOUNT_FAILURE = 'account/RETRIEVE_ACCOUNT_FAILURE';
export const TOGGLE_MENU = 'account/TOGGLE_MENU';
export const CUSTOMER_INITIATED_LOGOUT = 'account/CUSTOMER_INITIATED_LOGOUT';
export const SET_CROSS_SELL_MODAL_VISIBILITY = 'account/SET_CROSS_SELL_MODAL_VISIBILITY';
export const SET_BUYAPOWA_SIGNATURE_SUCCESS = 'account/SET_BUYAPOWA_SIGNATURE_SUCCESS';
