import styled from 'styled-components';

interface StyleProps {
  bottomMargin?: string;
}

export const FieldContainer = styled.div<StyleProps>`
  border: ${({ theme }) => theme.borderPrimary};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  box-shadow: ${({ theme }) => `0 2px 8px 0 ${theme.shadowPrimary}`};
  height: 64px;
  display: flex;
  margin-top: 8px;
  margin-bottom: ${({ bottomMargin }) => bottomMargin || ''};
`;

export const Container = styled.div<StyleProps>`
  margin-bottom: ${({ bottomMargin }) => bottomMargin || '57px'};
`;

export const ContentLoaderContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
`;

export const ContentLoaderWrapper = styled.div`
  width: 408px;
`;

export const AddressLoaderContainer = styled.div`
  height: 114px;
  border: ${({ theme }) => theme.borderPrimary};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  box-shadow: ${({ theme }) => `0 2px 8px 0 ${theme.shadowPrimary}`};
  margin-bottom: 57px;
  margin-top: 8px;
  display: flex;
`;

export const DetailsLoaderContainer = styled.div`
  height: 172px;
  border: ${({ theme }) => theme.borderPrimary};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  box-shadow: ${({ theme }) => `0 2px 8px 0 ${theme.shadowPrimary}`};
  display: flex;
`;

export const FormItemLabel = styled.span`
  height: 19px;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.32px;
  line-height: 19px;
`;

export const OccupationContainer = styled.div`
  display: inherit;
  position: relative;
  background-color: ${({ theme }) => `${theme.backgroundAlt}`};
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
`;

export const Arrow = styled.div`
  display: block;
  position: absolute;
  top: -7px;
  left: 90px;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => `${theme.backgroundAlt}`};
  border-radius: 2px;
  transform: rotate(45deg);
`;
