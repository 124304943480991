import { useState } from 'react';
import * as Sentry from '@sentry/react';

import { buildRequestUrl, RequestTypes } from 'services/clientUtils';
import { Occupations } from '@rentecarlo/component-library';

interface State {
  occupationState: {
    foundOccupations?: Occupations;
  };
  getOccupations(): void;
}

const useOccupationLookup = (): State => {
  // Hook state
  const [foundOccupations, setOccupations] = useState<Occupations>();

  // Request to retrieve occupations
  const getOccupations = (): void => {
    fetch(`${buildRequestUrl(RequestTypes.CSI)}/config/occupation-by-type/`)
      .then((response) => response.json())
      .then((response) => setOccupations(response))
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('name', error.name);
          scope.setExtra('stacktrace', error.stack);
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureException(error);
        });
      });
  };

  // Only exposes what is required by the component
  return {
    occupationState: { foundOccupations },
    getOccupations,
  };
};

export default useOccupationLookup;
