import * as actionTypes from './actionTypes';
import {
  Account,
  LoginNotVerified,
  LoginVerified,
  LogoutFailure,
  LogoutRequest,
  LogoutSuccess,
  RetrieveAccountRequest,
  RetrieveAccountFailure,
  RetrieveAccountSuccess,
  ToggleMenu,
  CustomerInitiatedLogout,
  SetCrossSellModalVisibility,
  SetBuyapowaSignatureSuccess,
} from './types';

const loginNotVerified = (): LoginNotVerified => ({
  type: actionTypes.LOGIN_NOT_VERIFIED,
});

const loginVerified = (): LoginVerified => ({
  type: actionTypes.LOGIN_VERIFIED,
});

const logoutRequest = (): LogoutRequest => ({
  type: actionTypes.LOGOUT_REQUEST,
});

const logoutSuccess = (): LogoutSuccess => ({
  type: actionTypes.LOGOUT_SUCCESS,
});

const logoutFailure = (): LogoutFailure => ({
  type: actionTypes.LOGOUT_FAILURE,
});

const retrieveAccountRequest = (): RetrieveAccountRequest => ({
  type: actionTypes.RETRIEVE_ACCOUNT_REQUEST,
});

const retrieveAccountSuccess = (data: Account): RetrieveAccountSuccess => ({
  data,
  type: actionTypes.RETRIEVE_ACCOUNT_SUCCESS,
});

const retrieveAccountFailure = (error: string): RetrieveAccountFailure => ({
  error,
  type: actionTypes.RETRIEVE_ACCOUNT_FAILURE,
});

const toggleMenu = (): ToggleMenu => ({
  type: actionTypes.TOGGLE_MENU,
});

const customerInitiatedLogout = (): CustomerInitiatedLogout => ({
  type: actionTypes.CUSTOMER_INITIATED_LOGOUT,
});

const setCrossSellModalVisibility = (
  showModal: boolean,
  isDriverOwner?: boolean,
  isSubscription?: boolean,
): SetCrossSellModalVisibility => ({
  showModal,
  isDriverOwner,
  isSubscription,
  type: actionTypes.SET_CROSS_SELL_MODAL_VISIBILITY,
});

const setBuyapowaSignatureSuccess = (buyapowaSignature: string): SetBuyapowaSignatureSuccess => ({
  buyapowaSignature,
  type: actionTypes.SET_BUYAPOWA_SIGNATURE_SUCCESS,
});

export const actionCreators = {
  loginNotVerified,
  loginVerified,
  logoutFailure,
  logoutRequest,
  logoutSuccess,
  retrieveAccountFailure,
  retrieveAccountRequest,
  retrieveAccountSuccess,
  toggleMenu,
  customerInitiatedLogout,
  setCrossSellModalVisibility,
  setBuyapowaSignatureSuccess,
};
