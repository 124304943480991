import { SagaIterator } from 'redux-saga';
import * as Sentry from '@sentry/react';
import { call, takeEvery, put, select } from 'redux-saga/effects';
import { ProfileClient, BackendClient, optimizelyClient } from 'services';
import { AppState } from 'state';
import { Address } from '@rentecarlo/component-library';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import {
  UpdateContactDetailsRequestAction,
  AddressAPITransformV3,
  ContactDetailsState,
  ProfileAPIResponse,
} from './types';

const getAddressUuid = (state: AppState) => state.profile.contactDetails.address.UUID;

const getUserIdentity = (state: AppState) => state.config.optimizelyAttributes.user_identity;

export const APIToAddressTransformer = (APIResponse: AddressAPITransformV3): Address => {
  return {
    UUID: '',
    AddressLine1: APIResponse.line1,
    AddressLine2: APIResponse.line2,
    County: APIResponse.county,
    FlatNumber: APIResponse.flat_no,
    HouseName: APIResponse.house_name,
    HouseNumber: APIResponse.house_no,
    Postcode: APIResponse.postcode,
    Preview: '',
    Road: APIResponse.road,
    Town: APIResponse.town,
    addressID: '',
    isManual: false,
  };
};

export const APIToContactDetailsTransformer = (
  APIResponse: ProfileAPIResponse,
): ContactDetailsState => {
  return {
    occupationType: APIResponse.occupation_type,
    occupation: APIResponse.occupation,
    phoneNumber: APIResponse.phone_number,
    email: APIResponse.email,
    canEdit: APIResponse.can_edit,
    address: APIToAddressTransformer(APIResponse.address),
  };
};

function* updateContactDetails(action: UpdateContactDetailsRequestAction): SagaIterator {
  try {
    const actionData = action.contactDetails;

    const requestData: ContactDetailsState = {
      occupationType: actionData.occupationType,
      occupation: actionData.occupation,
      phoneNumber: actionData.phoneNumber,
      email: actionData.newEmail || actionData.email,
      canEdit: actionData.canEdit,
      address: actionData.address,
    };

    const addressUuid = yield select(getAddressUuid);
    requestData.address.UUID = addressUuid;

    const userIdentity = yield select(getUserIdentity);

    const accountBFFenabled = optimizelyClient.isFeatureEnabled(
      'TEMP_ENABLE_ACCOUNT_BFF',
      userIdentity,
      { user_identity: userIdentity },
    );

    let contactDetails;
    if (accountBFFenabled) {
      contactDetails = yield call(BackendClient.updateCustomerContactDetails, requestData);
    } else {
      const response = yield call(ProfileClient.updateContactDetails, requestData, 'v3/me');

      contactDetails = APIToContactDetailsTransformer(response);
    }

    yield put(actions.updateContactDetailsSuccess(contactDetails));
  } catch (error) {
    const actualError: Error = error as Error;

    Sentry.withScope((scope) => {
      scope.setExtra('name', actualError.name);
      scope.setExtra('stacktrace', actualError.stack);
      scope.setLevel(Sentry.Severity.Error);
      Sentry.captureException(error);
    });

    yield put(actions.updateContactDetailsFailure(error as string));
  }
}

export default function* watchProfileSaga(): SagaIterator {
  yield takeEvery(actionTypes.UPDATE_CONTACT_DETAILS_REQUEST, updateContactDetails);
}
