import { FieldProps } from 'formik';
import React from 'react';
import { OccupationSelect, OccupationTypes } from '@rentecarlo/component-library';
import { occupationTypeMap } from '../../../services/occupationTypes';

interface OccupationFieldProps {
  id: string;
}

interface ComponentProps {
  disabled: boolean;
  occupation: string;
  value: OccupationTypes;
  onClick: () => void;
  setOccupationType: (arg0: string) => void;
}

interface OccupationField extends FieldProps, OccupationFieldProps, ComponentProps {}

// Formik Field Input handler
const OccupationTypeSelectField: React.FC<OccupationField> = ({
  field,
  occupation,
  value,
  ...others
}) => {
  return (
    <OccupationSelect
      occupationTypes={occupationTypeMap}
      occupationType={value}
      occupation={occupation}
      {...field}
      {...others}
    />
  );
};

export default OccupationTypeSelectField;
