import Styled, { css } from 'styled-components';
import { Fonts } from 'utils/config';
import { MediaQuery } from '@rentecarlo/component-library';

type ContainerProps = {
  bottomBorder?: boolean;
};

export const Container = Styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  border-bottom: ${({ bottomBorder, theme }): string =>
    bottomBorder ? `0.5px solid ${theme.fieldSecondary}` : ''};
  ${MediaQuery.mobile(css`
    flex-shrink: 0;
  `)}
`;

export const Title = Styled.h5`
  margin-left: 8px;
  margin-top: 24px;
  margin-bottom: 8px;
  height: 24px;
  color: ${({ theme }): string => theme.textSecondary};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 24px;
  font-family: ${Fonts.title};
`;

export const ElementsContainer = Styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
