import { SagaIterator } from 'redux-saga';
import { call, delay, fork, put, select, take } from 'redux-saga/effects';
import { NotificationsClient, BackendClient, optimizelyClient } from 'services';
import { AppState } from 'state';
import { NotificationState } from 'state/notifications/types';
import * as actionTypes from './actionTypes';
import { actionCreators } from './actions';

const getUserIdentity = (state: AppState) => state.config.optimizelyAttributes.user_identity;

function* updatePreferences(notifications: NotificationState): SagaIterator {
  try {
    const userIdentity = yield select(getUserIdentity);
    const accountBFFenabled = optimizelyClient.isFeatureEnabled(
      'TEMP_ENABLE_ACCOUNT_BFF',
      userIdentity,
      { user_identity: userIdentity },
    );

    if (accountBFFenabled) {
      yield call(BackendClient.updateCustomerMarketingPreferences, {
        phoneConsent: notifications.phoneConsent,
        emailConsent: notifications.emailConsent,
      });
    } else {
      yield call(NotificationsClient.updatePreferences, notifications, 'v3/me');
    }
    yield put(actionCreators.updateSuccessful());
  } catch (error) {
    yield delay(200);
    yield put(actionCreators.undoNotifications());
    yield put(actionCreators.updateFailed());
  }
}

export default function* watchNotificationToggles(): SagaIterator {
  while (true) {
    yield take([actionTypes.TOGGLE_NOTIFICATION]);
    const notifications = yield select((state: AppState) => state.notifications.present);
    yield fork(updatePreferences, notifications);
  }
}
