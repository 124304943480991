import React, { ReactElement } from 'react';
import { Formik, Form, Field } from 'formik';

import { PageModal, H1, H5, Button } from '@rentecarlo/component-library';
import { CheckboxListField } from 'components/molecules';
import { getCancellationReasons } from 'services/cancellationReasons';
import {
  Container,
  ButtonsContainer,
  ButtonContainer,
  InfoContainer,
  SubheadingContainer,
} from './assets/styles';
import { isChecked } from '../../../utils/validation';

type Props = {
  close: () => void;
  cancel: (reason: string, comment: string) => void;
  show: boolean;
  children: React.ReactElement[] | React.ReactElement;
  disabled?: boolean;
  learner: boolean;
};

type InputProps = {
  cancellationReason: string;
};

const initialValues: InputProps = {
  cancellationReason: '',
};

const CancelModal: React.FC<Props> = ({
  show,
  close,
  cancel,
  children,
  disabled = false,
  learner,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={() => {}} validateOnChange>
      {({ values, setFieldValue, validateForm, touched }): ReactElement => {
        return (
          <Form>
            <PageModal
              show={show}
              close={() => {
                setFieldValue('cancellationReason', '');
                close();
              }}
              closeText='Back'
            >
              {show && (
                <Container>
                  <H1>Are you sure you want to cancel this policy?</H1>
                  <InfoContainer>{children}</InfoContainer>
                  <SubheadingContainer>
                    <H5>Please let us know your reason for cancelling</H5>
                  </SubheadingContainer>
                  <Field
                    component={CheckboxListField}
                    name='cancel-reason'
                    options={getCancellationReasons(learner, false, false)}
                    value={values.cancellationReason}
                    onChange={(selectedOption: string): void => {
                      Promise.resolve(
                        setFieldValue('cancellationReason', selectedOption, true),
                      ).then(() => validateForm());
                    }}
                    validate={() => isChecked(values.cancellationReason !== '')}
                    touched={touched}
                  />
                  <ButtonsContainer>
                    <ButtonContainer>
                      <Button
                        id='no-cancel-policy'
                        borderWidth={2}
                        onClick={() => {
                          setFieldValue('cancellationReason', '');
                          close();
                        }}
                        height={64}
                        buttonType='outline'
                        borderRadius={8}
                        type='button'
                        disabled={disabled}
                      >
                        Don't cancel
                      </Button>
                    </ButtonContainer>
                    <ButtonContainer>
                      <Button
                        id='cancel-policy'
                        borderWidth={0.5}
                        height={64}
                        borderRadius={8}
                        type='submit'
                        onClick={(event: MouseEvent) => {
                          event.preventDefault();
                          validateForm().then((result) => {
                            if (Object.keys(result).length === 0) {
                              cancel(values.cancellationReason, '');
                            }
                          });
                        }}
                        disabled={disabled}
                      >
                        Cancel policy
                      </Button>
                    </ButtonContainer>
                  </ButtonsContainer>
                </Container>
              )}
            </PageModal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CancelModal;
