import { CognitoClient } from '@rentecarlo/node-amplify-client';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
interface AdditionalHeaders {
  'Content-Type'?: string;
  accept?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const getCookie = (cookieName: string): string => {
  if (document.cookie) {
    const cookieSuffix = `${cookieName}=`;
    // Split the cookies into an array
    const splitDocumentCookies = document.cookie.split('; ');
    // Filter the array of cookies to find our cookie
    const findCSRFCookie =
      splitDocumentCookies.length > 0
        ? splitDocumentCookies.filter((cookie) => cookie.includes(cookieSuffix))
        : [];
    // If we have a match, set the cookie to the value
    // If filter returns an empty array, then just set the csrftoken to an empty string
    return findCSRFCookie.length > 0 ? findCSRFCookie[0].replace(cookieSuffix, '') : '';
  }

  return '';
};

const isUserNotLoggedInError = (error: unknown) =>
  typeof error === 'object' && error !== null && 'code' in error && error.code === 'noUserSession';

const retrieveCognitoBearerToken = async (): Promise<string> => {
  try {
    const { idToken } = await CognitoClient.getCurrentSession();
    return `Bearer ${idToken}`;
  } catch (err) {
    if (!isUserNotLoggedInError(err)) {
      // eslint-disable-next-line no-console
      console.error('error', err);
    }
  }

  return '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleResponse = async (response: Response): Promise<any> => {
  if (response.ok) {
    try {
      const responseBody = await response.json();
      return await Promise.resolve(responseBody);
    } catch (error) {
      // The purpose of this catch is to prevent the frontend from completely
      // crashing when a non-json response is returned to the Client.
      return Promise.resolve({});
    }
  }

  try {
    // Nice opportunity to tie in the error payloads
    const errorResponse = await response.json();
    // eslint-disable-next-line prefer-promise-reject-errors, @typescript-eslint/return-await
    return Promise.reject(errorResponse);
  } catch (error) {
    return Promise.reject(response);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleBlobResponse = async (response: Response): Promise<any> => {
  if (response.ok) {
    try {
      const responseBody = await response.blob();
      return await Promise.resolve(responseBody);
    } catch (error) {
      // The purpose of this catch is to prevent the frontend from completely
      // crashing when a non-json response is returned to the Client.
      return Promise.resolve({});
    }
  }

  try {
    // Nice opportunity to tie in the error payloads
    const errorResponse = await response.json();
    // eslint-disable-next-line prefer-promise-reject-errors
    return await Promise.reject({
      response: errorResponse.data || errorResponse,
      status: response.status,
    });
  } catch (error) {
    return Promise.reject(response);
  }
};

class Builder {
  public requestHeaders: { [key: string]: string };

  public requestMethod: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private requestData: any;

  private requestSecured: boolean;

  private requestUrl: string;

  constructor() {
    const csrftoken = getCookie('csrftoken');
    this.requestMethod = '';
    // default secured to true
    this.requestSecured = true;
    this.requestUrl = '';
    this.requestHeaders = {
      'Content-Type': 'application/json',
      accept: 'application/json',
      CSRF: csrftoken.trim(),
    };
    this.requestData = null;
  }

  public get(url: string) {
    this.requestMethod = 'GET';
    this.requestUrl = url;
    return this;
  }

  public put(url: string) {
    this.requestMethod = 'PUT';
    this.requestUrl = url;
    return this;
  }

  public post(url: string) {
    this.requestMethod = 'POST';
    this.requestUrl = url;
    return this;
  }

  public delete(url: string) {
    this.requestMethod = 'DELETE';
    this.requestUrl = url;
    return this;
  }

  public data(data: unknown) {
    this.requestData = data;
    return this;
  }

  public head(url: string) {
    this.requestMethod = 'HEAD';
    this.requestUrl = url;
    return this;
  }

  public unsecured() {
    this.requestSecured = false;
    return this;
  }

  public additionalHeaders(headers: AdditionalHeaders) {
    this.requestHeaders = {
      ...this.requestHeaders,
      ...headers,
    };
    return this;
  }

  public execute = async () => {
    if (this.requestSecured) {
      this.requestHeaders = {
        ...this.requestHeaders,
        Authorization: await retrieveCognitoBearerToken(),
      };
    }

    const response: Response = await fetch(`${this.requestUrl}`, {
      body: this.requestData ? JSON.stringify(this.requestData) : undefined,
      credentials: 'include',
      headers: this.requestHeaders,
      method: this.requestMethod,
    });

    return handleResponse(response);
  };

  public executeBlobResp = async () => {
    if (this.requestSecured) {
      this.requestHeaders = {
        ...this.requestHeaders,
        Authorization: await retrieveCognitoBearerToken(),
      };
    }

    const response: Response = await fetch(`${this.requestUrl}`, {
      body: this.requestData,
      credentials: 'include',
      headers: this.requestHeaders,
      method: this.requestMethod,
    });

    return handleBlobResponse(response);
  };
}

const client = {
  Builder,
  getCookie,
  retrieveCognitoBearerToken,
};

export default client;
