import styled, { css } from 'styled-components';
import { FullPage, Content } from 'components/templates/PageLayout/assets/styles';

import { MediaQuery } from '@rentecarlo/component-library';

export const ReferralsPageLayout = styled(FullPage)`
  margin: 0px;
`;

export const ReferralsContent = styled(Content)`
  max-width: unset;
`;

export const BuyapowaEmbed = styled.div`
  min-height: 800px;
  min-width: 871px;
  ${MediaQuery.mobile(css`
    min-width: unset;
    width: 100%;
    margin: 0;
    padding: 0;
  `)}
`;

export const SomethingWentWrong = styled.div`
  display: none;
`;

export const Container = styled.div`
  margin-top: 48px;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${MediaQuery.mobile(css`
    margin-top: 32px;
  `)}
`;

export const BuyapowaLoaderContainer = styled(Container)`
  justify-content: center;
  margin-top: 0px;
  align-items: center;
`;
