import React from 'react';
import { Container, ErrorIcon, InfoText, Wrapper } from './assets/styles';

interface Props {
  children: React.ReactNode;
  type?: string;
}

const InformationBox: React.FC<Props> = ({ children, type = 'info' }: Props) => {
  return (
    <Container type={type}>
      <Wrapper>
        {type === 'error' && <ErrorIcon />}
        <InfoText>{children}</InfoText>
      </Wrapper>
    </Container>
  );
};

export default InformationBox;
