// Unsure about how the mandatory boolean isVisible should be handled in <NotificationErrorBox />
// TypeScript currently fails - need to investigate.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { PageTitle, Subtitle } from 'components/atoms';
import { NotificationErrorBox, NotificationToggle } from 'components/molecules';
import { PageLayout } from 'components/templates';
import { Card } from '@rentecarlo/component-library';
import React from 'react';
import styled from 'styled-components';
import {
  Container,
  EmailIcon,
  HorizontalDivider,
  OptionContainer,
  OptionLabel,
  ResponsiveContainer,
  SubContainer,
  TelephoneIcon,
  VerticalDivider,
} from './assets/styles';

const Copy = styled.div`
  color: #364344;
  font-family: proxima-soft;
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f3f4f7;
  border-radius: 8px;
  padding: 16px;
  margin-top: 14px;
`;

const Notifications: React.FC<unknown> = () => {
  return (
    <PageLayout>
      <Container>
        <PageTitle id='pageTitle'>Marketing Preferences</PageTitle>
        <SubContainer>
          <Subtitle>How would you like us to contact you?</Subtitle>
          <Card>
            <ResponsiveContainer>
              <OptionContainer>
                <EmailIcon />
                <OptionLabel>Email</OptionLabel>
                <NotificationToggle toggleName='emailConsent' />
              </OptionContainer>
              <HorizontalDivider />
              <VerticalDivider />
              <OptionContainer>
                <TelephoneIcon />
                <OptionLabel>SMS</OptionLabel>
                <NotificationToggle toggleName='phoneConsent' />
              </OptionContainer>
            </ResponsiveContainer>
          </Card>
          <Copy>
            We need your number in case we need to speak with you regarding your insurance. If you
            have SMS toggled on we may also send you marketing updates via SMS. If you do not wish
            to receive marketing updates via SMS or email simply toggle them here. You can change
            these preferences at any time. We may still contact you via phone, SMS or email about
            your insurance.
          </Copy>
        </SubContainer>
        <SubContainer>
          <NotificationErrorBox />
        </SubContainer>
      </Container>
    </PageLayout>
  );
};

export default Notifications;
