import { SagaIterator } from 'redux-saga';
import * as Sentry from '@sentry/react';
import { call, put, takeEvery } from 'redux-saga/effects';
import { VeygoError, PolicyStatus, Subscription, Policy } from '@rentecarlo/component-library';
import { buildRequestUrl, Client, RequestTypes } from 'services';
import { PurchasesClient } from 'services/purchasesClient';
import { actionCreators as AccountActions } from 'state/account/actionCreators';
import {
  GetRefundAmountRequest,
  CancelSubscription,
  CancelPolicy,
  CancellationAPIResponse,
  CancellationResponse,
  GetSubsRefundAmountRequest,
} from './types';
import { actionCreators as PurchasesActions } from './actions';
import * as actionTypes from './actionTypes';

const getSubscriptionPolicy = (subscription: Subscription): Policy => {
  const { activePolicy, upcomingPolicy, previousPolicies } = subscription;

  return subscription.status === PolicyStatus.UPCOMING && upcomingPolicy
    ? upcomingPolicy
    : activePolicy ?? previousPolicies[0];
};

const cancellationAPIResponseToPolicyTransformer = (
  response: CancellationAPIResponse,
): CancellationResponse => {
  return {
    uuid: response.uuid,
    cancellationDateTime: response.cancellation_datetime,
  };
};

const subscriptionsGetAPIResponseToSubsciptionTransformer = (
  response: Record<string, Array<Subscription>>,
) => {
  return {
    active: response.active.map((subscription) => ({
      ...subscription,
      status: PolicyStatus.ACTIVE,
    })),
    ended: response.ended.map((subscription) => ({ ...subscription, status: PolicyStatus.ENDED })),
    upcoming: response.upcoming.map((subscription) => ({
      ...subscription,
      status: PolicyStatus.UPCOMING,
    })),
  };
};

const handleError = (error: Error) => {
  Sentry.withScope((scope) => {
    scope.setExtra('name', error.name);
    scope.setExtra('stacktrace', error.stack);
    scope.setLevel(Sentry.Severity.Error);
    Sentry.captureException(error);
  });
};

function* getSubscriptions(): SagaIterator {
  try {
    const subscriptionsRequest = new Client.Builder().get(
      `${buildRequestUrl(RequestTypes.CSI)}/subscriptions/me/`,
    ).execute;

    const { subscriptions } = yield call(subscriptionsRequest);

    yield put(
      PurchasesActions.retrieveSubscriptionsSuccess(
        subscriptionsGetAPIResponseToSubsciptionTransformer(subscriptions),
      ),
    );
  } catch (e) {
    const error = e as VeygoError;
    if (error instanceof Error) {
      handleError(error);
    }
    yield put(PurchasesActions.retrieveSubscriptionsFailure(error));
  }
}

function* getPolicies(): SagaIterator {
  try {
    const policiesRequest = new Client.Builder().get(
      `${buildRequestUrl(RequestTypes.CSI)}/api/policies/me`,
    ).execute;

    const { policies } = yield call(policiesRequest);
    yield put(PurchasesActions.retrieveUserPoliciesSuccess(policies));
  } catch (e) {
    const error = e as VeygoError;
    if (error instanceof Error) {
      handleError(error);
    }
    yield put(PurchasesActions.retrieveUserPoliciesFailure(error));
  }
}

function* cancelSubscription(action: CancelSubscription): SagaIterator {
  try {
    yield call(
      PurchasesClient.cancelSubscription,
      action.subscription,
      action.cancellationReason,
      action.cancelType.toString(),
      action.comment,
    );

    yield put(PurchasesActions.cancelSubscriptionSuccess(action.cancelType));

    if (action.cancellationReason === 'I have passed my test') {
      const subscriptionPolicy = getSubscriptionPolicy(action.subscription);
      yield put(
        AccountActions.setCrossSellModalVisibility(true, !!subscriptionPolicy.isDriverOwner, true),
      );
    }

    yield put(PurchasesActions.retrieveSubscriptions());
  } catch (e) {
    const error = e as VeygoError;
    if (error instanceof Error) {
      handleError(error);
    }

    yield put(PurchasesActions.cancelSubscriptionFailure(error));
  }
}

function* cancelPolicy(action: CancelPolicy): SagaIterator {
  try {
    const response = yield call(
      PurchasesClient.cancelPolicy,
      action.policy,
      action.cancellationReason,
      action.comment,
    );

    yield put(
      PurchasesActions.cancelPolicySuccess(cancellationAPIResponseToPolicyTransformer(response)),
    );

    if (action.cancellationReason === 'I have passed my test') {
      yield put(
        AccountActions.setCrossSellModalVisibility(true, !!action.policy.isDriverOwner, false),
      );
    }
  } catch (e) {
    const error = e as VeygoError;
    if (error instanceof Error) {
      handleError(error);
    }
    yield put(PurchasesActions.cancelPolicyFailure(error));
  }
}

function* getRefundAmount(action: GetRefundAmountRequest): SagaIterator {
  try {
    const data = yield call(PurchasesClient.getRefundAmount, action.uuid);
    yield put(PurchasesActions.getRefundAmountSuccess(data.amount, action.uuid));
  } catch (e) {
    const error = e as VeygoError;
    if (error instanceof Error) {
      handleError(error);
    }
    yield put(PurchasesActions.getRefundAmountFailure(error));
  }
}

function* getSubsRefundAmount(action: GetSubsRefundAmountRequest): SagaIterator {
  try {
    const data = yield call(PurchasesClient.getRefundAmount, action.policyUuid);
    yield put(PurchasesActions.getSubsRefundAmountSuccess(data.amount, action.subsUuid));
  } catch (e) {
    const error = e as VeygoError;
    if (error instanceof Error) {
      handleError(error);
    }
    yield put(PurchasesActions.getSubsRefundAmountFailure(error));
  }
}

export default function* watchPurchasesSaga(): SagaIterator {
  yield takeEvery(actionTypes.RETRIEVE_USER_POLICIES_REQUEST, getPolicies);
  yield takeEvery(actionTypes.RETRIEVE_SUBSCRIPTION_REQUEST, getSubscriptions);
  yield takeEvery(actionTypes.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription);
  yield takeEvery(actionTypes.CANCEL_POLICY_REQUEST, cancelPolicy);
  yield takeEvery(actionTypes.GET_REFUND_AMOUNT_REQUEST, getRefundAmount);
  yield takeEvery(actionTypes.GET_SUBS_REFUND_AMOUNT_REQUEST, getSubsRefundAmount);
}
