import React, { ReactNode } from 'react';
import { Button, PillButtonText } from './assets/styles';

interface Props {
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  children: ReactNode;
  fontSize?: number;
  fontWeight?: string;
  height?: number;
  hoverTextColor?: string;
  id: string;
  onClick?: () => void;
  paddingHorizontal?: number;
  textColor?: string;
}

const PillButton: React.FC<Props> = (props: Props) => {
  const {
    backgroundColor,
    borderColor,
    borderWidth = 0,
    children,
    fontSize,
    fontWeight,
    height,
    hoverTextColor,
    id,
    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    onClick = (): void => {},
    paddingHorizontal,
    textColor,
  } = props;
  return (
    <Button
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderWidth={borderWidth}
      height={height}
      hoverTextColor={hoverTextColor}
      id={id}
      onClick={onClick}
      paddingHorizontal={paddingHorizontal}
      textColor={textColor}
      type='button'
    >
      <PillButtonText fontSize={fontSize} fontWeight={fontWeight}>
        {children}
      </PillButtonText>
    </Button>
  );
};

export default PillButton;
