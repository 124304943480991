/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AccountIcon, HelpIcon, HelpIconDark, VeygoLogoIcon } from 'assets';

import Styled, { css } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

interface HelpIconInterface {
  hoverActive: boolean;
}

export const Container = Styled.div`
  height: 60px;
  min-height: 60px;
  width: 100%;
  background-color: ${({ theme }) => theme.logoPrimary};
  box-shadow: ${({ theme }) => `0 1px 3px 0 ${theme.shadowPrimary}`};
  display: flex;
`;

export const VeygoLogoContainer = Styled.div`
  min-width: 360px;
  margin-left: 24px;
  flex: 1;
  display: flex;
  align-items: center;
  ${MediaQuery.tablet(css`
    min-width: 98px;
  `)}
`;

export const VeygoLogoLink = Styled.a.attrs(() => ({
  href: process.env.REACT_APP_VEYGO_CMS,
  rel: 'noopener noreferrer',
  target: '_blank',
}))`
  cursor: pointer;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
  }

`;

export const VeygoLogo = Styled.img.attrs(() => ({
  src: VeygoLogoIcon,
  alt: 'veygo-icon',
}))`
  width: 136px;
`;

export const GetHelpContainer = Styled.div`
  min-width: 240px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-right: 16px;
  ${MediaQuery.tablet(css`
    display: none;
  `)}
`;

export const GetHelpMobileContainer = Styled.div`
  min-width: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-right: 16px;
  ${MediaQuery.desktop(css`
    display: none;
  `)}
`;

export const GetHelpMobileButtonWrapper = Styled.a.attrs(() => ({
  href: 'https://www.veygo.com/help',
  rel: 'noopener noreferrer',
  target: '_blank',
}))`
  height: 33px;
  width: 33px;
  border-radius: 23px;
  background-color: ${({ theme }) => theme.logoPrimary};
  box-shadow: ${({ theme }) => `0 2px 4px 0 ${theme.shadowSecondary}`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GetHelpWrapper = Styled.div`
  height: 33px;
  width: 240px;
`;

export const GetHelpIconWrapper = Styled.div`
  flex: 0.4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8px;
`;

export const GetHelpTextWrapper = Styled.div`
  flex: 0.6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const GetHelpIcon = Styled.img.attrs<HelpIconInterface>(({ hoverActive }) => ({
  src: hoverActive ? HelpIconDark : HelpIcon,
  alt: 'help-icon',
}))<HelpIconInterface>`;
  fill: red;
`;

export const MyAccountContainer = Styled.div`
  min-width: 58px;
  display: flex;
  align-items: center;
`;

export const MyAccountIconWrapper = Styled.a`
  height: 32px;
  width: 32px;
  border-radius: 23px;
  background: ${({ theme }) => theme.backgroundPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MyAccountIcon = Styled.img.attrs(() => ({
  src: AccountIcon,
  alt: 'account-icon',
}))`
  height: 16px;
  width: 16px;
`;

export const GetHelpPillButtonContainer = Styled.div`
  display: flex;
  flex: 1

  ${MediaQuery.tablet(css`
    height: 33px;
    width: 33px;
    background: ${({ theme }) => theme.logoPrimary};
  `)}
`;
