import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components';

import { store } from 'state';
import { Theme } from 'utils/theme';
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk';
import { SiteContent } from './SiteContent';

const { REACT_APP_OPTIMIZELY_KEY } = process.env;

const optimizelyClient = createInstance({
  sdkKey: REACT_APP_OPTIMIZELY_KEY,
});

const Root: React.FC<unknown> = () => {
  return (
    <Sentry.ErrorBoundary fallback={<p>'An error has occurred'</p>}>
      <Provider store={store}>
        <OptimizelyProvider optimizely={optimizelyClient} user={{ id: 'account_user' }}>
          <ThemeProvider theme={Theme}>
            <Router>
              <SiteContent />
            </Router>
          </ThemeProvider>
        </OptimizelyProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

export default Root;
