import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useLocation } from 'react-router-dom';
import { AppState } from 'state';

import { actionCreators } from 'state/purchases';
import {
  ActivePolicies,
  UpcomingPolicies,
  EndedPolicies,
  ActiveSubscriptions,
  UpcomingSubscriptions,
  EndedSubscriptions,
} from 'state/purchases/types';

import { CancelType } from 'types/Subscription';

import { Policy, PolicyStatus, Subscription, VeygoError } from '@rentecarlo/component-library';
import DetailedPurchase from './DetailedPurchase';
import { usePolicyDocuments } from './usePolicyDocuments';

interface Props {
  subscriptions: ActiveSubscriptions & UpcomingSubscriptions & EndedSubscriptions;
  policies: ActivePolicies & UpcomingPolicies & EndedPolicies;
  loading: boolean;
  lastUpdate?: Date;
  purchasesError: VeygoError | null;
  policyCancelSucceeded?: boolean;
}

interface DetailedPurchaseContainerProps extends Props {
  retrieveUserPolicies: () => void;
  retrieveSubscriptions: () => void;
  cancelPolicy: (policy: Policy, reason: string, comment: string) => void;
  cancelSubscription: (
    subscription: Subscription,
    reason: string,
    cancelType: CancelType,
    comment: string,
  ) => void;
  getRefundAmountRequest: (uuid: string) => void;
  getSubsRefundAmountRequest: (policy_uuid: string, subs_uuid: string) => void;
  clearPurchasesError: () => void;
  clearCancelSuccessState: () => void;
}

interface LocationState {
  status: string;
  index: number;
  uuid: string;
  isSubscription: boolean;
}

const getPolicy = (
  policies: ActivePolicies & UpcomingPolicies & EndedPolicies,
  status: string,
  uuid: string,
): Policy | undefined => {
  let policy;

  policies[status].forEach((loopedPolicy) => {
    if (loopedPolicy.uuid === uuid) {
      policy = loopedPolicy;
    }
  });

  return policy;
};

const getSubscription = (
  subscriptions: ActiveSubscriptions & UpcomingSubscriptions & UpcomingSubscriptions,
  status: string,
  uuid: string,
): Subscription | undefined => {
  let subscription;

  subscriptions[status].forEach((loopedSubscription) => {
    if (loopedSubscription.uuid === uuid) {
      subscription = loopedSubscription;
    }
  });

  return subscription;
};

const searchPolicy = (
  policies: ActivePolicies & UpcomingPolicies & EndedPolicies,
  uuid: string,
) => {
  let searchedPolicy;
  policies.active.forEach((policy) => {
    if (policy.uuid === uuid) {
      searchedPolicy = policy;
    }
  });

  policies.upcoming.forEach((policy) => {
    if (policy.uuid === uuid) {
      searchedPolicy = policy;
    }
  });

  policies.ended.forEach((policy) => {
    if (policy.uuid === uuid) {
      searchedPolicy = policy;
    }
  });

  return searchedPolicy;
};

const searchSubscription = (
  subscriptions: ActiveSubscriptions & UpcomingSubscriptions & EndedSubscriptions,
  uuid: string,
) => {
  let searchedSubscription;
  subscriptions.active.forEach((subscription) => {
    if (subscription.uuid === uuid) {
      searchedSubscription = subscription;
    }
  });

  subscriptions.ended.forEach((subscription) => {
    if (subscription.uuid === uuid) {
      searchedSubscription = subscription;
    }
  });

  return searchedSubscription;
};

const getEmptyPolicy = (): Policy => ({
  uuid: '',
  excess: 0,
  price: 0,
  cancelled: false,
  referenceNumber: '',
  carEngineSize: 0,
  carMake: '',
  carModel: '',
  carRegistrationNumber: '',
  durationStart: '',
  durationEnd: '',
  cancellationDateTime: '',
  productName: '',
  driverFirstName: '',
  driverLastName: '',
});

const getEmptySubscription = (): Subscription => ({
  uuid: '',
  referenceNumber: '',
  activePolicy: getEmptyPolicy(),
  upcomingPolicy: getEmptyPolicy(),
  previousPolicies: [getEmptyPolicy()],
  startDate: '',
  nextPaymentDate: '',
  status: PolicyStatus.ACTIVE,
  cancelled: false,
  productName: '',
});

const getActivePolicyFromSubscription = (subscription: Subscription) => {
  return subscription.activePolicy;
};

const getUpcomingPolicyFromSubscription = (subscription: Subscription) => {
  return subscription.upcomingPolicy;
};

const DetailedPurchaseContainer: React.FC<DetailedPurchaseContainerProps> = (props) => {
  const {
    retrieveSubscriptions,
    retrieveUserPolicies,
    subscriptions,
    policies,
    cancelPolicy,
    cancelSubscription,
    lastUpdate,
    getRefundAmountRequest,
    loading,
    purchasesError,
    clearPurchasesError,
    clearCancelSuccessState,
    policyCancelSucceeded,
    getSubsRefundAmountRequest,
  } = props;

  const location = useLocation<LocationState>();

  const subscription =
    getSubscription(subscriptions, location.state.status, location.state.uuid) ||
    searchSubscription(subscriptions, location.state.uuid) ||
    getEmptySubscription();

  const policy =
    getPolicy(policies, location.state.status, location.state.uuid) ||
    searchPolicy(policies, location.state.uuid) ||
    getActivePolicyFromSubscription(subscription) ||
    getUpcomingPolicyFromSubscription(subscription) ||
    getEmptyPolicy();

  const documentsUrls = usePolicyDocuments(
    location.state.isSubscription
      ? subscription.activePolicy || subscription.upcomingPolicy
      : policy,
    lastUpdate,
  );

  useEffect(() => {
    if (location.state.isSubscription) {
      retrieveSubscriptions();
    } else {
      retrieveUserPolicies();
    }
  }, [retrieveUserPolicies, retrieveSubscriptions, location.state.isSubscription]);

  return (
    <DetailedPurchase
      isSubscription={location.state.isSubscription}
      subscription={subscription}
      policy={policy}
      cancelPolicy={(reason: string, comment: string) => cancelPolicy(policy, reason, comment)}
      cancelSubscription={(reason: string, cancelType: CancelType, comment: string) =>
        cancelSubscription(subscription, reason, cancelType, comment)
      }
      files={documentsUrls}
      getRefundAmount={getRefundAmountRequest}
      loading={loading}
      purchasesError={purchasesError}
      retrieveUserPolicies={retrieveUserPolicies}
      clearPurchasesError={clearPurchasesError}
      clearCancelSuccessState={clearCancelSuccessState}
      policyCancelSucceeded={policyCancelSucceeded}
      getSubsRefundAmount={getSubsRefundAmountRequest}
      retrieveSubscriptions={retrieveSubscriptions}
    />
  );
};

const mapStateToProps = (state: AppState): Props => ({
  loading: state.purchases.loading,
  policies: state.purchases.policies,
  subscriptions: state.purchases.subscriptions,
  lastUpdate: state.purchases.lastUpdate,
  purchasesError: state.purchases.error,
  policyCancelSucceeded: state.purchases.policyCancelSucceeded,
});

export default connect(mapStateToProps, (dispatch) => bindActionCreators(actionCreators, dispatch))(
  React.memo(DetailedPurchaseContainer),
);
