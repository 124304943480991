/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState } from 'react';
import { Moment } from 'moment';
import styled, { css } from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';

import size from '../../../config/size';
import DateTimeInput from '../../atoms/DateTimeInput/DateTimeInput';
import Modal from '../../atoms/Modal/Modal';
import AccountCalendar from '../../molecules/AccountCalendar/AccountCalendar';
import MobileModalHeader from '../../molecules/ModalModalHeader/MobileModalHeader';
import ModalTimeBar from '../../molecules/ModalTimeBar/ModalTimeBar';

const Container = styled.div`
  position: relative;
`;

interface ModalProps {
  desktopMinWidth: string;
}

const MobileModalHeaderContainer = styled.div<ModalProps>`
  ${(props) => css`
    @media (min-width: ${props.desktopMinWidth}) {
      display: none;
    }
  `}
`;

const ModalOuter = styled.div<ModalProps>`
  position: absolute;
  top: 66px;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.white};
  z-index: 2;
  ${(props) => css`
    @media (min-width: ${props.desktopMinWidth}) {
      margintop: 6px;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    }
  `}
`;

const ModalInner = styled.div<ModalProps>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.white};
  ${(props) => css`
    @media (min-width: ${props.desktopMinWidth}) {
      backgroundcolor: initial;
    }
  `}
`;

const QuoteCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
`;

interface ComponentProps {
  startDateTime: Moment | null;
  endDateTime: Moment | null;
  dateId: string;
  timeId: string;
  calenderIconId: string;
  onChange: (value: string) => void;
}

const formatTime = (hours: number, minutes: number) => {
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  return minutes === 0
    ? `${formattedHours} : 00`
    : `${formattedHours} : ${minutes.toString().padStart(2, '0')}`;
};

const QuoteDateTimeInput: React.FC<ComponentProps> = ({
  startDateTime = null,
  endDateTime = null,
  dateId,
  timeId,
  calenderIconId,
  onChange,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [selectedTime, setSelectTime] = useState<Moment | null>(null);

  const { desktopWidth: desktopMinWidth, desktopWidthInt } = size;
  const curDateTime = startDateTime;

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleSelectedDateChange = (dateTime: Moment | null) => {
    let dateTimeCloned = dateTime;
    if (selectedTime) {
      const [hours, minutes] = selectedTime.split(':').map(Number);
      dateTimeCloned = dateTime.set({ hour: hours, minute: minutes });
    }
    onChange(dateTimeCloned.format('Y-MM-DD HH:mm'));
    setSelectedDate(dateTimeCloned);
  };

  const handleSelectTimeChange = (hours: number, minutes: number) => {
    const newTime = formatTime(hours, minutes);
    setSelectTime(newTime);

    if (selectedDate) {
      const dateTimeCloned = selectedDate.hours(hours).minutes(minutes);
      onChange(dateTimeCloned.format('Y-MM-DD HH:mm'));
    }
  };

  return (
    <Container>
      <DateTimeInput
        date={selectedDate}
        time={selectedDate ? selectedTime : null}
        onCalendarClick={() => toggleModal()}
        label='from'
        dateId={dateId}
        timeId={timeId}
        calenderIconId={calenderIconId}
      />
      <ModalOuter desktopMinWidth={desktopMinWidth}>
        {modalOpen && (
          <Modal desktopMinWidth={desktopMinWidth} fullscreenOnDesktop={false}>
            <ModalInner desktopMinWidth={desktopMinWidth}>
              <MobileModalHeaderContainer desktopMinWidth={desktopMinWidth}>
                <MobileModalHeader onCancel={() => toggleModal()} onDone={() => toggleModal()} />
              </MobileModalHeaderContainer>

              <OutsideClickHandler
                onOutsideClick={() => {
                  if (window.innerWidth >= desktopWidthInt) {
                    toggleModal();
                  }
                }}
              >
                <ModalTimeBar
                  id='from'
                  onDone={() => toggleModal()}
                  onChange={handleSelectTimeChange}
                  label='Time:'
                  hours={curDateTime === null ? undefined : curDateTime.hours()}
                  minutes={curDateTime === null ? undefined : curDateTime.minutes()}
                />
                <QuoteCalendarContainer>
                  <AccountCalendar
                    startDate={startDateTime}
                    endDate={endDateTime}
                    toOrFrom='from'
                    onChange={(datetime) => handleSelectedDateChange(datetime)}
                  />
                </QuoteCalendarContainer>
              </OutsideClickHandler>
            </ModalInner>
          </Modal>
        )}
      </ModalOuter>
    </Container>
  );
};

export default QuoteDateTimeInput;
