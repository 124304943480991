import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import size from '../../../config/size';

interface ContainerProps {
  display?: string;
  fullscreenOnDesktop: boolean;
  desktopMinWidth: string;
}

const Container = styled.div<ContainerProps>`
  display: ${(props) => props.display};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  ${(props) =>
    !props.fullscreenOnDesktop &&
    css`
      @media (min-width: ${props.desktopMinWidth}) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        &:after {
          background-color: ${({ theme }): string => theme.backgroundSecondary};
          position: absolute;
          right: -80px;
          width: 80px;
          content: '';
          top: 0;
          height: 100%;
        }
        &:before {
          background-color: ${({ theme }): string => theme.backgroundSecondary};
          position: absolute;
          left: -2px;
          width: 2px;
          content: '';
          top: 0;
          height: 100%;
        }
      }
    `}
`;

interface ModalProps extends ContainerProps {
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  desktopMinWidth,
  fullscreenOnDesktop,
  display,
  children,
}) => (
  <Container
    desktopMinWidth={desktopMinWidth}
    fullscreenOnDesktop={fullscreenOnDesktop}
    display={display}
  >
    {children}
  </Container>
);

Modal.defaultProps = {
  desktopMinWidth: size.desktopWidth,
  fullscreenOnDesktop: true,
  display: '',
};

export default Modal;
