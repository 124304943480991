import { SET_OPTIMIZELY_ATTRIBUTE, ConfigState, ConfigActionTypes } from './types';

export const initialState: ConfigState = {
  optimizelyAttributes: {}
};

const reducer = (
  state: ConfigState = initialState,
  action: ConfigActionTypes,
): ConfigState => {
  switch (action.type) {
    case SET_OPTIMIZELY_ATTRIBUTE:
      return {
        ...state,
        optimizelyAttributes: {
          ...state.optimizelyAttributes,
          ...action.data
        }
      };
    default:
      return state;
  }
};

export default reducer;
