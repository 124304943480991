import React, { useEffect, ReactElement } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from 'state';
import { actionCreators } from 'state/purchases';
import {
  ActivePolicies,
  UpcomingPolicies,
  EndedPolicies,
  ActiveSubscriptions,
  UpcomingSubscriptions,
  EndedSubscriptions,
} from 'state/purchases/types';

import Purchases from './Purchases';

interface Props {
  policies: ActivePolicies & UpcomingPolicies & EndedPolicies;
  subscriptions: ActiveSubscriptions & UpcomingSubscriptions & EndedSubscriptions;
  loading: boolean;
}

interface PurchasesContainerProps extends Props {
  retrieveUserPolicies: () => void;
  retrieveSubscriptions: () => void;
}

const PurchasesContainer = (props: PurchasesContainerProps): ReactElement => {
  const { retrieveUserPolicies, retrieveSubscriptions, policies, subscriptions } = props;

  useEffect(() => {
    retrieveUserPolicies();
    retrieveSubscriptions();
  }, [retrieveUserPolicies, retrieveSubscriptions]);

  return <Purchases policies={policies} subscriptions={subscriptions} />;
};

const mapStateToProps = (state: AppState): Props => ({
  loading: state.purchases.loading,
  policies: state.purchases.policies,
  subscriptions: state.purchases.subscriptions,
});

export default connect(mapStateToProps, (dispatch) => bindActionCreators(actionCreators, dispatch))(
  React.memo(PurchasesContainer),
);
