import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { actionCreators } from 'state/notifications';
import { AppState } from 'state';
import { NotificationActions, NotificationState } from 'state/notifications/types';
import { NotificationToggle } from './NotificationToggle';

interface StateProps {
  isChecked: boolean;
}

interface DispatchProps {
  onToggle: (toggleName: string) => void;
}

interface NotificationToggleContainerInterface extends StateProps, DispatchProps {
  toggleName: string;
}

interface ContainerProps {
  toggleName: string;
}

const selectValue = (notifications: NotificationState, toggleName: string): boolean => {
  switch (toggleName) {
    case 'emailConsent':
      return notifications.emailConsent;
    case 'phoneConsent':
      return notifications.phoneConsent;
    default:
      return false;
  }
};

const NotificationToggleContainer: React.FC<NotificationToggleContainerInterface> = (props) => {
  const { toggleName, onToggle, isChecked } = props;
  return (
    <NotificationToggle
      id={`${toggleName}Toggle`}
      toggleNotification={(): void => onToggle(toggleName)}
      isChecked={isChecked}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch<NotificationActions>): DispatchProps => ({
  onToggle: (toggleName: string): void => dispatch(actionCreators.toggleNotification(toggleName)),
});

const mapStateToProps = (state: AppState, ownProps: ContainerProps): StateProps => ({
  isChecked: selectValue(state.notifications.present, ownProps.toggleName),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationToggleContainer);
