import styled, { css } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > h1 {
    text-align: left;
    margin-bottom: 40px;
  }
`;

export const InfoContainer = styled.div`
  margin-bottom: 40px;
`;

export const SubheadingContainer = styled.div`
  margin-bottom: 8px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${MediaQuery.mobile(css`
    flex-direction: column;
    height: 144px;
  `)};
`;

export const ButtonContainer = styled.div`
  width: 192px;
  ${MediaQuery.mobile(css`
    width: 100%;
  `)};
`;
