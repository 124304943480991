import { connect } from 'react-redux';
import { AppState } from 'state';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { actionCreators } from 'state/account';
import NavMenu from './NavMenu';

interface StateProps {
  close: boolean;
  loggedIn: boolean;
}

interface DispatchProps {
  toggleMenu: () => void;
}

const mapStateToProps = (state: AppState): StateProps => ({
  close: state.account.showMenu,
  loggedIn: state.account.loggedIn,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  toggleMenu: () => dispatch(actionCreators.toggleMenu()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)(withRouter<any, any>(NavMenu));
