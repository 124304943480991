import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import moment from 'moment';

import {
  PageModal,
  H1,
  H5,
  P,
  ToggleButtonGroup,
  RoundedGreyBox,
  Button,
  Subscription,
  CheckboxInput,
  PolicyStatus,
} from '@rentecarlo/component-library';

import { CheckboxListField } from 'components/molecules';
import { isChecked } from 'utils/validation';

import { CancelType } from 'types/Subscription';
import {
  getCancellationReasons,
  createReasonFromMainAndSecondaryReasons,
} from 'services/cancellationReasons';

import { useOptimizelyFlag } from 'utils/hooks';

import { buildCancellationReasons, isSecondaryCancellationReasonValid } from 'components/utils';

import {
  Container,
  ButtonsContainer,
  ButtonContainer,
  InfoContainer,
  SubheadingContainer,
  DescriptionText,
  Spacer,
  Content,
} from './assets/styles';

interface Props {
  close: () => void;
  show: boolean;
  subscription: Subscription;
  cancel: (reason: string, cancelType: CancelType, customerComment: string) => void;
  learner: boolean;
}

interface InputProps {
  cancellationReason: string;
}

const initialValues: InputProps = {
  cancellationReason: '',
};

const CancelSubsModal: React.FC<Props> = ({ show, close, subscription, cancel, learner }) => {
  const [cancelType, setCancelType] = useState<CancelType | null>(null);
  const [cancelImmediatlyConfirmed, setcancelImmediatlyConfirmed] = useState(false);
  const [customerComment, setCustomerComment] = useState('');
  const [secondaryCancellationReason, setSecondaryCancellationReason] = useState('');
  const [secondaryCancellationReasonErrorText, setSecondaryCancellationReasonErrorText] = useState(
    '',
  );

  const formikRef = useRef<FormikProps<InputProps> | null>(null);

  const showSecondaryCancellationReasons =
    useOptimizelyFlag('TEMP_SECONDARY_CANCELLATION_REASONS').enabled &&
    !subscription.productName.includes('New Driver');

  useEffect(() => {
    if (subscription.status === PolicyStatus.UPCOMING) {
      setCancelType(CancelType.IMMEDIATE);
      setcancelImmediatlyConfirmed(true);
    }
  }, [subscription]);

  const getImmediateCancelDescriptionText = () => {
    if (subscription.status === PolicyStatus.UPCOMING && subscription.upcomingPolicy) {
      return (
        <li>
          As your policy has not started yet, you'll receive a full refund of{' '}
          <b>£{subscription.refundAmount}</b>, within the next <b>3-10 days</b> of cancelling
        </li>
      );
    }
    const immediateCancelText = (
      <li>
        Your cover will end <b>immediately</b>, and you will no longer be insured
      </li>
    );
    if (subscription.upcomingPolicy) {
      return (
        <>
          {immediateCancelText}
          <li>We will issue a partial refund for the remainder of the current cycle</li>
          <li>
            As we have already taken a payment for the next cycle of{' '}
            <b>£{subscription.upcomingPolicy.price}</b>, this will be refunded to you in full
          </li>
          <li>
            Your refunds will be issued to you as two separate payments. They will arrive within the
            next <b>3-10 days</b>
          </li>
          <li>
            We will email exact refund amounts to you after you tap 'cancel subscription' below
          </li>
        </>
      );
    }
    return (
      <>
        {immediateCancelText}
        <li>
          We will issue a partial refund for the remainder of the current cycle. This will arrive
          within the next <b>3-10 days</b>
        </li>
        <li>
          We will email the exact refund amount to you after you tap 'cancel subscription' below
        </li>
      </>
    );
  };

  const changeCancellationType = (cancellationType: CancelType) => {
    setCancelType(cancellationType);
    if (formikRef.current?.values.cancellationReason) {
      formikRef.current.resetForm();
    }
  };

  return (
    <PageModal
      show={show}
      close={() => {
        close();
      }}
      closeText='Back'
    >
      <Container>
        <H1 fontWeight='800'>Cancel subscription</H1>
        {subscription.status !== PolicyStatus.UPCOMING && (
          <>
            <SubheadingContainer>When do you want your cover to end?</SubheadingContainer>
            <InfoContainer>
              You can either let your cover roll until the end of your current cycle, which is{' '}
              <b>{moment(subscription.activePolicy?.durationEnd).format('DD/MM/YYYY')}</b>. Or you
              can end it immediately.
            </InfoContainer>
            <ToggleButtonGroup
              value={cancelType || ''}
              groupName='cancelType'
              onSelect={(type: string) => changeCancellationType(type as CancelType)}
              items={{
                left: {
                  text: 'End of current cycle',
                  value: CancelType.LAPSE,
                },
                right: {
                  text: 'Immediately',
                  value: CancelType.IMMEDIATE,
                },
              }}
            />
          </>
        )}
      </Container>
      {cancelType && (
        <Content>
          {cancelType === CancelType.IMMEDIATE && subscription.status !== PolicyStatus.UPCOMING && (
            <>
              <SubheadingContainer>Are you sure?</SubheadingContainer>
              <P>Your cover will end immediately and you will no longer be insured.</P>
              <Spacer height={16} />
              <CheckboxInput
                id='confirmImmediate'
                checked={cancelImmediatlyConfirmed}
                onPress={() => setcancelImmediatlyConfirmed(!cancelImmediatlyConfirmed)}
              >
                Yes, I want to cancel immediately
              </CheckboxInput>
            </>
          )}
          {(cancelType === CancelType.LAPSE || cancelImmediatlyConfirmed) && (
            <>
              <RoundedGreyBox padding={[8, 16, 8, 8]} margin={[32, 0, 32, 0]}>
                <DescriptionText>
                  <ul>
                    {cancelType === CancelType.LAPSE && (
                      <>
                        <li>
                          Your cover will end on{' '}
                          <b>
                            {moment(subscription.activePolicy?.durationEnd).format('DD/MM/YYYY')}
                          </b>{' '}
                          at {moment(subscription.activePolicy?.durationEnd).format('HH:mm')}.
                        </li>
                        {subscription.upcomingPolicy && (
                          <li>
                            As we have already taken a payment of{' '}
                            <b>£{subscription.upcomingPolicy.price}</b> for the next cycle, this
                            will be refunded to you in full within the next <b>3-10 days</b>
                          </li>
                        )}
                      </>
                    )}
                    {cancelType === CancelType.IMMEDIATE && getImmediateCancelDescriptionText()}
                  </ul>
                </DescriptionText>
              </RoundedGreyBox>
              <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={() => {}}
                validateOnChange
              >
                {({ values, setFieldValue, validateForm, touched }): ReactElement => {
                  return (
                    <Form>
                      <Container>
                        <SubheadingContainer>
                          <H5>Please let us know your reason for cancelling</H5>
                        </SubheadingContainer>
                        <Field
                          component={CheckboxListField}
                          name='cancel-reason'
                          options={
                            showSecondaryCancellationReasons
                              ? buildCancellationReasons(
                                  {
                                    comment: customerComment,
                                    setComment: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                      setCustomerComment(event.currentTarget.value);
                                    },
                                  },
                                  {
                                    reason: secondaryCancellationReason,
                                    setReason: (option: string) => {
                                      setSecondaryCancellationReason(option);
                                      setSecondaryCancellationReasonErrorText('');
                                    },
                                    errorText: secondaryCancellationReasonErrorText,
                                  },
                                  learner,
                                  true,
                                  cancelType,
                                  true,
                                )
                              : getCancellationReasons(
                                  learner && cancelType === CancelType.IMMEDIATE,
                                  true,
                                  false,
                                )
                          }
                          value={values.cancellationReason}
                          onChange={(selectedOption: string): void => {
                            Promise.resolve(
                              setFieldValue('cancellationReason', selectedOption, true),
                            ).then(() => validateForm());
                            setCustomerComment('');
                            setSecondaryCancellationReason('');
                            setSecondaryCancellationReasonErrorText('');
                          }}
                          validate={() => isChecked(values.cancellationReason !== '')}
                          touched={touched}
                        />
                        <ButtonsContainer>
                          <ButtonContainer>
                            <Button
                              id='no-cancel-policy'
                              borderWidth={2}
                              onClick={() => {
                                close();
                              }}
                              height={64}
                              buttonType='outline'
                              borderRadius={8}
                              type='button'
                            >
                              Don't cancel
                            </Button>
                          </ButtonContainer>
                          <ButtonContainer>
                            <Button
                              id='cancel-policy'
                              borderWidth={0.5}
                              height={64}
                              borderRadius={8}
                              type='submit'
                              onClick={(event: MouseEvent) => {
                                event.preventDefault();
                                if (
                                  showSecondaryCancellationReasons &&
                                  !isSecondaryCancellationReasonValid(
                                    values.cancellationReason,
                                    secondaryCancellationReason,
                                    customerComment,
                                  )
                                ) {
                                  setSecondaryCancellationReasonErrorText('Required');
                                  return;
                                }
                                validateForm().then((result) => {
                                  if (Object.keys(result).length === 0 && cancelType) {
                                    cancel(
                                      createReasonFromMainAndSecondaryReasons(
                                        values.cancellationReason,
                                        secondaryCancellationReason,
                                      ),
                                      cancelType,
                                      customerComment,
                                    );
                                  }
                                });
                              }}
                            >
                              Cancel subscription
                            </Button>
                          </ButtonContainer>
                        </ButtonsContainer>
                      </Container>
                    </Form>
                  );
                }}
              </Formik>
            </>
          )}
        </Content>
      )}
    </PageModal>
  );
};

export default CancelSubsModal;
