import React from 'react';
import {
  SidebarIcon,
  SidebarIconSpacer,
  SidebarIconWrapper,
  SidebarItemContainer,
  SidebarItemLabel,
  SidebarLabelWrapper,
} from './assets/styles';

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

interface SidebarItemInterface {
  active?: boolean;
  label: string;
  icon?: string;
  onClick: () => void;
  status: Status;
  id: string;
}

const SidebarItem: React.FC<SidebarItemInterface> = ({
  active = false,
  label,
  icon,
  onClick,
  status = Status.ACTIVE,
  id,
}) => {
  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  const onClickHandler = status === Status.ACTIVE ? onClick : (): void => {};
  return (
    <SidebarItemContainer status={status} active={active} onClick={onClickHandler} id={id}>
      <SidebarIconWrapper>
        <SidebarIconSpacer>
          <SidebarIcon src={icon} />
        </SidebarIconSpacer>
      </SidebarIconWrapper>
      <SidebarLabelWrapper>
        <SidebarItemLabel active={active}>{label}</SidebarItemLabel>
      </SidebarLabelWrapper>
    </SidebarItemContainer>
  );
};

export default SidebarItem;
