import { FieldProps } from 'formik';
import React from 'react';

import { Input } from '@rentecarlo/component-library';

interface InputFieldInterface extends FieldProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  name: string;
  submitError?: boolean;
  pasteDisabled?: boolean;
}

type ErrorText = string | undefined;

// Formik Field Input handler
const InputField: React.FC<InputFieldInterface> = ({
  field,
  form,
  placeholder,
  submitError,
  ...others
}) => {
  //  Cast FieldError from FormikError<any> to a handled
  //  String | Undefined so our generic input is agnostic to Formik.
  const errorText = form.errors[field.name] as ErrorText;
  const touched = form.touched[field.name];

  return (
    <Input
      placeholder={placeholder}
      errorText={errorText}
      touched={!!touched}
      containerError={!!(submitError && errorText)}
      {...field}
      {...others}
    />
  );
};

export default InputField;
