type TBuildRequestUrl = (type: string) => string;

export enum RequestTypes {
  CSI = 'CSI',
  RENTALS = 'RENTALS',
  AUTH = 'AUTH',
  CUSTOMERS_API = 'CUSTOMERS_API',
  CLAIMS_API = 'CLAIMS_API',
}

const buildUrl = (protocol: string, domain: string, port?: string): string => {
  if (port) {
    return `${protocol}://${domain}:${port}`;
  }
  return `${protocol}://${domain}`;
};

const buildRentalsUrl = (): string => {
  const {
    REACT_APP_RENTALS_PROTOCOL,
    REACT_APP_RENTALS_DOMAIN,
    REACT_APP_RENTALS_PORT,
  } = process.env;

  return buildUrl(REACT_APP_RENTALS_PROTOCOL, REACT_APP_RENTALS_DOMAIN, REACT_APP_RENTALS_PORT);
};

const buildCustomersApiUrl = (): string => {
  const {
    REACT_APP_CUSTOMERS_API_PROTOCOL,
    REACT_APP_CUSTOMERS_API_DOMAIN,
    REACT_APP_CUSTOMERS_API_PORT,
  } = process.env;

  return buildUrl(
    REACT_APP_CUSTOMERS_API_PROTOCOL,
    REACT_APP_CUSTOMERS_API_DOMAIN,
    REACT_APP_CUSTOMERS_API_PORT,
  );
};

const buildCSIUrl: () => string = () => {
  const { REACT_APP_CSI_PROTOCOL, REACT_APP_CSI_DOMAIN, REACT_APP_CSI_PORT } = process.env;

  return buildUrl(REACT_APP_CSI_PROTOCOL, REACT_APP_CSI_DOMAIN, REACT_APP_CSI_PORT);
};

const buildAuthUrl: () => string = () => {
  const { REACT_APP_AUTH_PROTOCOL, REACT_APP_AUTH_DOMAIN, REACT_APP_AUTH_PORT } = process.env;

  return buildUrl(REACT_APP_AUTH_PROTOCOL, REACT_APP_AUTH_DOMAIN, REACT_APP_AUTH_PORT);
};

export const buildClaimsUrl = (): string => {
  const { REACT_APP_CLAIMS_PROTOCOL, REACT_APP_CLAIMS_DOMAIN } = process.env;
  return buildUrl(REACT_APP_CLAIMS_PROTOCOL, REACT_APP_CLAIMS_DOMAIN);
};

export const buildRequestUrl: TBuildRequestUrl = (type: string) => {
  switch (type) {
    case RequestTypes.CSI:
      return buildCSIUrl();
    case RequestTypes.RENTALS:
      return buildRentalsUrl();
    case RequestTypes.AUTH:
      return buildAuthUrl();
    case RequestTypes.CUSTOMERS_API:
      return buildCustomersApiUrl();
    case RequestTypes.CLAIMS_API:
      return buildClaimsUrl();
    default:
      return buildCSIUrl();
  }
};

export function createCustomerRequestUrl(endpoint: string): string {
  return `${buildRequestUrl(RequestTypes.CUSTOMERS_API)}/customers/${endpoint}`;
}

export function createCSIRequestUrl(endpoint: string): string {
  return `${buildRequestUrl(RequestTypes.CSI)}/${endpoint}`;
}
