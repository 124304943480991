import Styled, { css } from 'styled-components';
import { Fonts } from 'utils/config';
import { MediaQuery } from '@rentecarlo/component-library';

type ContainerProps = {
  bottomBorder?: boolean;
  marginTop?: string;
};

export const Container = Styled.div<ContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 8px;
  border-bottom: ${({ bottomBorder, theme }): string =>
    bottomBorder ? `0.5px solid ${theme.fieldSecondary}` : ''};
  padding-bottom: 16px;
  margin-top: ${({ marginTop }): string => marginTop || ''};
`;

export const Title = Styled.h5`
  margin-left: 8px;
  margin-top: 24px;
  margin-bottom: 8px;
  height: 24px;
  color: ${({ theme }): string => theme.textSecondary};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 24px;
  font-family: ${Fonts.title};
`;

export const ElementsContainer = Styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  ${MediaQuery.mobile(css`
    margin-bottom: 16px;
  `)}
`;
