import React, { ReactNode } from 'react';
import { Title } from './assets/styles';

interface Props {
  children: ReactNode;
}

const Subtitle: React.FC<Props> = (props: Props) => {
  const { children } = props;
  return <Title>{children}</Title>;
};

export default Subtitle;
