// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';
import { CustomerComment } from 'components/atoms';
import {
  CancelReason,
  CancellationReasonType,
  getCancellationReasons,
  getSecondaryCancellationReasons,
  getSecondaryCancellationQuestion,
} from 'services/cancellationReasons';
import { DropDown } from '@rentecarlo/component-library';
import { CancelType } from 'types/Subscription';

type CustomerCancellationComment = {
  comment: string;
  setComment: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

type SecondaryCancellationReason = {
  reason: string;
  setReason: (reason: string) => void;
  errorText: string;
};

export const buildCancellationReasons = (
  customerComment: CustomerCancellationComment,
  secondaryCancellationReason: SecondaryCancellationReason,
  isLearner: boolean,
  isSubscription: boolean,
  subscriptionCancelType?: CancelType,
  showSecondaryCancellationReasons = false,
) => {
  let cancellationReasons;

  if (isSubscription) {
    cancellationReasons = getCancellationReasons(
      isLearner && subscriptionCancelType === CancelType.IMMEDIATE,
      true,
      showSecondaryCancellationReasons,
    );
  } else {
    cancellationReasons = getCancellationReasons(isLearner, false, false);
  }

  cancellationReasons = cancellationReasons.map((reason: CancelReason) => {
    if (reason.reasonId === CancellationReasonType.MISSING_FEATURE) {
      return {
        child: (
          <CustomerComment
            id='missing-feature-comment'
            name='missing-feature-comment'
            placeholder='Please help us by describing the feature you need (optional)'
            value={customerComment.comment}
            onChange={customerComment.setComment}
          />
        ),
        ...reason,
      };
    }

    if (
      reason.reasonId !== CancellationReasonType.ADDED_TO_MAIN_INSURANCE &&
      reason.reasonId !== CancellationReasonType.PASSED_TEST
    ) {
      let secondaryCancellationReasons = getSecondaryCancellationReasons(reason.reasonId);

      secondaryCancellationReasons = secondaryCancellationReasons.map(
        (secondaryReason: CancelReason) => {
          if (
            secondaryReason.reasonId === CancellationReasonType.DOES_NOT_MEET_REQUIREMENTS_OTHER
          ) {
            return {
              child: (
                <CustomerComment
                  id='no-access-comment'
                  name='no-access-comment'
                  placeholder='Please help us by describing the requirements you have'
                  value={customerComment.comment}
                  onChange={customerComment.setComment}
                />
              ),
              ...secondaryReason,
            };
          }
          if (secondaryReason.reasonId === CancellationReasonType.MAKE_A_CHANGE_OTHER) {
            return {
              child: (
                <CustomerComment
                  id='make-change-comment'
                  name='make-change-comment'
                  placeholder='Please help us by describing the change you need to make'
                  value={customerComment.comment}
                  onChange={customerComment.setComment}
                />
              ),
              ...secondaryReason,
            };
          }
          return secondaryReason;
        },
      );

      return {
        child: (
          <DropDown
            label={getSecondaryCancellationQuestion(reason.reasonId)}
            placeholder='Select a reason'
            options={secondaryCancellationReasons}
            value={secondaryCancellationReason.reason}
            id='secondary-cancel-reason-dropdown'
            onClick={secondaryCancellationReason.setReason}
            errorText={secondaryCancellationReason.errorText}
            containerError={secondaryCancellationReason.errorText !== ''}
          />
        ),
        ...reason,
      };
    }

    return reason;
  });

  return cancellationReasons;
};

export const isSecondaryCancellationReasonValid = (
  mainReason: string,
  secondaryReason: string,
  customerComment: string,
) => {
  if (!mainReason) return true;

  if (
    mainReason !== 'I have been added to the main insurance' &&
    mainReason !== 'I have passed my test' &&
    secondaryReason === ''
  )
    return false;
  if (secondaryReason === 'Other' && customerComment.trim().length === 0) return false;

  return true;
};
