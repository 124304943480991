import { connect } from 'react-redux';
import { AppState } from 'state';
import { licenceLabelFromType } from 'utils/transformers/licenceTypes';
import { toFullDate } from 'utils/transformers/dateFormat';
import { Address, OccupationTypes } from '@rentecarlo/component-library';

import Profile from './Profile';

export interface ComponentProps {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  licenceType?: string;
  licenceNumber: string;
  occupationType?: OccupationTypes;
  occupation: string;
  address: Address;
  loading: boolean;
}

const mapStateToProps = (state: AppState): ComponentProps => ({
  firstName: state.profile.personalDetails.firstName,
  lastName: state.profile.personalDetails.lastName,
  dateOfBirth:
    state.profile.personalDetails.dateOfBirth &&
    toFullDate(state.profile.personalDetails.dateOfBirth),
  licenceType:
    state.profile.personalDetails.licenceType &&
    licenceLabelFromType(state.profile.personalDetails.licenceType),
  licenceNumber: state.profile.personalDetails.licenceNumber,
  occupationType: state.profile.contactDetails.occupationType,
  occupation: state.profile.contactDetails.occupation,
  address: state.profile.contactDetails.address,
  loading: state.profile.loading,
});

export default connect(mapStateToProps)(Profile);
