import { FormattedOccupationTypes, OccupationTypes } from '@rentecarlo/component-library';

export type OccupationValues = {
  text: FormattedOccupationTypes;
  value: OccupationTypes;
};

export const occupationTypeMap: Array<OccupationValues> = [
  {
    text: 'Student',
    value: 'student',
  },
  {
    text: 'Employed',
    value: 'employed',
  },
  {
    text: 'Other',
    value: 'other',
  },
];
