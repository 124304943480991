import Styled from 'styled-components';
import { Fonts } from 'utils/config';
import { ThemeUtils } from '@rentecarlo/component-library';

interface Props {
  theme: ThemeUtils.Theme;
}

export const HorizontalLayoutContainer = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

export const DetailsIcon = Styled.img`
    width: 32px;
    height: 32px;
    margin-top: 2px;
`;

export const DetailsContainer = Styled.div`
    margin-left: 16px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
`;

export const DetailsTitle = Styled.div`
    font-family: ${Fonts.title};
    font-weight: bold;
    font-size: 20px;
    color: ${({ theme }: Props): string => theme.textPrimary};
    padding-bottom: 8px;
`;

export const DetailsText = Styled.div`
    font-family: ${Fonts.standard};
    font-size: 14px;
    color: ${({ theme }: Props): string => theme.textSecondary};
`;
