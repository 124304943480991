import { useState, useEffect, useRef } from 'react';
import { Policy } from '@rentecarlo/component-library';
import { PurchasesClient } from 'services/purchasesClient';

type PolicyFiles = {
  certificate: string;
  policy: string;
  proposition: string;
};

export const usePolicyDocuments = (policy?: Policy, lastUpdate?: Date): PolicyFiles => {
  const [documentsUrls, setDocumentsUrls] = useState({
    certificate: '',
    policy: '',
    proposition: '',
  });

  const originalLastUpdate = useRef(lastUpdate);

  useEffect(() => {
    let unmounted = false;
    if (policy && lastUpdate !== originalLastUpdate.current) {
      PurchasesClient.getPolicyDocuments(policy.uuid, 'certificate').then((response) => {
        const file = window.URL.createObjectURL(response);
        if (!unmounted) {
          setDocumentsUrls((prevState) => ({ ...prevState, certificate: file }));
        }
      });

      PurchasesClient.getPolicyDocuments(policy.uuid, 'proposition').then((response) => {
        const file = window.URL.createObjectURL(response);
        if (!unmounted) {
          setDocumentsUrls((prevState) => ({ ...prevState, proposition: file }));
        }
      });

      PurchasesClient.getPolicyDocuments(policy.uuid, 'policy').then((response) => {
        const file = window.URL.createObjectURL(response);
        if (!unmounted) {
          setDocumentsUrls((prevState) => ({ ...prevState, policy: file }));
        }
      });
    }

    return () => {
      unmounted = true;
    };
  }, [policy, lastUpdate]);

  return documentsUrls;
};
