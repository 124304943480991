import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';

import { Card, ThemeUtils, Tooltip, TooltipEvents } from '@rentecarlo/component-library';
import { PageTitle } from 'components/atoms';
import {
  DetailsSummary,
  PersonalDetailsSkeletonLoader,
  ContactSkeletonLoader,
} from 'components/molecules';
import { ContactDetails, PageLayout, Section } from 'components/templates';
import { ComponentProps } from './Profile.container';

import {
  Container,
  ContentContainer,
  ProfileCardContainer,
  CardRowContainer,
  LicenceTextContainer,
  CardContentContainer,
  Link,
  CardRowTitle,
  CardRecordSecondary,
  LicenceContainer,
  IconContainer,
  TooltipText,
  TooltipTextContainer,
} from './assets/styles';
import ProfileIcon from './assets/active.svg';
import ContactDetailsIcon from './assets/contact.svg';

interface Props extends ComponentProps {
  theme: ThemeUtils.Theme;
}

const Profile = ({
  firstName,
  lastName,
  dateOfBirth,
  licenceType,
  licenceNumber,
  theme,
  loading,
}: Props): ReactElement => {
  const tooltipEvents = new TooltipEvents();
  const isProvisionalLicence = licenceType === 'Provisional Licence';
  return (
    <PageLayout>
      <Container>
        <PageTitle id='pageTitle'>Profile</PageTitle>
        <ContentContainer>
          <Section bottomBorder firstColumnWidth={40} reduceBorderSize='48px'>
            <DetailsSummary image={ProfileIcon} detailTitle='Personal details'>
              These details aren’t editable as they have been used on your policy documents. If
              they're wrong please <Link href='https://www.veygo.com/contact'>contact us</Link>.
            </DetailsSummary>
            {!loading ? (
              <ProfileCardContainer>
                <Card>
                  <CardContentContainer>
                    <CardRowContainer>
                      <CardRowTitle>Name:</CardRowTitle>
                      <div id='name'>
                        {firstName} {lastName}
                      </div>
                    </CardRowContainer>
                    <CardRowContainer>
                      <CardRowTitle>Date of birth:</CardRowTitle>
                      <div id='dateOfBirth'>{dateOfBirth}</div>
                    </CardRowContainer>
                    <CardRowContainer>
                      <CardRowTitle>Licence type:</CardRowTitle>
                      <LicenceTextContainer>
                        <div id='licenceType'>
                          <LicenceContainer>
                            <div>{licenceType}</div>
                            {isProvisionalLicence && (
                              <IconContainer id='tooltip-info-icon'>
                                <Tooltip
                                  title='Changing your licence type'
                                  modalTitle='Changing your licence type'
                                  events={tooltipEvents}
                                  tooltipDirection='right'
                                  width={400}
                                >
                                  <TooltipTextContainer>
                                    <TooltipText>
                                      First off you'll need to use our website rather than App
                                      (sorry!). Now, to change your provisional licence to a full
                                      one just log in to your account and start a new quote. Once
                                      you accept you're happy for us to use your data, you'll be
                                      taken to a page where you'll see a box which says 'Passed your
                                      test?'... just hit 'Change to full licence'.
                                    </TooltipText>
                                    <TooltipText>
                                      You'll be given the option to upgrade your account and change
                                      your licence type. All done.
                                    </TooltipText>
                                  </TooltipTextContainer>
                                </Tooltip>
                              </IconContainer>
                            )}
                          </LicenceContainer>
                        </div>
                        <CardRecordSecondary id='licenceNumber'>
                          {licenceNumber}
                        </CardRecordSecondary>
                      </LicenceTextContainer>
                    </CardRowContainer>
                  </CardContentContainer>
                </Card>
              </ProfileCardContainer>
            ) : (
              <PersonalDetailsSkeletonLoader />
            )}
          </Section>
          <Section firstColumnWidth={40} marginTop='23px'>
            <DetailsSummary image={ContactDetailsIcon} detailTitle='Contact details'>
              You can only edit these details once every 30 days. So if you're editing them, just
              double check it before hitting Save. On that note, you need to hit 'Save details' at
              the bottom for any changes you make to be saved.
            </DetailsSummary>
            {!loading ? <ContactDetails theme={theme} /> : <ContactSkeletonLoader />}
          </Section>
        </ContentContainer>
      </Container>
    </PageLayout>
  );
};

export default withTheme<React.FC<Props>>(Profile);
