import { FieldProps } from 'formik';
import React from 'react';
import { SearchInput } from '@rentecarlo/component-library';

interface InputSearchFieldInterface extends FieldProps {
  alwaysCapitalised: boolean;
  id: string;
  placeholder: string;
  onSearch: () => void;
  error?: string;
}

type ErrorText = string | undefined;

const InputSearchField: React.FC<InputSearchFieldInterface> = ({
  field,
  form,
  onSearch,
  error,
  ...others
}) => {
  //  Cast FieldError from FormikError<any> to a handled
  //  String | Undefined so our generic input is agnostic to Formik.
  const errorText = (form.errors[field.name] as ErrorText) || error;
  return (
    <SearchInput
      errorText={errorText}
      containerError={!!error}
      onSearch={(event: React.MouseEvent): void => {
        event.preventDefault();
        onSearch();
      }}
      {...field}
      {...others}
      bold
    />
  );
};

export default InputSearchField;
