import React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { ToggleContainer } from './assets/styles';
import './assets/toggle.css';

interface NotificationToggleInterface {
  id: string;
  isChecked: boolean;
  toggleNotification: () => void;
}

export const NotificationToggle: React.FC<NotificationToggleInterface> = ({
  id,
  toggleNotification,
  isChecked,
}) => {
  return (
    <ToggleContainer>
      <Toggle
        id={id}
        className='custom-react-toggle'
        checked={isChecked}
        onChange={toggleNotification}
      />
    </ToggleContainer>
  );
};
