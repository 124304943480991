import styled, { css } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

interface SpacerProps {
  height: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > h1 {
    text-align: left;
    margin-bottom: 16px;
  }
`;

export const InfoContainer = styled.div`
  margin-bottom: 16px;
`;

export const SubheadingContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: bold;
`;

export const ButtonsContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
  ${MediaQuery.mobile(css`
    flex-direction: column;
    height: 144px;
  `)};
`;

export const ButtonContainer = styled.div`
  width: 192px;
  ${MediaQuery.mobile(css`
    width: 100%;
  `)};
`;

export const DescriptionText = styled.div`
  font-family: 'proxima-soft';
  font-size: 16px;
  color: '#364344';
  line-height: 26px;
`;

export const Spacer = styled.div<SpacerProps>`
  height: ${({ height }) => height}px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
