import React from 'react';
import { FieldProps } from 'formik';
import { AddressSpeechBox, Address } from '@rentecarlo/component-library';

interface InputAddressSpeechBoxInterface extends FieldProps {
  id: string;
  addresses: Address[];
  visible: boolean;
  text: string;
  footerText: string;
  onChange: () => void;
  selectedAddress: number;
  toggleAddressCard: () => void;
  touched: boolean;
  active: boolean;
  pristine: boolean;
  submitFailed: boolean;
}

const InputAddressSpeechBox: React.FC<InputAddressSpeechBoxInterface> = (props) => {
  const { ...others } = props;

  return <AddressSpeechBox {...others} />;
};

export default InputAddressSpeechBox;
