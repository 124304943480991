/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Styled from 'styled-components';

interface StyleProps {
  bottomMargin?: string;
}

export const Container = Styled.div<StyleProps>`
  margin-bottom: ${({ bottomMargin }) => bottomMargin || '32px'};
`;

export const FormItemLabel = Styled.span`
  height: 19px;
  width: 80px;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.32px;
  line-height: 19px;
`;

export const FormChildrenWrapper = Styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`;
