import { Address } from '@rentecarlo/component-library';
import {
  ContactDetailsState,
  ProfileAPITransform,
  AddressAPITransformV3,
} from 'state/profile/types';
import Client from './client';
import { createCustomerRequestUrl, createCSIRequestUrl } from './clientUtils';

const localAddressToRemote = (address: Address): AddressAPITransformV3 => {
  const baseRequestAddress = {
    flat_no: address.FlatNumber,
    house_no: address.HouseNumber,
    house_name: address.HouseName,
    line1: address.AddressLine1,
    line2: address.AddressLine2,
    road: address.Road,
    town: address.Town,
    county: address.County,
    postcode: address.Postcode,
  };

  return baseRequestAddress;
};

const localFieldsToRemote = (data: ContactDetailsState): ProfileAPITransform => ({
  personal_details: {
    occupation_type: data.occupationType,
    occupation: data.occupation,
    phone_number: data.phoneNumber,
    email: data.email,
    can_edit: data.canEdit,
    loading: false,
  },
  address: localAddressToRemote(data.address),
});

const updateContactDetails = (
  data: ContactDetailsState,
  endpoint: string,
): Promise<ProfileAPITransform> => {
  return new Client.Builder()
    .put(`${createCustomerRequestUrl(endpoint)}`)
    .data(localFieldsToRemote(data))
    .execute();
};

const checkEmailExists = (email: string): Promise<unknown> => {
  return new Client.Builder()
    .post(`${createCSIRequestUrl('/accnt/login/check_email')}`)
    .data({ email })
    .execute();
};

export const ProfileClient = {
  updateContactDetails,
  checkEmailExists,
};
