import * as React from 'react';
import {
  HouseWrapper,
  IconImgHolder,
  KebabIcon,
  KebabWrapper,
  StyledBreadcrumb,
  TitleWrapper,
} from './assets/styles';

interface Props {
  toggleMenu: () => void;
}

const Breadcrumb: React.FC<Props> = (props) => {
  const { toggleMenu } = props;
  return (
    <StyledBreadcrumb>
      <HouseWrapper>
        <IconImgHolder />
      </HouseWrapper>
      <TitleWrapper>Overview</TitleWrapper>
      <KebabWrapper id='kebabIcon' onClick={toggleMenu} data-testid='kebabIcon'>
        <KebabIcon />
      </KebabWrapper>
    </StyledBreadcrumb>
  );
};

export default Breadcrumb;
