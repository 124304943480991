import Styled from 'styled-components';

export const PostcodeAddressContainer = Styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OccupationContainer = Styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
