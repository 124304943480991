import { PillButton } from 'components/atoms';
import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Theme } from 'utils/theme';
import {
  Container,
  GetHelpContainer,
  GetHelpIcon,
  GetHelpIconWrapper,
  GetHelpMobileButtonWrapper,
  GetHelpMobileContainer,
  GetHelpPillButtonContainer,
  GetHelpTextWrapper,
  GetHelpWrapper,
  MyAccountContainer,
  MyAccountIcon,
  MyAccountIconWrapper,
  VeygoLogo,
  VeygoLogoContainer,
  VeygoLogoLink,
} from './assets/styles';

interface Props {
  theme: Theme;
}

const RenderVeygoLogoSection: React.FC<unknown> = () => (
  <VeygoLogoContainer>
    <VeygoLogoLink>
      <VeygoLogo />
    </VeygoLogoLink>
  </VeygoLogoContainer>
);

const RenderGetHelpSection: React.FC<Props> = ({ theme }) => {
  const [helpButtonActive, setButtonActive] = useState(false);
  const setActive = (): void => setButtonActive(true);
  const setInactive = (): void => setButtonActive(false);
  return (
    <GetHelpContainer data-testid='help-container'>
      <GetHelpWrapper
        onMouseOver={setActive}
        onMouseOut={setInactive}
        onFocus={setActive}
        onBlur={setInactive}
      >
        <a href='https://www.veygo.com/help' target='_blank' rel='noopener noreferrer'>
          <PillButton
            backgroundColor='transparent'
            borderColor={theme.backgroundPrimary}
            textColor={theme.backgroundPrimary}
            id='getHelpButton'
            height={33}
            borderWidth={1}
          >
            <GetHelpPillButtonContainer>
              <GetHelpIconWrapper>
                <GetHelpIcon hoverActive={helpButtonActive} />
              </GetHelpIconWrapper>
              <GetHelpTextWrapper>Get Help</GetHelpTextWrapper>
            </GetHelpPillButtonContainer>
          </PillButton>
        </a>
      </GetHelpWrapper>
    </GetHelpContainer>
  );
};

const RenderMyAccountSection: React.FC<unknown> = () => {
  return (
    <MyAccountContainer>
      <MyAccountIconWrapper>
        <MyAccountIcon />
      </MyAccountIconWrapper>
    </MyAccountContainer>
  );
};

const RenderMobileGetHelpSection: React.FC<unknown> = () => (
  <GetHelpMobileContainer>
    <GetHelpMobileButtonWrapper>
      <GetHelpIcon hoverActive={false} />
    </GetHelpMobileButtonWrapper>
  </GetHelpMobileContainer>
);

const Header: React.FC<Props> = ({ theme }: Props) => (
  <Container>
    <RenderVeygoLogoSection />
    <RenderGetHelpSection theme={theme} />
    <RenderMobileGetHelpSection />
    <RenderMyAccountSection />
  </Container>
);

export default withTheme<React.FC<Props>>(Header);
