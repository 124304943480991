import * as Sentry from '@sentry/react';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { enhancer } from './account';
import rootReducer from './reducer';
import rootSaga from './saga';

const { REACT_APP_USE_REDUX_DEVTOOLS } = process.env;
const composeEnhancers =
  REACT_APP_USE_REDUX_DEVTOOLS === 'true' ? composeWithDevTools({ trace: true }) : compose;

const handleSagaError = (error: Error, errorInfo: { sagaStack: string }) => {
  // eslint-disable-next-line
  console.error(error);
  // eslint-disable-next-line
  console.error(errorInfo.sagaStack);
};

const sagaMiddleware = createSagaMiddleware({
  onError: handleSagaError,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
    sentryReduxEnhancer,
    enhancer.resetStoreOnLogoutEnhancer,
  ),
);

sagaMiddleware.run(rootSaga);

export default store;
