import React, { ReactElement, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import {
  ThemeUtils,
  ActionsCard,
  ActionsCardLink,
  PillButton,
  Status,
  InfoTextBox,
  PolicyCard,
  SubscriptionCard,
  Policy,
  Subscription,
  PolicyStatus,
  TextLink,
  Currency,
  VeygoResponseError,
  VeygoError,
  AlertBanner,
  InformationBox,
  Transformers,
  AppLinks,
} from '@rentecarlo/component-library';
import moment from 'moment';
import { PageLayout } from 'components/templates';
import { CancelType } from 'types/Subscription';
import {
  CancelModal,
  RetryRefundModal,
  CancelSubsModal,
  ClaimStatusModal,
} from 'components/organisms';
import { RootState } from 'state/reducer';
import { PurchasesClient } from 'services';
import CaptureIncidentDate from 'components/organisms/CaptureIncidentDate/CaptureIncidentDate.component';
import { StartClaimInfo, StartClaimSubsInfo } from 'components/organisms/StartClaimInfoModal';
import { useOptimizelyFlag } from 'utils/hooks';
import {
  Container,
  ContentContainer,
  BackButtonContainer,
  AmountText,
  ActionsContainer,
  ContentSection,
  DetailedPurchaseContainer,
  BannerText,
  BannerStatusText,
  Loader,
  AppLinksContainer,
} from './assets/styles';
import { ReactComponent as DocumentImage } from './assets/file-certificate-blue.svg';
import { ReactComponent as CancelPolicyImage } from './assets/ban.svg';
import { ReactComponent as HelpAndSupportImage } from './assets/question-circle.svg';
import { ReactComponent as SpeechIcon } from './assets/speech.svg';
import { ReactComponent as PaymentImage } from './assets/wallet.svg';
import { ReactComponent as RecoveryImage } from './assets/recovery-icon.svg';
import BluePurpleBackground from './assets/prompt-box-background.svg';
import { LoadingIcon } from '../../assets';

interface Props {
  theme: ThemeUtils.Theme;
  isSubscription?: boolean;
  policy: Policy;
  subscription: Subscription;
  cancelPolicy: (reason: string, comment: string) => void;
  cancelSubscription: (reason: string, cancelType: CancelType, comment: string) => void;
  files: {
    certificate: string;
    policy: string;
    proposition: string;
  };
  getRefundAmount: (uuid: string) => void;
  loading: boolean;
  purchasesError: VeygoError | null;
  policyCancelSucceeded?: boolean;
  retrieveUserPolicies: () => void;
  clearPurchasesError: () => void;
  clearCancelSuccessState: () => void;
  getSubsRefundAmount: (policy_uuid: string, subs_uuid: string) => void;
  retrieveSubscriptions: () => void;
}

enum ShowModal {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

const isValidPurchasesError = (e: VeygoError): e is VeygoResponseError => {
  const error = e as VeygoResponseError;
  return error.message !== undefined && error.code !== undefined;
};

const isIncorrectRefundAmountError = (e: VeygoError): boolean => {
  return isValidPurchasesError(e) && e.code === 'INCORRECTREFUNDAMOUNT';
};

const DetailedPurchase = ({
  isSubscription = false,
  subscription,
  policy,
  cancelPolicy,
  cancelSubscription,
  files,
  getRefundAmount,
  loading,
  purchasesError,
  policyCancelSucceeded,
  retrieveUserPolicies,
  clearPurchasesError,
  clearCancelSuccessState,
  getSubsRefundAmount,
  retrieveSubscriptions,
}: Props): ReactElement => {
  const [showCancellationModal, setShowCancellationModal] = useState<ShowModal>(ShowModal.CLOSED);
  const [showRetryRefundModal, setShowRetryRefundModal] = useState<ShowModal>(ShowModal.CLOSED);
  const [showClaimsModal, setShowClaimsModal] = useState<ShowModal>(ShowModal.CLOSED);
  const [startingClaim, setStartingClaim] = useState(false);
  const [startClaimError, setStartClaimError] = useState(false);
  const [showCustomerPortalError, setShowCustomerPortalError] = useState(false);
  const [disableCustomerPortalButton, setDisableCustomerPortalButton] = useState(false);
  const [showCaptureIncidentDate, setShowCaptureIncidentDate] = useState(false);
  const [height, setHeight] = useState('100%');
  const enableCaptureDate = useOptimizelyFlag('TEMP_ENABLE_CLAIMS');

  useEffect(() => {
    const deviceHeight = window.innerHeight;
    const deviceWidth = window.innerWidth;
    if (deviceWidth <= 380 && deviceHeight <= 680) {
      setHeight('80%');
    }
  }, []);

  useEffect(() => {
    if (purchasesError && isIncorrectRefundAmountError(purchasesError)) {
      setShowRetryRefundModal(ShowModal.OPEN);
    }
  }, [purchasesError]);

  useEffect(() => {
    clearCancelSuccessState();
  }, [clearCancelSuccessState]);

  const oneLinkUrl = 'https://veygoppc.onelink.me/QP8O/bbxct0bz';

  const history = useHistory();
  const goBackToPurchases = useCallback(() => history.push(`/purchases`), [history]);

  const getPolicyStatus = (): Status => {
    if (policy.cancelled) {
      return Status.ERROR;
    }
    if (Date.parse(policy.durationEnd) < Date.now()) {
      return Status.ERROR;
    }
    if (Date.parse(policy.durationStart) > Date.now()) {
      return Status.INFO;
    }

    return Status.SUCCESS;
  };
  /**
   * getStatusText
   * @param currentPolicyStatus
   * @returns {PolicyStatus} return current status of the policy
   */
  const getStatusText = (currentPolicyStatus: Policy): PolicyStatus => {
    if (currentPolicyStatus.cancelled) {
      return PolicyStatus.CANCELLED;
    }
    if (Date.parse(currentPolicyStatus.durationEnd) < Date.now()) {
      return PolicyStatus.ENDED;
    }
    if (Date.parse(currentPolicyStatus.durationStart) > Date.now()) {
      return PolicyStatus.UPCOMING;
    }
    return PolicyStatus.ACTIVE;
  };

  const PolicyDocuments: ActionsCardLink[] = [
    {
      name: 'Certificate of insurance',
      Image: DocumentImage,
      onClick: () => window.open(files.certificate),
    },
    {
      name: 'Motor policy schedule',
      Image: DocumentImage,
      onClick: () => window.open(files.policy),
    },
    {
      name: 'Motor proposal confirmation',
      Image: DocumentImage,
      onClick: () => window.open(files.proposition),
    },
  ];

  const getProductHelpURL = () => {
    if (policy) {
      if (
        policy.productName === 'Car sharing insurance' ||
        policy.productName === 'Temporary car insurance'
      ) {
        return 'https://www.veygo.com/help/temporary-car-insurance/';
      }

      if (policy.productName === 'Learner driver insurance') {
        return 'https://www.veygo.com/help/learner-driver-insurance/';
      }
    }

    return 'https://www.veygo.com/help';
  };

  const redirectToClaimForm = () => {
    const win = window.open('', '_blank');
    if (win) {
      win.location.href = 'https://www.veygo.com/contact/';
      win.focus();
    }
  };

  const openIncidentDate = () => {
    setShowCaptureIncidentDate(true);
  };

  const purchases = useSelector((state: RootState) => state.purchases);

  const checkRelatedPolicy = (date: string) => {
    const dateMoment = moment(date);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const checkDateInRange = (item: any) => {
      const durationStart = moment(item.durationStart);
      const durationEnd = moment(item.durationEnd);
      return dateMoment.isBetween(durationStart, durationEnd);
    };

    const categories = ['active', 'ended', 'upcoming'];
    if (!isSubscription) {
      const subscriptionUUID = categories
        .flatMap((category) => {
          return purchases.subscriptions[category].find(checkDateInRange)?.uuid;
        })
        .find(Boolean);
      return subscriptionUUID;
    }
    return policy.uuid;
  };

  const startClaimSelfServe = async (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    date?: string | null,
  ) => {
    let polidcyUUID = policy.uuid;
    if (date) {
      const linkedPolicy = checkRelatedPolicy(date);
      if (linkedPolicy) {
        polidcyUUID = linkedPolicy;
      }
    }
    setShowClaimsModal(ShowModal.CLOSED);
    setStartingClaim(true);
    const win = window.open('', '_blank');

    try {
      const result = await PurchasesClient.getMakeClaimChatbotUrl(polidcyUUID, date);
      if (win) {
        if (result && result.chatbotUrl) {
          win.location.href = result.chatbotUrl;
          win.focus();
        } else {
          win.close();
        }
      }
    } catch (error) {
      setStartClaimError(true);
      win?.close();
    }
    setStartingClaim(false);
  };

  const redirectToUpdatePaymentDetails = async () => {
    setShowCustomerPortalError(false);
    setDisableCustomerPortalButton(true);

    if (!subscription) {
      setShowCustomerPortalError(true);
      return;
    }

    try {
      const result = await PurchasesClient.getCustomerPortalUrl(subscription);
      window.open(result.url, '_blank');
    } catch (error) {
      setShowCustomerPortalError(true);
    }
    setDisableCustomerPortalButton(false);
  };

  const recoveryAction = {
    name: 'I need recovery',
    id: 'needRecovery',
    Image: RecoveryImage,
    onClick: () => window.open('https://www.veygo.com/help-centre/if-your-vehicle-needs-recovery/'),
    ActionType: Status.WARNING,
  };

  const showClaimsAction = policy && getStatusText(policy) !== PolicyStatus.UPCOMING;
  const Actions: ActionsCardLink[] = [
    ...(showClaimsAction
      ? [
          {
            name: 'Start a claim',
            id: 'startClaim',
            onClick: () => {
              setShowClaimsModal(ShowModal.OPEN);
            },
            Image: SpeechIcon,
            ActionType: Status.INFO,
          },
        ]
      : []),
    {
      name: 'Help and support',
      Image: HelpAndSupportImage,
      onClick: () => window.open(getProductHelpURL()),
      ActionType: Status.INFO,
    },
    recoveryAction,
    {
      name: 'Cancel policy',
      id: 'cancelPolicy',
      Image: CancelPolicyImage,
      onClick: () => {
        setShowCancellationModal(ShowModal.OPEN);
        getRefundAmount(policy.uuid);
      },
      isDisabled: loading || (policy ? getPolicyStatus() === Status.ERROR : false),
      ActionType: Status.ERROR,
    },
  ];
  const SubscriptionActions: ActionsCardLink[] = [
    ...(showClaimsAction
      ? [
          {
            name: 'Start a claim',
            id: 'startClaim',
            onClick: () => {
              setShowClaimsModal(ShowModal.OPEN);
            },
            Image: SpeechIcon,
            ActionType: Status.INFO,
          },
        ]
      : []),
    {
      name: 'Help and support',
      Image: HelpAndSupportImage,
      onClick: () => window.open(getProductHelpURL()),
      ActionType: Status.INFO,
    },
    ...(subscription.status === PolicyStatus.ACTIVE || subscription.status === PolicyStatus.UPCOMING
      ? [
          {
            name: 'Update payment details',
            Image: PaymentImage,
            onClick: () => redirectToUpdatePaymentDetails(),
            ActionType: Status.INFO,
            isDisabled: disableCustomerPortalButton,
          },
        ]
      : []),
    recoveryAction,
    {
      name: 'Cancel subscription',
      id: 'cancelSubscription',
      Image: CancelPolicyImage,
      onClick: () => {
        setShowCancellationModal(ShowModal.OPEN);
        if (subscription.status === PolicyStatus.UPCOMING) {
          getSubsRefundAmount(policy.uuid, subscription.uuid);
        }
      },
      isDisabled: loading || subscription.cancelled,
      ActionType: Status.ERROR,
    },
  ];

  const getRefundHelpCentreLink = () => {
    if (
      policy.productName === 'Car sharing insurance' ||
      policy.productName === 'Temporary car insurance'
    ) {
      return (
        <>
          For more information on how refunds are calculated, please visit{' '}
          <TextLink
            fontSize={14}
            href='https://www.veygo.com/help-center/i-need-to-cancel-my-policy-2'
          >
            this page
          </TextLink>
          .
        </>
      );
    }
    return (
      <>
        For more information on how refunds are calculated, please visit{' '}
        <TextLink fontSize={14} href='https://www.veygo.com/help-center/i-need-to-cancel-my-policy'>
          this page
        </TextLink>
        .
      </>
    );
  };

  const closeRetryRefundModal = () => {
    setShowRetryRefundModal(ShowModal.CLOSED);
    if (isSubscription) {
      retrieveSubscriptions();
    } else {
      retrieveUserPolicies();
    }
    clearPurchasesError();
  };
  const breakdownRefundText = () => {
    if (policy.isBreakdownCoverPurchased) {
      return `, excluding the cost of your breakdown cover which is non-refundable`;
    }
    return '';
  };
  // eslint-disable-next-line consistent-return
  const getRefundText = () => {
    if (new Date(policy.durationStart) > new Date()) {
      return (
        <>
          As your policy has not started yet, you'll receive a full refund of
          <AmountText> {Currency.formatAsPounds(policy.refundAmount || policy.price)}</AmountText>,
          within 3 to 10 days of cancelling.
        </>
      );
    }

    if (policy.refundAmount && Number(policy.refundAmount) > 0) {
      return (
        <>
          You'll receive a refund of
          <AmountText> {Currency.formatAsPounds(policy.refundAmount)} </AmountText>
          for the remaining days left on your policy, within 3 to 10 days of cancelling
          {breakdownRefundText()}.
        </>
      );
    }

    return (
      <>
        There’s no refund due for the remaining time left on your policy. To continue, please click
        ‘Cancel policy’ below.
      </>
    );
  };

  const getAlertContent = () => {
    return (
      <>
        <BannerText>Your policy has been</BannerText>
        <BannerStatusText>cancelled</BannerStatusText>
      </>
    );
  };

  const closeStartClaimModal = () => {
    setStartClaimError(false);
    setStartingClaim(false);
  };

  return (
    <DetailedPurchaseContainer>
      {policyCancelSucceeded && (
        <AlertBanner id='cancellationBanner'>{getAlertContent()}</AlertBanner>
      )}
      <PageLayout>
        {showCaptureIncidentDate && enableCaptureDate.enabled && (
          <CaptureIncidentDate
            visible={showCaptureIncidentDate}
            error={startClaimError}
            close={() => setShowCaptureIncidentDate(false)}
            makeClaim={startClaimSelfServe}
            height={height}
          />
        )}
        <ClaimStatusModal
          visible={startingClaim}
          error={startClaimError}
          makeClaim={startClaimSelfServe}
          close={closeStartClaimModal}
        />
        {isSubscription ? (
          <CancelSubsModal
            show={showCancellationModal === ShowModal.OPEN}
            close={() => setShowCancellationModal(ShowModal.CLOSED)}
            cancel={(reason: string, cancelType: CancelType, comment: string) => {
              cancelSubscription(reason, cancelType, comment);
              setShowCancellationModal(ShowModal.CLOSED);
            }}
            subscription={subscription}
            learner={subscription.productName === 'Learner driver insurance'}
          />
        ) : (
          <CancelModal
            show={showCancellationModal === ShowModal.OPEN}
            close={() => setShowCancellationModal(ShowModal.CLOSED)}
            cancel={(reason: string, comment: string) => {
              cancelPolicy(reason, comment);
              setShowCancellationModal(ShowModal.CLOSED);
            }}
            disabled={loading || getPolicyStatus() === Status.ERROR}
            learner={policy.productName === 'Learner driver insurance'}
          >
            <InfoTextBox state={Status.INFO} id='info-refund-amount'>
              {getRefundText()}
              <br />
              {getRefundHelpCentreLink()}
            </InfoTextBox>
          </CancelModal>
        )}
        {!isSubscription ? (
          <StartClaimInfo
            show={showClaimsModal === ShowModal.OPEN}
            close={() => setShowClaimsModal(ShowModal.CLOSED)}
            startClaim={enableCaptureDate.enabled ? openIncidentDate : startClaimSelfServe}
          />
        ) : (
          <StartClaimSubsInfo
            show={showClaimsModal === ShowModal.OPEN}
            close={() => setShowClaimsModal(ShowModal.CLOSED)}
            startClaim={enableCaptureDate.enabled ? openIncidentDate : redirectToClaimForm}
          />
        )}
        <RetryRefundModal
          show={showRetryRefundModal === ShowModal.OPEN}
          onClick={closeRetryRefundModal}
        />
        <Container>
          <ContentContainer>
            <BackButtonContainer>
              <PillButton onClick={goBackToPurchases}>Back</PillButton>
            </BackButtonContainer>
            {isSubscription && subscription.cancelled && subscription.activePolicy && (
              <InformationBox type='important' id='subsCancellationLapseInfoBox'>
                This subscription will end on{' '}
                <b>
                  {' '}
                  {Transformers.DateFormat.toShortDayNameDayMonthYear(
                    subscription.activePolicy.durationEnd,
                  )}{' '}
                  at {Transformers.DateFormat.toTime(subscription.activePolicy.durationEnd)}
                </b>
                . After this time you will not be covered.
              </InformationBox>
            )}
            <ContentSection>
              {isSubscription ? (
                <SubscriptionCard
                  subscription={subscription}
                  detailedCard
                  action={() => {}}
                  updatePaymentLink={
                    'updateUrl' in subscription && subscription.updateUrl
                      ? String(subscription.updateUrl)
                      : null
                  }
                />
              ) : (
                <PolicyCard
                  policy={policy}
                  detailedCard
                  policyStatus={getStatusText(policy)}
                  action={() => {}}
                />
              )}

              <ActionsContainer>
                {loading && <Loader src={LoadingIcon} id='loadingImg' />}
                <ActionsCard
                  title='Actions'
                  actions={isSubscription ? SubscriptionActions : Actions}
                >
                  {showCustomerPortalError && (
                    <InformationBox type='error'>
                      Something went wrong. Please try again
                    </InformationBox>
                  )}
                </ActionsCard>
                <ActionsCard title='Your policy documents' actions={PolicyDocuments} />
                {policy.productType !== 'newdriver' && (
                  <AppLinksContainer>
                    <AppLinks
                      background={BluePurpleBackground}
                      buttonText='Get the app'
                      displayButton
                      id='AppLinksWithButton'
                      title='Download the Veygo app for quick access to your policy documents'
                      url={oneLinkUrl}
                    />
                  </AppLinksContainer>
                )}
              </ActionsContainer>
            </ContentSection>
          </ContentContainer>
        </Container>
      </PageLayout>
    </DetailedPurchaseContainer>
  );
};

export default withTheme<React.FC<Props>>(DetailedPurchase);
