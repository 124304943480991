import { FieldProps } from 'formik';
import React from 'react';
import { CheckboxList } from '@rentecarlo/component-library';

interface CheckboxFieldProps {
  id: string;
}

type OptionType = {
  name: string;
  value: string;
};

interface ComponentProps {
  disabled: boolean;
  options: Array<OptionType>;
  onClick: () => void;
}

type ErrorText = string | undefined;

interface CheckboxField extends FieldProps, CheckboxFieldProps, ComponentProps {}

// Formik Field Input handler
const CheckboxListField: React.FC<CheckboxField> = ({ field, form, options, ...others }) => {
  const errorText = form.errors[field.name] as ErrorText;
  return (
    <CheckboxList
      options={options}
      errorText={errorText}
      containerError={!!errorText}
      {...field}
      {...others}
    />
  );
};

export default CheckboxListField;
