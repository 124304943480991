import React, { useState } from 'react';
import { FormItem } from 'components/atoms';
import { Field } from 'formik';
import { InputField, PasswordInputField } from 'components/molecules';
import { InformationBox, LinkButton } from '@rentecarlo/component-library';
import styled from 'styled-components';
import { isEmail, isRequired } from 'utils/validation';
import { FormValues } from './ContactDetailsInput';

interface Props {
  values: FormValues;
  canEdit: boolean;
  submitError: boolean;
}

const ButtonContainer = styled.div`
  align-self: self-start;
  margin-top: -16px;
`;

const UpdateEmailInput: React.FC<Props> = ({ values, canEdit, submitError }) => {
  const [updateEmail, setUpdateEmail] = useState(false);
  const [readOnly, setReadOnly] = useState(true);

  const validateRetypedEmail = (value: string): string | undefined => {
    if (value !== values.newEmail) {
      return 'The email addresses you have entered do not match';
    }
    return undefined;
  };

  return !updateEmail ? (
    <FormItem label='Your email address' bottomMargin='57px'>
      <Field
        id='email'
        name='email'
        aria-label='email'
        placeholder='Email address'
        value={values.email}
        component={InputField}
        type='email'
        maxLength={255}
        disabled
      />
      {canEdit && (
        <ButtonContainer>
          <LinkButton id='updateEmail' action={() => setUpdateEmail(true)}>
            Change email address
          </LinkButton>
        </ButtonContainer>
      )}
    </FormItem>
  ) : (
    <FormItem label='New email address' bottomMargin='57px'>
      <Field
        id='newEmail'
        name='newEmail'
        aria-label='newEmail'
        placeholder='Type new email'
        value={values.newEmail}
        component={InputField}
        validate={isEmail}
        type='email'
        maxLength={255}
        submitError={submitError}
        pasteDisabled
        autocomplete='off'
      />
      <Field
        id='retypeEmail'
        name='retypeEmail'
        aria-label='retypeEmail'
        placeholder='Re-type new email'
        value={values.retypeEmail}
        component={InputField}
        validate={validateRetypedEmail}
        type='email'
        maxLength={255}
        submitError={submitError}
        pasteDisabled
      />
      <Field
        id='password'
        name='password'
        value={values.password}
        placeholder='Confirm current password'
        component={PasswordInputField}
        validate={isRequired}
        submitError={submitError}
        readOnly={readOnly}
        onBlur={() => setReadOnly(true)}
        onFocus={() => setReadOnly(false)}
        autocomplete='new-password'
      />
      <InformationBox type='important'>
        You need to scroll down and hit ‘Save details’ for your new email to be saved
      </InformationBox>
    </FormItem>
  );
};

export default UpdateEmailInput;
