import { SET_OPTIMIZELY_ATTRIBUTE, ConfigActionTypes } from './types';



export const setOptimiselyAttribute = (key: string, value: string): ConfigActionTypes => ({
  type: SET_OPTIMIZELY_ATTRIBUTE,
  data:{
    [key]: value
  }
})

export type ConfigActions = ReturnType<typeof setOptimiselyAttribute>;
