import React, { ReactNode } from 'react';
import { TwoColumnsLayout } from 'components/templates';
import { Separator } from '@rentecarlo/component-library';
import { Container, ElementsContainer, Title } from './assets/styles';

interface SectionProps {
  title?: string;
  bottomBorder?: boolean;
  firstColumnWidth?: number;
  marginTop?: string;
  children: ReactNode;
  reduceBorderSize?: string;
}

const Section: React.FC<SectionProps> = ({
  title,
  bottomBorder,
  children,
  firstColumnWidth,
  marginTop,
  reduceBorderSize,
}) => {
  return (
    <Container marginTop={marginTop}>
      {title && <Title>{title}</Title>}
      <ElementsContainer>
        <TwoColumnsLayout leftColumnWidth={firstColumnWidth}>{children}</TwoColumnsLayout>
      </ElementsContainer>
      {bottomBorder && <Separator reduceSize={reduceBorderSize} />}
    </Container>
  );
};

export default Section;
