import React, { ReactNode } from 'react';
import { Title } from './assets/styles';

interface Props {
  children: ReactNode;
  id: string;
}

const PageTitle: React.FC<Props> = (props: Props) => {
  const { children, id } = props;
  return <Title id={id}>{children}</Title>;
};

export default PageTitle;
