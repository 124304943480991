import React, { ReactNode } from 'react';
import { Container } from './assets/styles';

type Props = {
  children: ReactNode;
  leftColumnWidth?: number;
};

const TwoColumnsLayout: React.FC<Props> = (props: Props) => {
  const { children, leftColumnWidth = 50 } = props;
  return <Container leftWidth={leftColumnWidth}>{children}</Container>;
};

export default TwoColumnsLayout;
