import styled, { css } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MediaQuery.desktop(css`
    margin-bottom: 56px;
  `)}
`;

export const Container = styled.div`
  width: 100%;
  margin-top: 48px;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${MediaQuery.mobile(css`
    margin-top: 32px;
  `)}
`;

export const BackButtonContainer = styled.div`
  width: 68px;
  padding-bottom: 48px;
`;

export const AmountText = styled.span`
  font-weight: bold;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 288px;

  & > div {
    margin-bottom: 21px;
    width: 100%;
  }
`;

export const ContentSection = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 24px;

  & > div:nth-child(even) {
    width: 288px;
    ${MediaQuery.mobile(css`
      width: 100%;
    `)}
  }

  & > div:nth-child(odd) {
    margin-right: 24px;
    width: 400px;
    ${MediaQuery.mobile(css`
      width: 100%;
    `)}
  }

  ${MediaQuery.mobile(css`
    flex-direction: column;
  `)}
`;

export const DetailedPurchaseContainer = styled.div`
  ${MediaQuery.mobile(css`
    width: 100%;
  `)}
`;

export const BannerText = styled.span`
  font-family: proxima-soft;
  font-size: 20px;
`;

export const BannerStatusText = styled.span`
  font-family: proxima-soft;
  font-size: 20px;
  font-weight: bold;
  padding-left: 6px;
`;

export const Loader = styled.img`
  width: 30px;
  height: 30px;
  margin: 0 30px 0 20px;
`;

export const InformationText = styled.span`
  color: ${({ theme }) => theme.textPrimary};
`;

export const AppLinksContainer = styled.div`
  margin-top: 24px;
`;
