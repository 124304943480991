import React, { ReactNode } from 'react';
import { Container, FormChildrenWrapper, FormItemLabel } from './assets/styles';

interface Props {
  children: ReactNode;
  label: string;
  bottomMargin?: string;
}

const FormItem: React.FC<Props> = ({ children, label, bottomMargin }) => (
  <Container bottomMargin={bottomMargin}>
    <FormItemLabel>{label}</FormItemLabel>
    <FormChildrenWrapper>{children}</FormChildrenWrapper>
  </Container>
);

export default FormItem;
