import { SagaIterator, Task } from 'redux-saga';
import { CognitoClient } from '@rentecarlo/node-amplify-client';
import * as Sentry from '@sentry/react';
import { call, cancel, fork, put, take, takeEvery, select } from 'redux-saga/effects';
import {
  AccountClient,
  buildRequestUrl,
  Client,
  RequestTypes,
  eraseCookie,
  ReferralsClient,
  optimizelyClient,
  BackendClient,
} from 'services';
import { LicenceTypes } from 'types/Customer';
import { Address, OccupationTypes } from '@rentecarlo/component-library';
import { AppState } from 'state';
import { actionCreators } from './actionCreators';
import * as actionTypes from './actionTypes';

/* eslint-disable camelcase */
export interface ProfileResponse {
  uuid: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  licence_type?: LicenceTypes;
  licence_number: string;
  occupation_type: OccupationTypes;
  occupation: string;
  can_edit: boolean;
  email: string;
  phone_number: string;
  email_consent: boolean;
  phone_consent: boolean;
  admiral_marketing_consent: boolean;
  addresses: ResponseAddressV3[];
  loading: boolean;
}

interface ResponseAddressV3 {
  line1: string;
  line2: string;
  county: string;
  flat_no: string;
  house_name: string;
  house_no: string;
  postcode: string;
  road: string;
  town: string;
}

interface ProfileTransform {
  uuid: string;
  personalDetails: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    licenceType?: LicenceTypes;
    licenceNumber: string;
  };
  contactDetails: {
    occupationType: OccupationTypes;
    occupation: string;
    email: string;
    phoneNumber: string;
    address: Address;
    canEdit: boolean;
  };
  notifications: {
    emailConsent: boolean;
    phoneConsent: boolean;
  };
  loading: boolean;
}

const getUserIdentity = (state: AppState) => state.config.optimizelyAttributes.user_identity;

function responseAddressToLocal(address: ResponseAddressV3): Address {
  return {
    UUID: '',
    AddressLine1: address.line1,
    AddressLine2: address.line2,
    County: address.county,
    FlatNumber: address.flat_no,
    HouseName: address.house_name,
    HouseNumber: address.house_no,
    Postcode: address.postcode,
    Preview: '',
    Road: address.road,
    Town: address.town,
    addressID: '',
    isManual: false,
  };
}

function responseToProfile(profile: ProfileResponse): ProfileTransform {
  return {
    uuid: profile.uuid,
    personalDetails: {
      firstName: profile.first_name,
      lastName: profile.last_name,
      dateOfBirth: profile.date_of_birth,
      licenceType: profile.licence_type,
      licenceNumber: profile.licence_number,
    },
    contactDetails: {
      occupationType: profile.occupation_type,
      occupation: profile.occupation,
      email: profile.email,
      phoneNumber: profile.phone_number,
      address: responseAddressToLocal(profile.addresses[0]),
      canEdit: profile.can_edit,
    },
    notifications: {
      emailConsent: profile.email_consent,
      phoneConsent: profile.phone_consent,
    },
    loading: profile.loading,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleError(error: any) {
  Sentry.withScope((scope) => {
    scope.setExtra('name', error.name);
    scope.setExtra('stacktrace', error.stack);
    scope.setLevel(Sentry.Severity.Error);
    Sentry.captureException(error);
  });
}

function* getAccount(): SagaIterator {
  try {
    yield call(() => optimizelyClient.onReady());

    const userIdentity = yield select(getUserIdentity);
    const accountBFFenabled = optimizelyClient.isFeatureEnabled(
      'TEMP_ENABLE_ACCOUNT_BFF',
      userIdentity,
      { user_identity: userIdentity },
    );

    let profile;
    if (accountBFFenabled) {
      profile = yield call(BackendClient.getCustomer);
    } else {
      const profileRequest = new Client.Builder().get(
        `${buildRequestUrl(RequestTypes.CUSTOMERS_API)}/customers/v3/me`,
      ).execute;

      const profileResponse = yield call(profileRequest);
      profile = responseToProfile(profileResponse);
    }

    yield put(actionCreators.retrieveAccountSuccess(profile));
  } catch (error) {
    handleError(error);

    yield put(actionCreators.retrieveAccountFailure(error as string));
  }
}

function* logout(): SagaIterator {
  try {
    // Try cognito first if that fails they could be a legacy user
    const session = yield call(() => CognitoClient.getCurrentSession());
    if (session.isValid) {
      yield call(() => CognitoClient.signOut());
    }
    yield put(actionCreators.logoutSuccess());
    eraseCookie('customer_uuid');
    window.location.href = `${buildRequestUrl(RequestTypes.AUTH)}/`;
  } catch (error) {
    try {
      yield call(AccountClient.logout);
      yield put(actionCreators.logoutSuccess());
      eraseCookie('customer_uuid');
      window.location.href = `${buildRequestUrl(RequestTypes.AUTH)}/`;
    } catch (legacyError) {
      handleError(legacyError);
      yield put(actionCreators.logoutFailure());
    }
  }
}

function* fetchBuyapowaSignature(): SagaIterator {
  try {
    const data = yield call(ReferralsClient.getBuyapowaSignature);
    yield put(actionCreators.setBuyapowaSignatureSuccess(data));
  } catch (e) {
    handleError(e);
  }
}

function* verify(): SagaIterator {
  try {
    // try cognito if that is not valid check legacy and log them out
    const session = yield call(() => CognitoClient.getCurrentSession());
    if (session.isValid) {
      yield put(actionCreators.loginVerified());
    } else {
      yield put(actionCreators.loginNotVerified());
    }
  } catch (error) {
    try {
      yield call(AccountClient.logout);
      yield put(actionCreators.logoutSuccess());
    } catch (legacyError) {
      handleError(legacyError);
      yield put(actionCreators.logoutFailure());
      yield put(actionCreators.loginNotVerified());
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* loginOrRestoreSession(action: { type: string }) {
  switch (action.type) {
    case actionTypes.LOGIN_VERIFIED:
      yield getAccount();
      break;
    case actionTypes.LOGOUT_FAILURE:
      break;
    default:
      throw new Error(`Unrecognised login action type: ${action.type}`);
  }
}

function* logoutOrReturnToLogin(action: { type: string }, loginTask: Task): SagaIterator {
  switch (action.type) {
    case actionTypes.LOGOUT_REQUEST:
      if (loginTask) cancel(loginTask);
      yield call(logout);
      yield put(actionCreators.customerInitiatedLogout());
      break;
    default:
      throw new Error(`Unrecognised logout action type: ${action.type}`);
  }
}

function* authFlow(): SagaIterator {
  while (true) {
    const loginAction = yield take([actionTypes.LOGIN_VERIFIED, actionTypes.LOGOUT_FAILURE]);
    const loginTask = yield fork(loginOrRestoreSession, loginAction);
    const logoutAction = yield take([actionTypes.LOGOUT_REQUEST]);
    yield fork(logoutOrReturnToLogin, logoutAction, loginTask);
  }
}

export default function* watchAccountSaga(): SagaIterator {
  yield fork(authFlow);
  yield call(verify);
  yield takeEvery(actionTypes.RETRIEVE_ACCOUNT_SUCCESS, fetchBuyapowaSignature);
}
