import { SagaIterator } from 'redux-saga';
import { fork } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { saga as notificationsSaga } from './notifications';
import { saga as profileSaga } from './profile';
import { saga as purchasesSaga } from './purchases';
import { runInit } from './init';

function* rootSaga(): SagaIterator {
  try {
    yield fork(runInit);
    yield fork(notificationsSaga);
    yield fork(profileSaga);
    yield fork(purchasesSaga);
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setExtra('Error', error);
      scope.setLevel(Sentry.Severity.Error);
      Sentry.captureMessage('An unhandled exception caused the root saga to fail');
    });
  }
}

export default rootSaga;
