import styled from 'styled-components';

type OptionalProps = {
  height?: number;
};

export const Spacer = styled.div<OptionalProps>`
  height: ${({ height }) => (height ? `${height}px` : '8px')};
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
  flex-shrink: 0;
  align-self: stretch;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: row;
`;

export const IncedentIconContainer = styled.div`
  display: flex;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 25px;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const StatusIcon = styled.img`
  height: 17px;
  width: auto;
`;
