import React from 'react';

const ChevronRight: React.FC = () => (
  <svg
    version='1.1'
    id='Capa_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 256 256'
  >
    <g>
      <g>
        <polygon points='79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128 		' />
      </g>
    </g>
  </svg>
);

export default ChevronRight;
