import styled from 'styled-components';

import { P } from '@rentecarlo/component-library';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.textPrimary};
  font-family: ${({ theme }) => theme.title};
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 30px;
  margin: 8px 0 16px;
`;

export const Text = styled(P).attrs({ fontSize: 14, lineHeight: 23 })``;

export const Bold = styled.span`
  font-weight: bold;
`;

export const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;

  margin: 24px 0 8px;
`;

export const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;

  background-color: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 4px;

  padding: 20px;

  margin-bottom: 32px;
`;

export const SubTitle = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.textPrimary};
  font-family: ${({ theme }) => theme.title};
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 20px;
`;

export const ListItem = styled.div`
  margin: -5px 0;
`;
