import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { actionCreators } from 'state/account';
import { AccountActions } from 'state/account/types';
import Breadcrumb from './Breadcrumb';

interface Props {
  toggleMenu: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch<AccountActions>): Props => ({
  toggleMenu: (): void => dispatch(actionCreators.toggleMenu()),
});

export default connect(null, mapDispatchToProps)(Breadcrumb);
