import { VeygoError } from '@rentecarlo/component-library';

import { CancelType } from 'types/Subscription';

import {
  PurchasesActions,
  ActivePolicies,
  UpcomingPolicies,
  EndedPolicies,
  CancellationResponse,
  ActiveSubscriptions,
  UpcomingSubscriptions,
  EndedSubscriptions,
} from './types';
import * as actionTypes from './actionTypes';

interface PurchasesReducerState {
  lastUpdate?: Date;
  loading: boolean;
  error: VeygoError | null;
  policies: ActivePolicies & UpcomingPolicies & EndedPolicies;
  subscriptions: ActiveSubscriptions & UpcomingSubscriptions & EndedSubscriptions;
  policyCancelSucceeded?: boolean;
}
export const initialState: PurchasesReducerState = {
  lastUpdate: undefined,
  error: null,
  loading: false,
  policies: {
    active: [],
    upcoming: [],
    ended: [],
  },
  subscriptions: {
    active: [],
    upcoming: [],
    ended: [],
  },
  policyCancelSucceeded: false,
};

const movePolicyToCancelled = (
  state: PurchasesReducerState,
  cancellationData: CancellationResponse,
): PurchasesReducerState => {
  const endedPolicies = [...state.policies.ended];
  const activePolicies = [
    ...state.policies.active.filter((policy) => {
      if (policy.uuid !== cancellationData.uuid) {
        return policy;
      }

      endedPolicies.push({
        ...policy,
        cancelled: true,
        cancellationDateTime: cancellationData.cancellationDateTime,
      });
      return false;
    }),
  ];
  const upcomingPolicies = [
    ...state.policies.upcoming.filter((policy) => {
      if (policy.uuid !== cancellationData.uuid) {
        return policy;
      }

      endedPolicies.push({
        ...policy,
        cancelled: true,
        cancellationDateTime: cancellationData.cancellationDateTime,
      });
      return false;
    }),
  ];

  return {
    ...state,
    policies: {
      active: activePolicies,
      upcoming: upcomingPolicies,
      ended: endedPolicies,
    },
  };
};

const updateRefundAmount = (
  policies:
    | PurchasesReducerState['policies']['active']
    | PurchasesReducerState['policies']['upcoming'],
  uuid: string,
  amount: string,
) => {
  const policyArray = policies;
  const index = policies.findIndex((policy) => policy.uuid === uuid);
  if (index !== -1) {
    policyArray[index].refundAmount = amount;
  }

  return policyArray;
};

const updateSubsRefundAmount = (
  subscriptions:
    | PurchasesReducerState['subscriptions']['active']
    | PurchasesReducerState['subscriptions']['upcoming'],
  subsUuid: string,
  amount: string,
) => {
  const subsArray = subscriptions;
  const index = subscriptions.findIndex((subscription) => subscription.uuid === subsUuid);
  if (index !== -1) {
    subsArray[index].refundAmount = amount;
  }
  return subsArray;
};

const reducer = (
  state: PurchasesReducerState = initialState,
  action: PurchasesActions,
): PurchasesReducerState => {
  switch (action.type) {
    case actionTypes.RETRIEVE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.RETRIEVE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptions: action.subscriptions,
        lastUpdate: new Date(),
      };
    case actionTypes.RETRIEVE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.RETRIEVE_USER_POLICIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.RETRIEVE_USER_POLICIES_SUCCESS:
      return {
        ...state,
        loading: false,
        policies: action.policies,
        lastUpdate: new Date(),
      };
    case actionTypes.RETRIEVE_USER_POLICIES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.GET_REFUND_AMOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_REFUND_AMOUNT_SUCCESS: {
      return {
        ...state,
        policies: {
          active: updateRefundAmount(state.policies.active, action.uuid, action.amount),
          upcoming: updateRefundAmount(state.policies.upcoming, action.uuid, action.amount),
          ended: state.policies.ended,
        },
        loading: false,
      };
    }
    case actionTypes.CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        policyCancelSucceeded: action.cancellationType === CancelType.IMMEDIATE || false,
      };
    case actionTypes.CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.CANCEL_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CANCEL_POLICY_SUCCESS:
      return {
        ...movePolicyToCancelled(state, action.cancellationData),
        loading: false,
        lastUpdate: new Date(),
        policyCancelSucceeded: true,
      };
    case actionTypes.CANCEL_POLICY_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    case actionTypes.GET_REFUND_AMOUNT_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    case actionTypes.CLEAR_PURCHASES_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    case actionTypes.CLEAR_CANCEL_SUCCESS_STATE:
      return {
        ...state,
        policyCancelSucceeded: false,
      };
    case actionTypes.GET_SUBS_REFUND_AMOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SUBS_REFUND_AMOUNT_SUCCESS: {
      return {
        ...state,
        subscriptions: {
          active: updateSubsRefundAmount(
            state.subscriptions.active,
            action.subsUuid,
            action.amount,
          ),
          upcoming: updateSubsRefundAmount(
            state.subscriptions.upcoming,
            action.subsUuid,
            action.amount,
          ),
          ended: state.subscriptions.ended,
        },
        loading: false,
      };
    }
    case actionTypes.GET_SUBS_REFUND_AMOUNT_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
