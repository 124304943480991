import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionCreators } from 'state/account';
import { AppState } from 'state';
import { AccountActions } from 'state/account/types';
import LoginStatus from './LoginStatus';

interface DispatchProps {
  logoutRequest: () => void;
}

interface StateProps {
  firstName: string;
  loggedIn: boolean;
}

interface SidebarContainer extends DispatchProps {
  firstName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history?: any;
}

const LoginStatusContainer: React.FC<SidebarContainer> = (props) => {
  const { firstName, logoutRequest } = props;
  return <LoginStatus firstName={firstName} onLogoutHandler={logoutRequest} />;
};

const mapDispatchToProps = (dispatch: Dispatch<AccountActions>): DispatchProps => ({
  logoutRequest: (): void => dispatch(actionCreators.logoutRequest()),
});

const mapStateToProps = (state: AppState): StateProps => ({
  firstName: state.profile.personalDetails.firstName,
  loggedIn: state.account.loggedIn,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)(withRouter<any, any>(LoginStatusContainer));
