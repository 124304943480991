/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Styled from 'styled-components';

import { Status } from '../SidebarItem';

interface SidebarContainer {
  active?: boolean;
  onClick: () => void;
  status: Status;
}

export const SidebarItemContainer = Styled.div<SidebarContainer>`
  height: 48px;
  display: flex;
  border-left: ${({ theme, active }) =>
    `4px solid ${active ? theme.primary : theme.backgroundPrimary}`};
  margin-top: 16px;
  cursor: pointer;

  opacity: ${({ status }) => (status === Status.ACTIVE ? 1 : 0.5)};

  &:hover {
    background-color: ${({ theme }) => theme.backgroundSecondary};
  }
`;

export const SidebarLabelWrapper = Styled.div`
  flex: 0.7;
  letter-spacing: 0.32px;
  line-height: 19px;
  display: flex;
  align-items: center;
`;

export const SidebarItemLabel = Styled.span<{ active?: boolean }>`
  height: 19px;
  width: 72px;
  color: ${({ theme, active }) => (active ? theme.primary : theme.textPrimary)};
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: left;
`;

export const SidebarIconWrapper = Styled.div`
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
`;

export const SidebarIconSpacer = Styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SidebarIcon = Styled.img`
  height: 40px;
`;
