import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  PageModal,
  ToggleButtonGroup,
  IconList,
  TickIcon,
  Button,
} from '@rentecarlo/component-library';

import { RootState } from 'state/reducer';
import { actionCreators as AccountActions } from 'state/account';

import { useOptimizelyTrack } from 'utils/hooks';

import {
  Container,
  Title,
  SelectorContainer,
  ProductInfoContainer,
  SubTitle,
  ListItem,
  Bold,
  Text,
} from './assets/styles';

enum DriverType {
  OWNER = 'owner',
  BORROWER = 'borrower',
}

const productInfo = {
  [DriverType.OWNER]: {
    title: 'New Driver insurance',
    items: [
      <Text>
        <Bold>10% discount automatically applied as you used our learner driver insurance</Bold>
      </Text>,
      <Text>Every 3 months, review your price – drive safely and save up to 20%!</Text>,
      <Text>
        Track your driving via a separate telematics app on your smartphone - No black box!
      </Text>,
      <Text>Start building your No Claims Bonus, immediately!</Text>,
    ],
    cta: (sendEvent: (eventType: string) => void): React.ReactNode => (
      <Button
        id='goToNewDriver'
        onClick={() => {
          sendEvent('cross_sell_cta_owner');
          window.location.href = 'https://www.veygo.com/new-driver/?congrats=new-driver';
        }}
      >
        Get New Driver Insurance
      </Button>
    ),
  },
  [DriverType.BORROWER]: {
    title: 'Flexible car insurance',
    items: [
      <Text>
        <Bold>10% discount automatically applied as you used our learner driver insurance</Bold>
      </Text>,
      <Text>Pay-as-you-go. Pay by the hour, day or month</Text>,
      <Text>Peace of mind. The owner’s no claim bonus won’t be affected if you make a claim.</Text>,
    ],
    cta: (sendEvent: (eventType: string) => void): React.ReactNode => (
      <Button
        id='goToTemporaryInsurance'
        onClick={() => {
          sendEvent('cross_sell_cta_borrower');
          window.location.href =
            'https://www.veygo.com/temporary-car-insurance/?congrats=temporary';
        }}
      >
        Get Temporary Insurance
      </Button>
    ),
  },
};

interface ProductInfoProps {
  driverType: DriverType;
}

const ProductInfo: React.FC<ProductInfoProps> = ({ driverType }) => {
  const { title, items, cta } = productInfo[driverType];

  const sendEvent = useOptimizelyTrack();

  return (
    <>
      <ProductInfoContainer>
        <SubTitle>{title}:</SubTitle>
        <IconList
          id='product-info-list'
          icon={TickIcon}
          items={items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem key={`${index}-product-info`}>
              <Text>{item}</Text>
            </ListItem>
          ))}
        />
      </ProductInfoContainer>
      {cta(sendEvent)}
    </>
  );
};

const CrossSellModal: React.FC = () => {
  const dispatch = useDispatch();

  const { showModal, isDriverOwner, isSubscription } = useSelector(
    (state: RootState) => state.account.crossSell,
  );

  const firstName = useSelector((state: RootState) => state.profile.personalDetails.firstName);

  const [driverType, setDriverType] = useState(DriverType.OWNER);

  const sendEvent = useOptimizelyTrack();

  useEffect(() => {
    if (isDriverOwner !== undefined) {
      const initialDriverType = isDriverOwner ? DriverType.OWNER : DriverType.BORROWER;
      setDriverType(initialDriverType);

      if (isSubscription !== undefined) {
        const productType = isSubscription ? 'subscription' : 'one_off';

        sendEvent(`cross_sell_init_${productType}_${initialDriverType.valueOf()}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDriverOwner, isSubscription]);

  return (
    <PageModal
      show={showModal}
      close={() => {
        dispatch(AccountActions.setCrossSellModalVisibility(false));
        sendEvent(`cross_sell_close`);
      }}
      closeText='Back'
    >
      <Container>
        <Title>Congratulations {firstName} on Passing Your Driving Test!</Title>
        <Text>
          Your learner driver policy is now cancelled. Now that you’ve levelled up, you can stay on
          the road with Veygo. Get <Bold>New Driver Insurance</Bold> for your own car or{' '}
          <Bold>Pay-as-you-go cover</Bold> to borrow someone else’s. Explore your flexible options
          below.
        </Text>
        <SelectorContainer>
          <Text>
            <Bold>Tell us about the car you will be driving:</Bold>
          </Text>
          <ToggleButtonGroup
            value={driverType}
            groupName='cancelType'
            onSelect={(value: string) => {
              setDriverType(value as DriverType);
              sendEvent(`cross_sell_toggle_${value}`);
            }}
            items={{
              left: { text: 'I own it', value: DriverType.OWNER },
              right: { text: "I'm borrowing it", value: DriverType.BORROWER },
            }}
          />
        </SelectorContainer>
        <ProductInfo driverType={driverType} />
      </Container>
    </PageModal>
  );
};

export default CrossSellModal;
