import Styled from 'styled-components';
import AvatarIcon from './account-icon-black.svg';

export const Container = Styled.div`
    display: flex;
    flex-direction: column;
`;

export const TextWrapper = Styled.div`
	color: #757575;
	font-size: 12px;
    text-align: center;
    margin-bottom: 8px;
`;

export const AvatarWrapper = Styled.div`
    display: flex;
    justify-content: center;
    color: #364344;
    font-size: 16px;
    font-weight: bold;
`;

export const AvatarImage = Styled.img.attrs({
  src: AvatarIcon,
})`
    margin-right: 6px;
`;
