import { NotificationState } from 'state/notifications/types';
import Client from './client';
import { createCustomerRequestUrl } from './clientUtils';

interface ProfileRemoteTransform {
  marketing_preferences: {
    email_consent: boolean;
    phone_consent: boolean;
  };
}

const localFieldsToRemote = (data: NotificationState): ProfileRemoteTransform => ({
  marketing_preferences: {
    email_consent: data.emailConsent,
    phone_consent: data.phoneConsent,
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const updatePreferences = (data: NotificationState, endpoint: string) => {
  return new Client.Builder()
    .put(`${createCustomerRequestUrl(endpoint)}`)
    .data(localFieldsToRemote(data))
    .execute();
};

export const NotificationsClient = {
  updatePreferences,
};
