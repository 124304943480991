import { Avatar, PillButton } from 'components/atoms';
import React from 'react';
import { withTheme } from 'styled-components';
import { Theme } from 'utils/theme';
import { ButtonContainer, ButtonWrapper, Container } from './assets/styles';

interface Props {
  theme: Theme;
  firstName: string;
  onLogoutHandler?: () => void;
}

const LoginStatus: React.FC<Props> = (props) => {
  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  const { theme, firstName, onLogoutHandler = (): void => {} } = props;

  return (
    <Container>
      <Avatar name={firstName} />
      <ButtonContainer>
        <ButtonWrapper>
          <PillButton
            id='logoutButton'
            onClick={onLogoutHandler}
            textColor={theme.linkPrimary}
            hoverTextColor={theme.inputHover}
          >
            Logout
          </PillButton>
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  );
};

export default withTheme<React.FC<Props>>(LoginStatus);
