import React from 'react';
import { InformationBox } from 'components/atoms';
import { Container } from './assets/styles';

interface NotificationErrorBoxInterface {
  isVisible: boolean;
}

export const NotificationErrorBox: React.FC<NotificationErrorBoxInterface> = (props) => {
  const { isVisible } = props;
  return isVisible ? (
    <Container>
      <InformationBox type='error'>
        Oops! Sorry, something's gone wrong. If you need further assistance, please{' '}
        <a href='https://www.veygo.com/contact'>contact our customer service team</a> who will be{' '}
        happy to help.
      </InformationBox>
    </Container>
  ) : null;
};
