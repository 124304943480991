import Styled from 'styled-components';

export const Container = Styled.div`
`;

export const ButtonContainer = Styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = Styled.div`
  width: 101px;
`;
