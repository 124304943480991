import React from 'react';
import styled, { css } from 'styled-components';
import { Moment } from 'moment';
import calendarIcon from '../../../assets/calendar-icn.svg';

interface TextProps {
  active?: boolean;
  padding?: string;
}

interface DateTimeInputProps {
  onCalendarClick: () => void;
  label: string;
  dateId: string;
  date: Moment | null;
  time: string;
  timeId: string;
  calenderIconId: string;
}

const InputRow = styled.div`
  background-color: ${({ theme }): string => theme.white};
  overflow: hidden;
  border: 0.5px solid ${({ theme }): string => theme.fieldSecondary};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  height: 65px;
  width: 100%;
  cursor: pointer;
  ${css`
    :first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    :last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  `}
`;

const Date = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

const Time = styled.div`
  align-items: center;
  border-left: 0.5px solid ${({ theme }): string => theme.fieldSecondary};
  border-right: 0.5px solid ${({ theme }): string => theme.fieldSecondary};
  display: flex;
  justify-content: center;
  width: 100px;
`;

const Calendar = styled.div`
  padding: 22px;
`;
const Icon = styled.img`
  height: 20px;
  width: 20px;
`;

const Text = styled.span<TextProps>`
  color: ${({ theme, ...props }): string =>
    props.active ? theme.outerSpace : theme.textSecondary};
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  font-family: proxima-soft;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  padding-left: ${(props) => (props.padding ? props.padding : '14px')};
  padding-right: ${(props) => (props.padding ? props.padding : '14px')};
`;

const DateTimeInput: React.FC<DateTimeInputProps> = ({
  onCalendarClick,
  dateId,
  date,
  time,
  timeId,
  calenderIconId,
}) => (
  <InputRow onClick={onCalendarClick}>
    <Date id={dateId}>
      <Text id='durationOfCover-text-date' active={Boolean(date)} padding='24px'>
        {date ? date.format('ddd, Do MMM Y') : 'DD / MM / YY'}
      </Text>
    </Date>
    <Time id={timeId}>
      <Text id='durationOfCover-text-time' active={Boolean(time)} padding='14px'>
        {time || 'HH : MM'}
      </Text>
    </Time>
    <Calendar id={calenderIconId}>
      <Icon src={calendarIcon} alt='Open Calendar' />
    </Calendar>
  </InputRow>
);

DateTimeInput.defaultProps = {
  label: 'To',
  date: null,
  time: 'HH : MM',
};

export default DateTimeInput;
