import React, { useState } from 'react';
import * as Sentry from '@sentry/react';

import { buildRequestUrl, RequestTypes } from 'services/clientUtils';
import { Address } from '@rentecarlo/component-library';

interface State {
  state: {
    foundAddresses: Address[];
    addressError: string | undefined | React.ReactElement;
  };
  getAddresses(postcodeValue: string): void;
  resetErrors: () => void;
}

const getErrorMessage = (message: string): React.ReactElement | string => {
  if (message === 'Address Not Found') {
    return (
      <div>
        Oops, we don't recognise that postcode!{' '}
        <a href='https://www.veygo.com/contact/'>Contact us</a> and we'll do our best to help
      </div>
    );
  }

  return message;
};

const useAddressLookup = (): State => {
  // Hook state
  const [foundAddresses, setFoundAddresses] = useState<Address[]>([]);
  const [addressError, setAddressError] = useState<string | React.ReactElement>();

  const handleErrors = (response: Response) => {
    if (response.ok) {
      return response.json();
    }

    return response.json().then((body) => {
      setAddressError(getErrorMessage(body.message));
      return [];
    });
  };

  const resetErrors = () => {
    setAddressError(undefined);
  };

  // Request to retrieve addresses
  const getAddresses = (postcodeValue: string): void => {
    const postcodeFormatted = postcodeValue.replace(/ /g, '').toUpperCase();
    fetch(`${buildRequestUrl(RequestTypes.CSI)}/lookup/address/${postcodeFormatted}`)
      .then((response) => handleErrors(response))
      .then((response) => {
        setFoundAddresses(response);
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('name', error.name);
          scope.setExtra('stacktrace', error.stack);
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureException(error);
        });
      });
  };

  // Only exposes what is required by the component
  return {
    state: { foundAddresses, addressError },
    getAddresses,
    resetErrors,
  };
};

export default useAddressLookup;
