import React from 'react';
import { ThemeUtils } from '@rentecarlo/component-library';
import { ContactDetailsInput } from 'components/organisms';
import { Column, Container } from './assets/styles';

interface ContactDetailsProps {
  text?: string;
  theme: ThemeUtils.Theme;
}

const ContactDetails: React.FC<ContactDetailsProps> = (props: ContactDetailsProps) => {
  const { theme } = props;
  return (
    <Column>
      <Container>
        <ContactDetailsInput theme={theme} />
      </Container>
    </Column>
  );
};

export default ContactDetails;
