import React from 'react';
import { PopUpModal, ThemeUtils, TextLink } from '@rentecarlo/component-library';
import { SaveDetails } from 'assets';

interface SaveModalProps {
  theme: ThemeUtils.Theme;
  show: boolean;
  close: () => void;
  onClick: () => void;
}

const SaveModal: React.FC<SaveModalProps> = ({ theme, show, onClick, close }) => {
  return (
    <PopUpModal
      data-testid='save-modal'
      id='save-modal'
      visible={show}
      theme={theme}
      maxWidth='480px'
      image={SaveDetails}
      title='Ready to go?'
      firstButtonId='save'
      secondButtonId='cancel'
      firstButtonChildren='Confirm and save changes'
      secondButtonChildren="Don't save and go back"
      firstButtonOnClick={onClick}
      secondButtonOnClick={close}
    >
      Once you’ve saved your details, you won’t be able to edit your account for another{' '}
      <b>30 days.</b>
      <br /> <br />
      It’s also worth mentioning, this <b>will not</b> update any active or upcoming policies. To do
      that you need to <TextLink href='https://www.veygo.com/contact'>contact us</TextLink>.
      <br /> <br />
      If that sounds good, then let’s get going…
    </PopUpModal>
  );
};

export default SaveModal;
