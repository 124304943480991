import Styled from 'styled-components';

export const Column = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = Styled.div`
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
`;
