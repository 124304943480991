import { AccountActions } from './types';
import * as actionTypes from './actionTypes';

interface AccountReducerState {
  error: boolean;
  loginChecked: boolean;
  loggedIn: boolean;
  uuid: string;
  customerInitiatedLogout: boolean;
  showMenu: boolean;
  crossSell: {
    showModal: boolean;
    isDriverOwner?: boolean;
    isSubscription?: boolean;
  };
  buyapowaSignature: string;
}

export const initialState: AccountReducerState = {
  error: false,
  loggedIn: false,
  loginChecked: false,
  uuid: '',
  customerInitiatedLogout: false,
  showMenu: false,
  crossSell: {
    showModal: false,
    isDriverOwner: undefined,
    isSubscription: undefined,
  },
  buyapowaSignature: '',
};

const reducer = (
  state: AccountReducerState = initialState,
  action: AccountActions,
): AccountReducerState => {
  switch (action.type) {
    case actionTypes.LOGIN_NOT_VERIFIED:
      return {
        ...initialState,
        loggedIn: false,
        loginChecked: true,
      };
    case actionTypes.LOGIN_VERIFIED:
      return {
        ...initialState,
        loggedIn: true,
        loginChecked: true,
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...initialState,
        loginChecked: true,
      };
    case actionTypes.RETRIEVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        uuid: action.data.uuid,
      };
    case actionTypes.TOGGLE_MENU:
      return {
        ...state,
        showMenu: !state.showMenu,
      };
    case actionTypes.CUSTOMER_INITIATED_LOGOUT:
      return {
        ...state,
        customerInitiatedLogout: true,
      };
    case actionTypes.SET_CROSS_SELL_MODAL_VISIBILITY:
      return {
        ...state,
        crossSell: {
          showModal: action.showModal,
          isDriverOwner: action.isDriverOwner,
          isSubscription: action.isSubscription,
        },
      };
    case actionTypes.SET_BUYAPOWA_SIGNATURE_SUCCESS:
      return {
        ...state,
        buyapowaSignature: action.buyapowaSignature,
      };
    default:
      return state;
  }
};

export default reducer;
