/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Styled from 'styled-components';
import { Theme } from 'utils/theme';

import WarningIcon from './error.gif';

const borderStyle = (type: string, theme: Theme): string => {
  switch (type) {
    case 'error':
      return theme.errorPrimary;
    default:
      return theme.successPrimary;
  }
};

interface InformatiomBox {
  theme: Theme;
  type: string;
}

export const Container = Styled.div<InformatiomBox>`
  min-height: 48px;
  width: 100%;
  border: ${({ theme, type }) => `2px solid ${borderStyle(type, theme)}`};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.backgroundPrimary};
`;

export const Wrapper = Styled.div`
  position: relative;
  line-height: 17px;
`;

export const ErrorIcon = Styled.img.attrs(() => ({
  src: WarningIcon,
}))`
  height: 16px;
  width: 16px;
  position: absolute;
  left: 20px;
  top: 0px;
`;

export const InfoText = Styled.div`
  margin: 16px 16px 16px 48px;
  font-size: 14px;
  color: #364344;
`;
