import styled from 'styled-components';
import { WarningIcon } from 'assets';

type OptionalProps = {
  height?: number;
};

export const Spacer = styled.div<OptionalProps>`
  height: ${({ height }) => (height ? `${height}px` : '8px')};
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
`;

export const Warning = styled.img.attrs(() => ({
  src: WarningIcon,
  alt: 'warning-icon',
}))`
  height: 40px;
  width: 40px;
`;
