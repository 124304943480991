/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer, StoreEnhancer } from 'redux';
import * as actionTypes from './actionTypes';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const resetStoreOnLogoutEnhancer = (createStore: any) => (
  reducer: Reducer,
  initialState: Record<string, unknown>,
  enhancer: StoreEnhancer,
) => {
  const enhancedReducer = (state: any, action: any) => {
    if (action.type === actionTypes.LOGOUT_SUCCESS) {
      return reducer(undefined, action);
    }
    return reducer(state, action);
  };
  return createStore(enhancedReducer, initialState, enhancer);
};

const enhancer = {
  resetStoreOnLogoutEnhancer,
};

export default enhancer;
