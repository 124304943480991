import { Policy, Subscription, VeygoError } from '@rentecarlo/component-library';

import { CancelType } from 'types/Subscription';

import * as actionTypes from './actionTypes';
import {
  ActivePolicies,
  UpcomingPolicies,
  EndedPolicies,
  ActiveSubscriptions,
  UpcomingSubscriptions,
  EndedSubscriptions,
  CancelSubscription,
  CancelSubscriptionSuccess,
  CancelSubscriptionFailure,
  RetrieveSubscription,
  RetrieveUserPolicies,
  RetrieveUserPoliciesFailure,
  CancelPolicy,
  CancelPolicySuccess,
  CancelPolicyFailure,
  CancellationResponse,
  RetrieveSubscriptionsSuccess,
  RetrieveSubscriptionsFailure,
  RetrieveUserPoliciesSuccess,
  GetRefundAmountRequest,
  GetRefundAmountFailure,
  GetRefundAmountSuccess,
  ClearPurchasesError,
  ClearCancelSuccessState,
  GetSubsRefundAmountRequest,
  GetSubsRefundAmountFailure,
  GetSubsRefundAmountSuccess,
} from './types';

const retrieveSubscriptions = (): RetrieveSubscription => ({
  type: actionTypes.RETRIEVE_SUBSCRIPTION_REQUEST,
});

const retrieveSubscriptionsSuccess = (
  subscriptions: ActiveSubscriptions & UpcomingSubscriptions & EndedSubscriptions,
): RetrieveSubscriptionsSuccess => ({
  subscriptions,
  type: actionTypes.RETRIEVE_SUBSCRIPTION_SUCCESS,
});

const retrieveSubscriptionsFailure = (error: VeygoError): RetrieveSubscriptionsFailure => ({
  error,
  type: actionTypes.RETRIEVE_SUBSCRIPTION_FAILURE,
});

const cancelSubscription = (
  subscription: Subscription,
  cancellationReason: string,
  cancelType: CancelType,
  comment: string,
): CancelSubscription => ({
  type: actionTypes.CANCEL_SUBSCRIPTION_REQUEST,
  subscription,
  cancellationReason,
  cancelType,
  comment,
});

const cancelSubscriptionSuccess = (cancellationType: CancelType): CancelSubscriptionSuccess => ({
  type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
  cancellationType,
});

const cancelSubscriptionFailure = (error: VeygoError): CancelSubscriptionFailure => ({
  type: actionTypes.CANCEL_SUBSCRIPTION_FAILURE,
  error,
});

const retrieveUserPolicies = (): RetrieveUserPolicies => ({
  type: actionTypes.RETRIEVE_USER_POLICIES_REQUEST,
});

const retrieveUserPoliciesSuccess = (
  policies: ActivePolicies & UpcomingPolicies & EndedPolicies,
): RetrieveUserPoliciesSuccess => ({
  policies,
  type: actionTypes.RETRIEVE_USER_POLICIES_SUCCESS,
});

const retrieveUserPoliciesFailure = (error: VeygoError): RetrieveUserPoliciesFailure => ({
  error,
  type: actionTypes.RETRIEVE_USER_POLICIES_FAILURE,
});

const cancelPolicy = (
  policy: Policy,
  cancellationReason: string,
  comment: string,
): CancelPolicy => ({
  policy,
  cancellationReason,
  comment,
  type: actionTypes.CANCEL_POLICY_REQUEST,
});

const cancelPolicySuccess = (cancellationData: CancellationResponse): CancelPolicySuccess => ({
  cancellationData,
  type: actionTypes.CANCEL_POLICY_SUCCESS,
});

const cancelPolicyFailure = (error: VeygoError): CancelPolicyFailure => ({
  error,
  type: actionTypes.CANCEL_POLICY_FAILURE,
});

export const getRefundAmountRequest = (uuid: string): GetRefundAmountRequest => ({
  type: actionTypes.GET_REFUND_AMOUNT_REQUEST,
  uuid,
});

export const getRefundAmountFailure = (error: VeygoError): GetRefundAmountFailure => ({
  type: actionTypes.GET_REFUND_AMOUNT_FAILURE,
  error,
});

export const getRefundAmountSuccess = (amount: string, uuid: string): GetRefundAmountSuccess => ({
  type: actionTypes.GET_REFUND_AMOUNT_SUCCESS,
  amount,
  uuid,
});

export const getSubsRefundAmountRequest = (
  policyUuid: string,
  subsUuid: string,
): GetSubsRefundAmountRequest => ({
  type: actionTypes.GET_SUBS_REFUND_AMOUNT_REQUEST,
  policyUuid,
  subsUuid,
});

export const getSubsRefundAmountFailure = (error: VeygoError): GetSubsRefundAmountFailure => ({
  type: actionTypes.GET_SUBS_REFUND_AMOUNT_FAILURE,
  error,
});

export const getSubsRefundAmountSuccess = (
  amount: string,
  subsUuid: string,
): GetSubsRefundAmountSuccess => ({
  type: actionTypes.GET_SUBS_REFUND_AMOUNT_SUCCESS,
  amount,
  subsUuid,
});

export const clearPurchasesError = (): ClearPurchasesError => ({
  type: actionTypes.CLEAR_PURCHASES_ERROR,
});

export const clearCancelSuccessState = (): ClearCancelSuccessState => ({
  type: actionTypes.CLEAR_CANCEL_SUCCESS_STATE,
});

export const actionCreators = {
  retrieveSubscriptions,
  retrieveSubscriptionsSuccess,
  retrieveSubscriptionsFailure,
  cancelSubscription,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  retrieveUserPolicies,
  retrieveUserPoliciesSuccess,
  retrieveUserPoliciesFailure,
  cancelPolicy,
  cancelPolicySuccess,
  cancelPolicyFailure,
  getRefundAmountRequest,
  getRefundAmountSuccess,
  getRefundAmountFailure,
  clearPurchasesError,
  clearCancelSuccessState,
  getSubsRefundAmountRequest,
  getSubsRefundAmountSuccess,
  getSubsRefundAmountFailure,
};
