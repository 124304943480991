import { ContactDetailsState, ProfileActions, UpdateContactDetails } from './types';
import * as actionTypes from './actionTypes';

export const updateContactDetailsRequest = (
  contactDetails: UpdateContactDetails,
): ProfileActions => ({
  type: actionTypes.UPDATE_CONTACT_DETAILS_REQUEST,
  contactDetails,
});

export const updateContactDetailsFailure = (error: string): ProfileActions => ({
  error,
  type: actionTypes.UPDATE_CONTACT_DETAILS_FAILURE,
});

export const updateContactDetailsSuccess = (
  contactDetails: ContactDetailsState,
): ProfileActions => ({
  type: actionTypes.UPDATE_CONTACT_DETAILS_SUCCESS,
  contactDetails,
});

export const actionCreators = {
  updateContactDetailsRequest,
  updateContactDetailsFailure,
  updateContactDetailsSuccess,
};
