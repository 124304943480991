export interface ConfigState{
  optimizelyAttributes: Record<string, string>;
}

export const SET_OPTIMIZELY_ATTRIBUTE = 'SET_OPTIMIZELY_ATTRIBUTE';

interface SetOptimizelyAttributeAction {
  type: typeof SET_OPTIMIZELY_ATTRIBUTE;
  data: Record<string, string>
}

export type ConfigActionTypes = SetOptimizelyAttributeAction;
