import { useSelector } from 'react-redux';
import { useDecision, OptimizelyDecision } from '@optimizely/react-sdk';

import { RootState } from 'state/reducer';

interface OptimizelyFlag extends OptimizelyDecision {
  clientReady: boolean;
}

const useOptimizelyFlag = (featureFlag: string): OptimizelyFlag => {
  const optimizelyAttributes = useSelector((state: RootState) => state.config.optimizelyAttributes);

  const [decision, clientReady] = useDecision(
    featureFlag,
    {},
    {
      overrideUserId: optimizelyAttributes.user_identity,
      overrideAttributes: optimizelyAttributes,
    },
  );
  return { ...decision, clientReady };
};

export default useOptimizelyFlag;
