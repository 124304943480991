import React from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { Moment } from 'moment-timezone';
import moment from 'moment';

import ChevronLeft from './chevron-left';
import ChevronRight from './chevron-right';
import './calendar.less';
import { createNativeDateFromMoment } from './date';

interface ComponentProps {
  startDate: Moment | null;
  onChange: (date: Moment) => void;
}

const AccountCalendar: React.FC<ComponentProps> = ({ startDate, onChange }) => {
  const onDateChange = (newStartDate: Moment | null) => {
    if (newStartDate !== null) {
      onChange(newStartDate);
    }
  };
  const currentDate = new Date();
  return (
    <Calendar
      minDetail='month'
      showNeighboringMonth={false}
      selectRange={false}
      prevLabel={<ChevronLeft />}
      nextLabel={<ChevronRight />}
      nextAriaLabel='Move forward to switch to the next month.'
      prevAriaLabel='Move backward to switch to the previous month.'
      prev2Label={null}
      next2Label={null}
      value={createNativeDateFromMoment(startDate)}
      maxDate={currentDate}
      onChange={(value, _event) => {
        if (value instanceof Date) {
          const newStartDate = moment(value);
          onDateChange(newStartDate);
        }
      }}
    />
  );
};

export default AccountCalendar;
