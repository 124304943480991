import {
  Notifications,
  NotificationsSelected,
  Profile,
  ProfileActive,
  Purchases,
  PurchaseSelected,
  RAFIcon,
  RAFIconActive,
} from 'assets';

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface NavItem {
  type?: 'link' | 'nav';
  url?: string;
  label: string;
  icon: string;
  onClick: () => void;
  href?: string;
  path: string;
  status: Status;
  selectedIcon?: string;
  id: string;
}

type INavItems = ReadonlyArray<NavItem>;

export const navItems: INavItems = [
  {
    href: '/profile',
    icon: Profile,
    label: 'Profile',
    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    onClick: (): void => {},
    path: '/profile',
    selectedIcon: ProfileActive,
    status: Status.ACTIVE,
    type: 'nav',
    id: 'navProfile',
  },
  {
    href: '/notifications',
    icon: Notifications,
    label: 'Marketing Preferences',
    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    onClick: (): void => {},
    path: '/notifications',
    selectedIcon: NotificationsSelected,
    status: Status.ACTIVE,
    type: 'nav',
    id: 'navNotifications',
  },
  {
    href: `/purchases`,
    icon: Purchases,
    label: 'Purchases',
    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    onClick: (): void => {},
    path: '/purchases',
    selectedIcon: PurchaseSelected,
    status: Status.ACTIVE,
    type: 'nav',
    id: 'navPurchases',
  },
  {
    href: `/referrals`,
    icon: RAFIcon,
    label: 'Referrals',
    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    onClick: (): void => {},
    path: '/referrals',
    selectedIcon: RAFIconActive,
    status: Status.ACTIVE,
    type: 'nav',
    id: 'navReferrals',
  },
];
