import React, { ReactNode } from 'react';
import { Container, ElementsContainer, Title } from './assets/styles';

interface TitledSectionProps {
  title: string;
  bottomBorder?: boolean;
  children: ReactNode;
}

const TitledSection: React.FC<TitledSectionProps> = ({ title, bottomBorder, children }) => {
  return (
    <Container bottomBorder={bottomBorder}>
      <Title>{title}</Title>
      <ElementsContainer>{children}</ElementsContainer>
    </Container>
  );
};

export default TitledSection;
