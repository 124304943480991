import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from 'state';
import { Address, OccupationTypes } from '@rentecarlo/component-library';
import { updateContactDetailsRequest } from 'state/profile/actions';
import ContactDetailsInput, { FormValues } from './ContactDetailsInput';

interface Props {
  loading: boolean;
}
interface StateProps {
  occupationType?: OccupationTypes;
  occupation: string;
  phoneNumber: string;
  email: string;
  address: Address;
  canEdit: boolean;
}
interface DispatchProps {
  submit: (values: FormValues) => void;
}

export interface ComponentProps extends Props, StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps & Props => ({
  occupationType: state.profile.contactDetails.occupationType,
  occupation: state.profile.contactDetails.occupation,
  phoneNumber: state.profile.contactDetails.phoneNumber,
  email: state.profile.contactDetails.email,
  address: state.profile.contactDetails.address,
  canEdit: state.profile.contactDetails.canEdit,
  loading: state.profile.loading,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  submit: (values: FormValues): void => {
    dispatch(updateContactDetailsRequest(values));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsInput);
