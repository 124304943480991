import { ContactDetailsState } from 'state/profile/types';
import { NotificationState } from 'state/notifications/types';
import Client from './client';

type CustomerContactDetailsRequest = Omit<ContactDetailsState, 'canEdit'>;
type CustomerMarketingPreferencesRequest = Omit<NotificationState, 'updateFailed'>;

const getCustomer = () => {
  return new Client.Builder().get(`${process.env.REACT_APP_BFF_BASE_URL}/v1/customer/`).execute();
};

const updateCustomerContactDetails = (data: CustomerContactDetailsRequest) => {
  return new Client.Builder()
    .put(`${process.env.REACT_APP_BFF_BASE_URL}/v1/customer/contact-details/`)
    .data(data)
    .execute();
};

const updateCustomerMarketingPreferences = (data: CustomerMarketingPreferencesRequest) => {
  return new Client.Builder()
    .put(`${process.env.REACT_APP_BFF_BASE_URL}/v1/customer/marketing-preferences/`)
    .data(data)
    .execute();
};

export const BackendClient = {
  getCustomer,
  updateCustomerContactDetails,
  updateCustomerMarketingPreferences,
};
