import Styled, { css } from 'styled-components';
import { MediaQuery } from '@rentecarlo/component-library';

export const SplitViewContainer = Styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    ${MediaQuery.mobile(css`
      flex-direction: column;
      align-items: center;
    `)}
`;
