import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import size from '../../../config/size';
import Select from '../Select/Select';

import timebarRange from '../../../config/timebarRange.json';

const Container = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-bottom: 10px;
  background: ${({ theme }): string => theme.backgroundAlt};
`;

const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: space-between;
`;

const SelectContainerCss = css`
  width: 100%;
`;

const HourSelectContainer = styled.div`
  ${SelectContainerCss}
  margin-right: 8px;
`;

const MinuteSelectContainer = styled.div`
  ${SelectContainerCss}
`;

const Label = styled.div`
  width: 96px;
  color: #364344;
  font-family: 'proxima-soft';
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
`;

const DoneButton = styled.div`
  flex: 1;
  text-align: right;
  color: ${({ theme }) => theme.primary};
  font-size: 14px;
  font-weight: 500;
  display: none;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 16px;
  ${css`
    @media (min-width: ${size.desktopWidth}) {
      display: block;
    }
  `}
`;

interface ModalTimeBarProps {
  label: string;
  hours: number | null;
  id: string;
  minutes: number | null;
  onDone: () => void;
  onChange: (hours: number, minutes: number) => void;
}

const ModalTimeBar: React.FC<ModalTimeBarProps> = ({
  label,
  hours,
  id,
  minutes,
  onDone,
  onChange,
}) => {
  const [selectedHours, setSelectedHours] = useState<number | null>(hours);
  const [selectedMinutes, setSelectedMinutes] = useState<number | null>(minutes);
  useEffect(() => {
    if (selectedHours !== null && selectedMinutes !== null) {
      onChange(selectedHours, selectedMinutes);
    }
  }, [selectedHours, selectedMinutes, onChange]);

  const hourChange = (newHours: string) => {
    const hoursNumber = Number(newHours);
    setSelectedHours(hoursNumber);
  };

  const minuteChange = (newMinutes: string) => {
    const minutesNumber = Number(newMinutes);
    setSelectedMinutes(minutesNumber);
  };

  return (
    <Container>
      <Label id='account-text-modalTimeBar'>{label}</Label>
      <DropdownContainer>
        <HourSelectContainer>
          <Select
            id={`${id}_hours_select`}
            onChange={(e) => hourChange(e.target.value)}
            options={timebarRange.hours.map((h, index) => ({
              id: `hour-${index}`,
              ...h,
              value: h.value.toString(),
            }))}
            value={selectedHours === null ? '' : selectedHours.toString()}
            type='compact'
            showValidateTick={false}
            placeholder='Select hour'
            selectorType='default'
            desktopRightMargin='0px'
          />
        </HourSelectContainer>
        <MinuteSelectContainer>
          <Select
            id={`${id}_minutes_select`}
            onChange={(e) => minuteChange(e.target.value)}
            options={timebarRange.minutes.map((m, index) => ({
              id: `minute-${index}`,
              ...m,
              value: m.value.toString(),
            }))}
            value={selectedMinutes === null ? '' : selectedMinutes.toString()}
            type='compact'
            showValidateTick={false}
            placeholder='Select minute'
            selectorType='default'
            desktopRightMargin='0px'
          />
        </MinuteSelectContainer>
      </DropdownContainer>
      <DoneButton
        id='account-button-modalTimeBarCalendarDone'
        onClick={onDone}
        data-testid='calendar-done'
      >
        Done
      </DoneButton>
    </Container>
  );
};

ModalTimeBar.defaultProps = {
  minutes: timebarRange.minutes[0].value,
  hours: timebarRange.hours[0].value,
};

export default ModalTimeBar;
